import { sweetAlert } from "../../../src/utils/UniversalFunction";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../img/Zais_logo.png";
import "./login.css";
import { authenticationService } from "../../_services/authentication";
import "../sidebar/common.css";
import config from "../../config/config.json";
import env from "../../env";
import { apiCall } from "../../_services/apiCall";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import LoginImages from "../../img/login-image.jpg";

export default class resetpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
      },
      submitted: false,
      captchaIsVerified: false,
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }
  handleVerification(e) {
    this.setState({
      captchaIsVerified: true,
    });
  }

  async handleSubmit(event) {
    this.setState({ loading: true });
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, captchaIsVerified } = this.state;

    // document.getElementById('user_captcha_input').value = "";
    // if (captchaIsVerified) {
    if (user.email) {
      const { isSuccess, data } = await apiCall(
        config.AUTH_API_URL_COMPANY + "resetPassword",
        {},
        {
          email: user.email,
        },
        "POST"
      );
      this.setState({ loading: false });
      if (isSuccess) {
        setTimeout(() => {
          this.props.history.push("/reset_massage");
        }, 1000);
      }
      //   axios
      //     .post(config.API_URL + "password/email", {
      //       email: user.email,
      //       token: captchaIsVerified
      //     })
      //     .then((response) => {
      //       sweetAlert("success", response.data.message);
      //       setTimeout(() => {
      //         this.props.history.push("/reset_massage");
      //       }, 1000);
      //     })
      //     .catch(function (error) {
      //       if (error.response) {
      //         sweetAlert("error", error.response.data.message);
      //       }
      //     });
      // } else {
      //   sweetAlert("error", "Please fill Email Address");
      // }
    }
    //  else {
    //   sweetAlert("error", "Please verify Captcha");
    // }
  }

  componentDidMount() {
    if (authenticationService.currentUserValue) {
      this.props.history.push("/home");
    }
    this.videoRef.autoplay = true;
  }

  render() {
    const { user, submitted } = this.state;
    return (
      <div>
        <Row>
          <Col md={7}>
            {/* <img src={LoginImages} alt="" /> */}
            <video
              ref={(ref) => (this.videoRef = ref)}
              autoPlay
              loop
              muted
              className="video-background"
            >
              <source
                src="https://riu-bucket.s3.ap-south-1.amazonaws.com/f6.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Col>
          <Col md={5}>
            <section className="login">
              <div className="login_part">
                <div className="sing_log">
                  <div className="sing_one">
                    <img src={logo} alt="logo" className="w-50" />
                  </div>
                  <div className="text_sing">
                    <p className="faster_oval">
                      {" "}
                      Please enter your registered email to reset your RIU
                      password.{" "}
                    </p>
                  </div>
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div className="ster_form">
                      <div className="make_form">
                        <div className="row">
                          <div className="col-lg-12 col-xs-12">
                            <div className="form_sign">
                              <div
                                className={
                                  "form-group fg" +
                                  (submitted && !user.register_company_name
                                    ? " has-error"
                                    : "")
                                }
                              >
                                <label className="st_name" htmlFor="name">
                                  Email
                                </label>
                                <input
                                  className="form-control mb-3 p-3"
                                  type="text"
                                  name="email"
                                  placeholder="Please enter your registered email"
                                  value={user.email}
                                  onChange={this.handleChange}
                                />
                                {submitted && !user.email && (
                                  <div className="help-block">
                                    Email is required
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="make_form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="glee">
                            <div className="bacei">
                              <ReCAPTCHA
                                sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
                                onChange={(e) => this.handleVerification(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      <div className="row my-4">
                        <div className="col-md-6">
                          <NavLink to="/Login">
                            <Button variant="info" className="w-100 p-3">
                              <b> Back to Login</b>
                            </Button>
                          </NavLink>
                        </div>
                        <div className="col-md-6">
                          {this.state?.loading ? (
                            <Button
                              variant="info"
                              className="w-100 p-3"
                              disabled
                            >
                              <Spinner animation="border" /> Sending...
                            </Button>
                          ) : user.email ? (
                            <Button
                              type="submit"
                              className="w-100 p-3"
                              variant="info"
                            >
                              <b>Reset</b>
                            </Button>
                          ) : (
                            <Button
                              variant="secondary"
                              className="w-100 p-3"
                              disabled
                            >
                              <b>Reset</b>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    );
  }
}
