import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { Button, Modal, Form } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
const questionData = {
  289: "Total Diesel consumed within the operational boundary of the company* (in Liters)",
  292: "Total LPG consumed within the operational boundary of the company* (in Liters)",
  293: "Total Petrol consumed within the operational boundary of the company* (in Liters)",
  295: "Total PNG consumed within the operational boundary of the company* (in SCM)",
  391: "Total Groundwater consumption* (in KL)",
  392: "Total Water Bill (in Rs.)",
  393: "Total wastewater generated* (in KLD)",
  394: "Total Wastewater treated(STP/ETP)* (in KL)",
  395: "Total treated water reused(Flushing/CT etc.)* (in KL)",
  396: "Water recycling/reuse percentage*",
  399: "Total cost for STP treatment*(in Rs.)",
  400: "Total non-hazardous solid waste generation (black category general waste)* (Kg)",
  401: "Total non-hazardous waste sent to landfil(construction waste/other waste to landfill)* (Kg)",
  402: "Total packaging waste (Non-Plastic-Cardboard waste) generated* (Kg)",
  404: "Total packaging waste (Plastic) generated* (Kg)",
  408: "Total food waste generated/Kitchen Waste* (Kgs)",
  409: "Total biomedical waste generated",
  410: "Total biomedical waste disposal Bill* (in Rs.)",
  412: "Total e-waste generated* (Kg)",
  417: "Total Petrol cost* (in Rs.)",
  418: "Total Diesel cost* (in Rs.)",
  419: "Total PNG cost* (in Rs.)",
  421: "Total GHG emissions due to consumption of Petrol",
  422: "Total GHG emissions due to consumption of Diesel",
  424: "Total GHG emissions due to consumption of PNG",
  425: "Total GHG Emissions due to GRID electricity",
  426: "Total electricity consumption (Captive Power Plant - Natural Gas)* (in KW)",
  427: "Total Emissions due to Electricity Power plant (Captive Power Plant - Natural Gas)",
  428: "Total Electricity consumption through DG* (in KW)",
  429: "Total electricity consumption from Renewable energy (via PPA)* (in KW)",
  430: "Total electricity consumption from Renewable energy (rooftop solar)* (in KW)",
  431: "Total Electricity Bill *(in Rs.)",
  432: "Current employees by Gender (in %) Male",
  433: "Current employees by Gender (in %) Female",
  434: "Employees less than 30 years of age",
  435: "Employees between 30-50 years of age",
  436: "Employees more than 50 years of age",
  437: "Manpower turnover rate(atrition rate) in %",
  438: "Average training hours per employee (in Hours)",
  439: "Number of Mock Drills",
  440: "Fire Safety Audits",
  441: "Number of Safety Trainings",
  442: "Number of Safety Committee Meetings",
  443: "Fatalities (Number of cases)",
  444: "High-consequence injuries (Number of cases)",
  445: "Recordable injuries (Number of cases)",
  446: "Recordable work-related ill health cases (Number of cases)",
  447: "Number of Environmental Incidents",
  448: "Occupancy",
  449: "Total GHG emissions due to consumption of LPG",
  450: "Total LPG cost* (in Rs.)",
  468: "Total electricity consumption (GRID electricity)* (in KW)",
  469: "Total Tanker Water Consumption* (in KL)",
  470: "Total water consumption limit as per Consent to Operate*( in KLD)",
  471: "Total groundwater consumption limit as per CGWA NOC* (in KLD)",
  472: "Number of deviations reported in terms of water consumption with respect to CTO limit*",
  473: "Number of deviations reported in terms of water consumption with respect to CGWA limit*",
  474: "Total surface water consumption (this includes municipal supply water)* (in KL)",
  475: "Zero Liquid Discharge (ZLD)*(Yes/No)",
  485: "How many deviations observed in ambient air quality report appeared*",
  486: "Total number of significant deviations reported in the environmental monitoring reports with respect to applicable regulatory standard or permits*",
  487: "Number of deviations observed in ambient air quality report*",
  488: "Number of deviations observed in ambient noise level report*",
  489: "Number of deviations observed in air emissions reports (stacks) appeared*",
  490: "Number of deviations observed in air emissions reports (Diesel Generator)*",
  491: "Number of deviations observed in drinking water quality report*",
  492: "Number of deviations observed in treated wastewater quality report*",
  493: "Number of deviations observed in groundwater quality report for waste sector*",
  494: "Number of deviations observed in soil quality report for waste sector",
  495: "Total Furnace oil (FO) consumed within the operational boundary of the company* (in Liters)",
  496: "Total GHG emissions due to consumption of FO",
  497: "Total Coal consumed within the operational boundary of the company* (in Kg)",
  498: "Total GHG emissions due to consumption of Coal",
  499: "Total Briquette consumed within the operational boundary of the company* (in Kg)",
  500: "Total GHG emissions due to consumption of briquette",
  501: "New hires by Gender Male",
  502: "New hires by Gender Female",
  503: "New hires by age groups (30-50)",
  504: "New hires by age groups (>50)",
  507: "Testing and Certification of Lift(No. of lifts/No. of tests)",
  512: "Total Nitrous Oxide (N₂O) consumed within the operational boundary of the company* (in Liters)",
  513: "Total Carbon Dioxide (CO₂) consumed within the operational boundary of the company* (in Kgs)",
  514: "Total Entonox consumed within the operational boundary of the company* (in Kgs)",
  515: "Total Desflurane consumed within the operational boundary of the company* (in ml)",
  516: "Total Isoflurane consumed within the operational boundary of the company* (in ml)",
  517: "Total Sevoflurane consumed within the operational boundary of the company* (in ml)",
  518: "Total R-134A consumed within the operational boundary of the company* (in Kgs)",
  519: "Total R-22 consumed within the operational boundary of the company* (in Kgs)",
  520: "Total R-407C consumed within the operational boundary of the company* (in Kgs)",
  521: "Total R-32 consumed within the operational boundary of the company* (in Kgs)",
  522: "Total R-410A consumed within the operational boundary of the company* (in Kgs)",
  523: "Total Sevitrue consumed within the operational boundary of the company* (in ml)",
  524: "Total Suprane consumed within the operational boundary of the company* (in ml)",
  526: "Total Sevorane consumed within the operational boundary of the company* (in ml)",
  527: "Total Water consumption (Ground water+municipal supply water+tanker water)*( in KL)",
  528: "Total Electrical Consumption(Total of all above sources of Elctricity Consumed)* in KW",
  529: "Greenhouse Gas Emissions Scope-1",
  530: "Greenhouse Gas Emissions Scope-2",
  531: "New hires by age groups (<30)",
  532: "Diesel Energy within Operational Boundaries (in GJ)",
  533: "LPG Energy within Operational Boundaries (in GJ)",
  534: "Petrol Energy within Operational Boundaries (in GJ)",
  536: "PNG Energy within Operational Boundaries (in GJ)",
  537: "Electricity from Captive Power Plant (Natural Gas) (in GJ)",
  538: "Electricity Consumption through DG (in GJ)",
  539: "Total Electricity Consumption from Renewable Energy (via PPA) (in GJ)",
  540: "Total Electricity Consumption from Renewable Energy (Rooftop Solar) (in GJ)",
  541: "Total Electricity Consumption (Grid Electricity) (in GJ)",
  542: "Furnace Oil (FO) Energy within Operational Boundaries (in GJ)",
  543: "Coal Energy within Operational Boundaries (in GJ)",
  544: "Briquette Energy within Operational Boundaries (in GJ)",
  545: "Total packaging waste (Non-Plastic-Paper waste) generated* (Kg)",
  546: "Total iron waste generated * Kg",
  547: "Total Aluminum waste generated * Kg",
  548: "Total Copper waste generated * Kg",
  549: "Total food waste converted to manure through organic waste converter (OWC)* (Kgs)",
  550: "Total waste oil generated (cooking oil/diesel oil) in Lts",
  551: "Total spent formalin solution disposed in Ltrs",
  552: "Total number of employees (Number)",
};

const ExcelUploader = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [months, setMonths] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const multiselectRef = useRef();
  const [loading, setLoading] = useState(false);

  // Fetch locations when the component mounts
  useEffect(() => {
    const getLocations = async () => {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
          {},
          {},
          "GET"
        );

        if (isSuccess && data?.data) {
          const locationOptions = data.data.map((item) => ({
            id: item.id,
            name: item.unitCode,
            subLocations: item.subLocation || [],
          }));
          setLocations(locationOptions);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    getLocations();
  }, []);

  // Convert Excel serial number to MM/YYYY format
  const excelSerialToDate = (serial) => {
    if (typeof serial !== "number") return serial;
    const excelEpoch = new Date(1899, 11, 30);
    const parsedDate = new Date(excelEpoch.getTime() + serial * 86400000);
    return parsedDate.toLocaleDateString("en-US", {
      month: "2-digit",
      year: "numeric",
    });
  };

  // Generate predefined months from April 2024 onward
  useEffect(() => {
    const generateMonths = () => {
      const startMonth = 3; // April (0-based index)
      const startYear = 2024;
      const monthsArray = [];

      for (let i = 0; i < 12; i++) {
        const date = new Date(startYear, startMonth + i, 1);
        const formattedMonth = date
          .toLocaleDateString("en-US", { month: "2-digit", year: "numeric" })
          .replace(",", "");
        const abbreviatedMonth = date.toLocaleDateString("en-US", {
          month: "short",
        });

        monthsArray.push({
          name: formattedMonth,
          displayName: abbreviatedMonth,
        });
      }

      setMonths(monthsArray);
    };

    generateMonths();
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      if (jsonData.length === 0) return;

      let headers = jsonData[2] || [];
      headers = headers.map((col) => excelSerialToDate(col));

      const selectedMonthNames = selectedMonths.map((m) => m.name);

      let extractedData = [];
      for (let i = 3; i < jsonData.length; i++) {
        const row = jsonData[i];
        if (!row[1]) continue;

        let questionText = row[1].split("*")[0].trim(); // Remove everything after '*'

        let kpiData = {
          question: questionText,
          data: {},
        };

        for (let j = 3; j < headers.length; j++) {
          if (headers[j] && selectedMonthNames.includes(headers[j])) {
            kpiData.data[headers[j]] = row[j];
          }
        }

        extractedData.push(kpiData);
      }

      await generatePayload(extractedData, selectedMonthNames, questionData);
    };

    reader.readAsArrayBuffer(file);
  };

  const getDataByQuestionAndMonth = (question, month, dataArray) => {
    const entry = dataArray.find((item) => item.question === question);
    return entry
      ? entry.data[month] || "Data not available"
      : "Question not found";
  };

  const generatePayload = async (
    extractedData,
    selectedMonthNames,
    questionData
  ) => {
    setLoading(true);

    if (!selectedLocation) {
      console.warn("No location selected.");
      return;
    }

    for (const item of extractedData) {
      console.log(extractedData, "diapkkskjww");
      const questionId = Object.keys(questionData).find(
        (key) => questionData[key].toLowerCase() === item.question.toLowerCase()
      );

      if (!questionId) {
        console.warn(`No questionId found for: ${item.question}`);
        continue;
      }

      for (const month of selectedMonthNames) {
        // Extract year and month from "MM/YYYY"
        const [mm, yyyy] = month.split("/").map(Number);
        const fromDate = `${yyyy}-${String(mm).padStart(2, "0")}`;

        // Calculate next month for toDate
        let nextMonth = mm + 1;
        let nextYear = yyyy;
        if (nextMonth > 12) {
          nextMonth = 1;
          nextYear++;
        }
        const toDate = `${nextYear}-${String(nextMonth).padStart(2, "0")}`;

        if (item.data[month] !== undefined) {
          let answer = [[""]]; // Default answer

          if (Number(questionId) == 409) {
            answer = [
              [
                getDataByQuestionAndMonth(
                  "Yellow in (Kg)",
                  month,
                  extractedData
                ),
                getDataByQuestionAndMonth("Red (Kg)", month, extractedData),
                getDataByQuestionAndMonth("White (Kg)", month, extractedData),
                getDataByQuestionAndMonth("Blue (Kg)", month, extractedData),
                getDataByQuestionAndMonth(
                  "Cytotoxic (kgs)",
                  month,
                  extractedData
                ),
              ],
            ];
          } else if ([505].includes(Number(questionId))) {
            answer = [
              [
                getDataByQuestionAndMonth(
                  "Average training hours per employee by gender Male (in Hours/Number of employees))",
                  month,
                  extractedData
                ),
              ],
              [
                getDataByQuestionAndMonth(
                  "Average training hours per employee by gender Female (in Hours/Number of employees))",
                  month,
                  extractedData
                ),
              ],
            ];
          } else if ([402, 504, 531, 509].includes(Number(questionId))) {
            answer = [["", "", item.data[month]]];
          } else if ([437].includes(Number(questionId))) {
            answer = [["", item.data[month]]];
          } else if ([501, 502, 503, 508, 510].includes(Number(questionId))) {
            answer = [[item.data[month], ""]];
          }

          let readingValue = item.data[month];
          console.log(
            readingValue,
            item,
            item.question,
            "sasdadwd",
            typeof readingValue
          );
          let payload = {
            questionId: Number(questionId),
            moduleId: 12,
            questionType: [
              409, 402, 437, 501, 502, 503, 504, 505, 508, 509, 510, 531,
            ].includes(Number(questionId))
              ? "tabular_question"
              : [475, 476].includes(Number(questionId))
              ? "yes_no"
              : "quantitative_trends",
            questionTitle: item.question,
            fromDate: fromDate,
            toDate: toDate, // Next month
            frequency: "CUSTOM",
            readingValue,
            unit: "KL",
            sourceId: Number(selectedLocation),
            subLocationId: null,
            notApplicable: readingValue === "NA",
            financialYearId: 30,
            current_role: "company",
            note: [[""]],
            proofDocument: [[]],
            comment: [[]],
            answer: [
              409, 402, 437, 501, 502, 503, 504, 505, 508, 509, 510, 531,
            ].includes(Number(questionId))
              ? JSON.stringify(answer)
              : [475, 476].includes(Number(questionId))
              ? JSON.stringify({
                  answer: readingValue,
                })
              : JSON.stringify({
                  questionId: Number(questionId),
                  moduleId: 12,
                  questionType: [
                    409, 402, 437, 501, 502, 503, 504, 505, 508, 509, 510, 531,
                  ].includes(Number(questionId))
                    ? "tabular_question"
                    : "quantitative_trends",
                  questionTitle: item.question,
                  fromDate: fromDate,
                  toDate: toDate,
                  frequency: "CUSTOM",
                  readingValue:
                    typeof readingValue === "number"
                      ? readingValue.toFixed(2)
                      : readingValue,
                  unit: "KL",
                }),
          };

          await handleSubmit(payload); // Await API call
        }
      }
    }
    setLoading(false);
  };

  const handleSubmit = async (payload) => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestions`,
        {},
        payload,
        "POST"
      );

      if (isSuccess) {
        console.log("Payload submitted successfully:", data);
      } else {
        console.warn("Failed to submit payload.");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <Button
        variant="primary"
        className="mt-3"
        style={{
          backgroundColor: "#3F88A5",
          border: "none",
          padding: "10px 20px",
        }}
        onClick={() => setModalOpen(true)}
      >
        Upload
      </Button>

      {/* Modal for Selecting Location and Uploading File */}
      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Uploading...</p>
          ) : (
            <Form>
              <Form.Group controlId="locationSelect">
                <Form.Label>Choose a Location</Form.Label>
                <Form.Select
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  <option value="">Select a location</option>
                  {locations.map((loc) => (
                    <option key={loc.id} value={loc.id}>
                      {loc.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="monthSelect" className="mt-3">
                <Form.Label>Select Month(s)</Form.Label>
                <Multiselect
                  placeholder="Select Months"
                  displayValue="displayName"
                  options={months}
                  selectedValues={selectedMonths}
                  onRemove={setSelectedMonths}
                  onSelect={setSelectedMonths}
                  showCheckbox
                  ref={multiselectRef}
                />
              </Form.Group>

              <Form.Group controlId="fileUpload" className="mt-3">
                <Form.Label>Upload Excel File</Form.Label>
                <Form.Control
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExcelUploader;
