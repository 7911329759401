import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./accordioncomponent.css";
import "react-datepicker/dist/react-datepicker.css";
import MainAccordComponent from "./MainAccordComponent";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { Button } from "react-bootstrap";
import NoDataFound from "../../../img/no.png";
import Loader from "../../loader/Loader";
const AccordionComponent = ({
  getAuditListing,
  sourceData,
  currentUserId,
  menu,
  apiData,
  financeObject,
  moduleData,
  financialYear,
  startingMonth,
  assignedTo,
  correctModuleId,
  units,
  // loading
}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [savedAnswers, setSavedAnswers] = useState([{}]);
  const [savedStatusAnswers, setSavedStatusAnswers] = useState([{}]);
  const [userData, setUserData] = useState([]);
  const [moduleIdToUse, setModuleIdToUse] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [applicable, setApplicable] = useState(true);

  const handleDateChange = (date) => {
    setStartDate(date);
    setIsOpen(false); // Close the calendar after selecting a date
  };

  const handlePeriodSelect = (key) => {
    setSelectedPeriod(key);
  };

  const handleCalendarOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const isMounted = useRef(true);

  useEffect(() => {
    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getAllUser = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getAllUser`,
      {},
      {},
      "GET"
    );
    if (isSuccess && isMounted.current) {
      setUserData(data.data);
    }
  };

  const getReportingQuestionAnswer = async () => {
    if (moduleIdToUse && moduleIdToUse.length && financeObject) {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestionAnswer`,
        {},
        { moduleId: moduleIdToUse, financialYearId: financeObject },
        "GET"
      );
      if (isSuccess && isMounted.current) {
        setSavedStatusAnswers(data.answers);
      }
    }
  };



  useEffect(() => {
    const correctModuleIdValue =
      Array.isArray(correctModuleId) && correctModuleId.length === 0
        ? menu === "audit"
          ? localStorage.getItem("auditModuleId")
          : localStorage.getItem("moduleId")
        : correctModuleId;
    const moduleIdToUse =
      correctModuleIdValue ||
      (menu === "audit"
        ? localStorage.getItem("auditModuleId")
        : localStorage.getItem("moduleId"));
    setModuleIdToUse(moduleIdToUse);
  }, []);

  useEffect(() => {
    setActiveIndex(null);
    getReportingQuestionAnswer();
  }, [moduleData, financeObject]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (moduleData && moduleData.length > 0) {
      setLoading(false);
    }

    if (moduleData && moduleData.length == 0) {
      setLoading(false);
    }

  }, [moduleData]);
  useEffect(() => {
    const correctModuleIdValue =
      Array.isArray(correctModuleId) && correctModuleId.length === 0
        ? menu === "audit"
          ? localStorage.getItem("auditModuleId")
          : localStorage.getItem("moduleId")
        : correctModuleId;
    const moduleIdToUse =
      correctModuleIdValue ||
      (menu === "audit"
        ? correctModuleId === undefined
          ? localStorage.getItem("auditModuleId")
          : correctModuleId
        : localStorage.getItem("moduleId"));
    setModuleIdToUse(moduleIdToUse || correctModuleId);
  }, [correctModuleId]);

  useEffect(() => {
    getAllUser();
  }, []);
  const acceptAllQuestion = async () => {
    if (financeObject) {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAllAnswers`,
        {},
        { financialYearId: financeObject },
        "POST"
      );
      if (isSuccess) {
      }
    }
  };


  return (
    <div
      className="container w-100 my-3"
      style={{ background: "transparent", padding: "0%" }}
    >
      <div className="accordion" id="accordionExample">
      {loading ? (
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
        width: "100%",
      }}
    >
      <Loader />
    </div>
    ) : moduleData && moduleData.length > 0 ? (
      moduleData.map((item, index) => (
        <MainAccordComponent
          key={index}
          getAuditListing={getAuditListing}
          sourceData={sourceData}
          currentUserId={currentUserId}
          menu={menu}
          assignedTo={assignedTo}
          apiData={apiData}
          savedAnswers={savedAnswers}
          moduleId={correctModuleId}
          userData={userData}
          financeObject={financeObject}
          applicable={applicable}
          item={item}
          index={index}
          activeIndex={activeIndex}
          handleAccordionClick={handleAccordionClick}
          startingMonth={startingMonth}
          financialYear={financialYear}
          handlePeriodSelect={handlePeriodSelect}
          selectedPeriod={selectedPeriod}
          handleDateChange={handleDateChange}
          handleCalendarOpen={handleCalendarOpen}
          isOpen={isOpen}
          setApplicable={setApplicable}
          startDate={startDate}
          units={units}
          savedStatusAnswers={savedStatusAnswers}
        />
      ))
    ) : (
      <div className="hstack justify-content-center">
          <img src={NoDataFound} alt="" />
        </div>
    )}
        {/* {moduleData && moduleData.length > 0 && menu === "audit" && (
          <div className="conta">
            <div
              className="w-100"
              style={{ paddingTop: "2%", textAlign: "right" }}
            >
              <Button
                variant="primary"
                className="mr-2 cbn"
                style={{
                  backgroundColor: "#3F88A5",
                  color: "white",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
                onClick={acceptAllQuestion}
              >
                Accept All
              </Button>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AccordionComponent;
