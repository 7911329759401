import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FiChevronDown } from "react-icons/fi";
import "./SignUpTrainee.css";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { history } from "../../_helpers/history";
import { NavLink } from "react-router-dom";

const RegisterForm = () => {
  const { token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [email, setEmail] = useState("");
  const [invitedEmail, setInvitedEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("MALE");
  const [categoryId, setCategoryId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [title, setTitle] = useState("");  
  const AudienceOptions = [
    { label: "Permanent Employees", value: "EMPLOYEES_PERMANENT" },
    { label: "Other than Permanent Employees", value: "EMPLOYEES_TEMPORARY" },
    { label: "Permanent Workers", value: "WORKERS_PERMANENT" },
    { label: "Other than Permanent Workers", value: "WORKERS_TEMPORARY" },
    { label: "KMP", value: "KMP" },
    { label: "BOD", value: "BOD" },
    { label: "Customers", value: "CUSTOMERS" },
    { label: "Suppliers", value: "SUPPLIERS" },
    { label: "Distributors", value: "DISTRIBUTORS" },
  ];
  const togglePasswordVisibility = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
      if (showConfirmPassword) setShowConfirmPassword(false);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
      if (showPassword) setShowPassword(false);
    }
  };

  const handleSubmit = async (e) => {
    if (!validateForm()) return;
    const result = token.includes('&') ? token.split('&')[0] : null;
    e.preventDefault();
    const payload = {
      employeeId,
      email,
      password,
      firstName,
      lastName,
      gender,
      token: result ? result : token,
      userType: "TRAINEE",
      categoryId: parseInt(categoryId),
      departmentId: parseInt(departmentId),
    };
    const { isSuccess, data } = await apiCall(
      `${config.AUTH_API_URL_COMPANY}signupTrainee`,
      {},
      { ...payload },
      "POST"
    );
    if (isSuccess) {
      history.push(`/#/trainee_invite/${token}`);
      window.location.reload();
    }
  };


  const callFunction = async (e) => {
    const result = token.includes('&') ? token.split('&')[1] : null;
    if (result) {
      const { isSuccess, data } = await apiCall(
        `${config.AUTH_API_URL_COMPANY}getTraineeEmail`,
        {},
        { id: result },
        "GET"
      );
      if (isSuccess) {
        setEmail(data.data[0].email)
        setInvitedEmail(true);
      }
    }
  };

    const callTraineeFunction = async (e) => {
    const result = token.includes('&') ? token.split('&')[0] : token;

    if (result) {
      const { isSuccess, data } = await apiCall(
        `${config.AUTH_API_URL_COMPANY}getTraineeData`,
        {},
        { token: result },
        "GET"
      );
      if (isSuccess) {
        setTitle(data?.data?.trainingTitle)
        // setInvitedEmail(true);
      }
    }
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};

    if (!employeeId) newErrors.employeeId = "Employee ID is required";
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Invalid email format";
    }
    if (!password) {
      newErrors.password = "Password is required";
    } else if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (!categoryId) newErrors.categoryId = "Category is required";
    if (!departmentId) newErrors.departmentId = "Department is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    callFunction();
    callTraineeFunction();
  }, [])
  return (
    <div className="register-container">
      <div className="register-form">
        <h2 className="form-title">Register Now</h2>
        <h4 className="form-title">{title}</h4>        
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="formEmployeeID">
            <Form.Label column sm="12">
              Employee ID
            </Form.Label>
            <Col sm="12">
              <Form.Control
                type="text"
                className="form-controll"
                placeholder="Enter Employee Unique ID"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
              />
            </Col>
          </Form.Group>
          {errors.employeeId && <p style={{ color: "red", fontsize: "14px", fontweight: "bold", userselect: "none" }}>{errors.employeeId}</p>}

          <Row className="mt-4">
            <Col sm="6">
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  className="form-controll"
                  type="text"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
              {errors.firstName && <p style={{ color: "red", fontSize: "14px", fontWeight: "bold", userSelect: "none" }}>{errors.firstName}</p>}

            </Col>
            <Col sm="6" className="ms-2">
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-controll"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col sm="6">
              <Form.Group controlId="formEmail">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Mail ID"
                  className="form-controll"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly={invitedEmail}
                />
              </Form.Group>
              {errors.email && <p style={{ color: "red", fontsize: "14px", fontweight: "bold", userselect: "none" }}>{errors.email}</p>}

            </Col>
            <Col sm="6" className="ms-2">
              <Form.Group controlId="formGender">
                <Form.Label>Gender</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    className="form-controll"
                    as="select"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">Other</option>
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col sm="6">
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    className="form-controll"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroup.Text
                    onClick={() => togglePasswordVisibility("password")}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {errors.password && <p style={{ color: "red", fontsize: "14px", fontweight: "bold", userselect: "none" }}>{errors.password}</p>}

            </Col>
            <Col sm="6" className="ms-2">
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-controll"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <InputGroup.Text
                    onClick={() => togglePasswordVisibility("confirm")}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {errors.confirmPassword && <p style={{ color: "red", fontsize: "14px", fontweight: "bold", userselect: "none" }}>{errors.confirmPassword}</p>}

            </Col>
          </Row>

          <Row className="mt-4 mb-4">
            <Col sm="6">
              <Form.Group controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    className="form-controll"
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">Select category</option>

                    {AudienceOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
              {errors.category && <p style={{ color: "red", fontsize: "14px", fontweight: "bold", userselect: "none" }}>{errors.category}</p>}

            </Col>


            <Col sm="6" className="ms-2">
              <Form.Group controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    className="form-controll"
                    value={departmentId}
                    onChange={(e) => setDepartmentId(e.target.value)}
                  >
                    <option value="">Select Department</option>
                    <option value="1">MCDG - Sales</option>
                    <option value="2">MCDF + Infra Mfg - Manufacturing</option>
                    <option value="3">MSG</option>
                    <option value="4">Infrastructure</option>
                    <option value="5">Marketing</option>
                    <option value="6">Finance</option>
                    <option value="7">HR</option>
                    <option value="8">Legal</option>
                    <option value="9">R&D</option>
                    <option value="10">Sourcing</option>
                    <option value="11">EHS</option>
                    <option value="12">Supply Chain & Distribution</option>
                    <option value="13">ESG</option>
                    <option value="14">Technology</option>
                    <option value="15">Admin</option>
                    <option value="16">MCDF</option>
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
              {errors.department && <p style={{ color: "red", fontsize: "14px", fontweight: "bold", userselect: "none" }}>{errors.department}</p>}

            </Col>
          </Row>

          <Button variant="primary" type="submit" className="register-btn">
            REGISTER
          </Button>
          <div className="text-center mt-2">
            <h5>
              Already have an account?
              <NavLink
                to={`/trainee_invite/${token}`}
                style={{ color: "#3F88A5", cursor: "pointer" }}
              >
                Log in
              </NavLink>
            </h5>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RegisterForm;
