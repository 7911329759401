import React, { useEffect, useState } from "react";
import "./TrainingModal.css";
import CloseIcon from "../img/Close.png";
import "bootstrap/dist/css/bootstrap.min.css";
import img from "../img/filter.svg";
import TrainingFilterModal from "./TrainingFilterModal";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const TrainingModal = ({
  show,
  handleClose,
  actionId,
  editData,
  financialYearId,
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [topicMapping, setTopicMapping] = useState([]);
  const [trainingTopicData, setTrainingTopicData] = useState([]);
  const [formData, setFormData] = useState({
    trainingTitle: "",
    description: "",
    trainingFacilitator: "",
    trainers: "",
    departmentId: 0,
    fromDate: "",
    toDate: "",
    fromTime: "",
    toTime: "",
    targetAudience: [],
    modeOfTraining: "ONLINE",
    registrationDeadline: "",
    linkOrVenues: "",
    companyId: 0,
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [audienceType, setAudienceType] = useState("internal");
  const [showContentModal, setShowContentModal] = useState(false);
  const [contentModalData, setContentModalData] = useState("");
  const [desable, setDesable] = useState(false);

  // Audience options
  const internalAudienceOptions = [
    { label: "Permanent Employees", value: "EMPLOYEES_PERMANENT" },
    { label: "Other than Permanent Employees", value: "EMPLOYEES_TEMPORARY" },
    { label: "Permanent Workers", value: "WORKERS_PERMANENT" },
    { label: "Other than Permanent Workers", value: "WORKERS_TEMPORARY" },
    { label: "KMP", value: "KMP" },
    { label: "BOD", value: "BOD" },
    { label: "Customers", value: "CUSTOMERS" },
    { label: "Suppliers", value: "SUPPLIERS" },
    { label: "Distributors", value: "DISTRIBUTORS" },
  ];

  const externalAudienceOptions = [
    { label: "Contract Employees", value: "CONTRACT_EMPLOYEES" },
  ];

  const targetAudienceOptions =
    audienceType === "internal"
      ? internalAudienceOptions
      : audienceType === "external"
      ? externalAudienceOptions
      : [];

  // Modal content display handler
  const handleShowContent = (content) => {
    setContentModalData(content);
    setShowContentModal(true);
  };

  // Target audience selection handler
  const toggleOption = (value) => {
    if (value === "ALL") {
      if (selectedOptions.length === targetAudienceOptions.length) {
        setSelectedOptions([]);
        setFormData((prevData) => ({
          ...prevData,
          targetAudience: [],
        }));
      } else {
        const allValues = targetAudienceOptions.map((option) => option.value);
        setSelectedOptions(allValues);
        setFormData((prevData) => ({
          ...prevData,
          targetAudience: allValues,
        }));
      }
    } else {
      if (selectedOptions.includes(value)) {
        const newSelected = selectedOptions.filter(
          (selected) => selected !== value
        );
        setSelectedOptions(newSelected);
        setFormData((prevData) => ({
          ...prevData,
          targetAudience: newSelected,
        }));
      } else {
        const newSelected = [...selectedOptions, value];
        setSelectedOptions(newSelected);
        setFormData((prevData) => ({
          ...prevData,
          targetAudience: newSelected,
        }));
      }
    }
  };

  const isAllSelected = selectedOptions.length === targetAudienceOptions.length;

  // Fetch training topic mapping data
  const getTrainingTopicMapping = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getTrainingTopicMapping`,
        {},
        {},
        "GET"
      );
      if (isSuccess) {
        setTrainingTopicData(data?.data || []);

        // If editing, filter the topics based on the existing data
        if (editData && editData.trainingTopicID) {
          const topicIds = Array.isArray(editData.trainingTopicID)
            ? editData.trainingTopicID
            : JSON.parse(editData.trainingTopicID || "[]");

          const filteredArray =
            data?.data.filter((obj) => topicIds.includes(obj.id)) || [];

          setTopicMapping(filteredArray);
        }
      }
    } catch (error) {
      console.error("Error fetching training topic mapping:", error);
    }
  };

  // Handle edit data population
  useEffect(() => {
    if (editData) {
      getTrainingTopicMapping();

      // Parse target audience if needed
      let parsedTargetAudience = editData.targetAudience;

      if (
        typeof editData.targetAudience === "string" &&
        editData.targetAudience
      ) {
        try {
          parsedTargetAudience = JSON.parse(editData.targetAudience);
        } catch (e) {
          console.error("Error parsing target audience:", e);
          parsedTargetAudience = [];
        }
      }

      // Update form data with edit data
      setFormData({
        ...formData,
        ...editData,
        targetAudience: parsedTargetAudience || [],
      });

      // Update selected options for audience
      if (Array.isArray(parsedTargetAudience)) {
        setSelectedOptions(parsedTargetAudience);
      }
    }
  }, [editData]);

  // Initial data fetch
  useEffect(() => {
    getTrainingTopicMapping();
  }, []);

  // Topic filter modal handlers
  const openFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  // Get current financial year
  const getFinancialYear = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
        {},
        {}
      );

      if (isSuccess && data?.data?.length) {
        return data.data[data.data.length - 1].id;
      }
      return null;
    } catch (error) {
      console.error("Error fetching financial year:", error);
      return null;
    }
  };

  // Form change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Location selection handler
  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const { lat, lng } = await getLatLng(results[0]);

      const countryComponent = results[0].address_components.find((comp) =>
        comp.types.includes("country")
      );
      const stateComponent = results[0].address_components.find((comp) =>
        comp.types.includes("administrative_area_level_1")
      );
      const cityComponent = results[0].address_components.find((comp) =>
        comp.types.includes("locality")
      );

      const locationString = [
        cityComponent?.long_name || "",
        stateComponent?.long_name || "",
        countryComponent?.long_name || "",
      ]
        .filter(Boolean)
        .join(", ");

      setFormData({
        ...formData,
        linkOrVenues: locationString,
      });
    } catch (error) {
      console.error("Error fetching location details: ", error);
    }
  };

  const sendInvite = async (trainer, email, phone) => {
  const userId = JSON.parse(localStorage.getItem("user_temp_id"));

    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `inviteSubUser`,
      {},
      {
        firstName: trainer.name.split(" ")[0], // Assuming first name is the first word in the name
        lastName: trainer.name.split(" ")[1], // Assuming last name is the second word
        emailId: email,
        mobileNumber: phone,
        invitedBy: Number(userId),
        designationId: Number(1),
        sourceId: JSON.stringify([1]),
        roleId: Number(30),
      },
      "POST"
    );

    if (isSuccess) {
      // Handle success response here (e.g., show a success message)
      console.log("Invite sent successfully to:", trainer.name);
    } else {
      // Handle failure response here (e.g., show an error message)
      console.error("Failed to send invite to:", trainer.name, error);
    }
  };

  const [trainers, setTrainers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [trainerDetails, setTrainerDetails] = useState({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    // Parse the comma-separated trainers string into an array of trainer objects
    if (formData.trainers) {
      const trainerNames = formData.trainers
        .split(",")
        .map((name) => name.trim());
      const trainerObjects = trainerNames.map((name) => ({
        name,
        isValidated:
          name.toLowerCase() ===
          JSON.parse(
            localStorage.getItem("currentUser")
          ).first_name.toLowerCase() +
            " " +
            JSON.parse(
              localStorage.getItem("currentUser")
            ).last_name.toLowerCase(),
        isSelected: false,
        email: "",
        phone: "",
      }));
      setTrainers(trainerObjects);
    } else {
      setTrainers([]);
    }
  }, [formData.trainers]);

  // Save trainer details from modal
  const saveTrainerDetails = () => {
    if (selectedTrainer) {
      const updatedTrainers = trainers.map((trainer) =>
        trainer.name === selectedTrainer.name
          ? {
              ...trainer,
              email: trainerDetails.email,
              phone: trainerDetails.phone,
            }
          : trainer
      );
      setTrainers(updatedTrainers);
      setShowModal(false);
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedTrainers = [...trainers];
    updatedTrainers[index].isSelected = !updatedTrainers[index].isSelected;
    setTrainers(updatedTrainers);

    // If checked, open modal for details
    if (updatedTrainers[index].isSelected) {
      setSelectedTrainer(updatedTrainers[index]);
      setTrainerDetails({
        name: updatedTrainers[index].name,
        email: updatedTrainers[index].email || "",
        phone: updatedTrainers[index].phone || "",
      });
      setShowModal(true);
    }
  };
  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setTrainerDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleShowDetails = (trainer, index) => {
    setSelectedTrainer({ ...trainer, index });
    setTrainerDetails({
      name: trainer.name,
      email: trainer.email || "",
      phone: trainer.phone || "",
    });
    setShowModal(true);
  };
  const handleTrainerInputChange = (e) => {
    handleChange(e);

    // The trainers array will be updated in the useEffect hook
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDesable(true);
    try {
      // Get financial year if not provided
      const yearId = financialYearId || (await getFinancialYear());

      // Extract topic IDs and principle IDs
      const trainingTopicID = topicMapping.map((item) => item.id);
      const principlesId = topicMapping.map((item) => item.trainingPrincipleId);

      // Prepare payload
      const payload = {
        ...formData,
        targetAudience: formData.targetAudience,
        financialYearId: yearId,
        trainingTopicID,
        principlesId,
        companyId: Number(localStorage.getItem("user_temp_id")),
        ...(actionId ? { trainingId: actionId } : {}),
      };

      // Determine API endpoint based on action type
      const apiUrl = `${config.POSTLOGIN_API_URL_COMPANY}${
        actionId ? "updateTraining" : "createNewTraining"
      }`;

      // Make API call
      const { isSuccess, data } = await apiCall(apiUrl, {}, payload, "POST");
      setDesable(false);

      if (isSuccess) {
        const selectedTrainers = trainers.filter(
          (trainer) => trainer.isSelected
        );

        // Iterate through the selected trainers and call sendInvite for each
        selectedTrainers.forEach((trainer) => {
          // Ensure to pass the necessary data to the sendInvite function
          sendInvite(trainer, trainer.email, trainer.phone);
        });
        handleClose();
      } else {
        console.error("API call returned error:", data);
      }
    } catch (error) {
      console.error("API call failed", error);
    }
  };

  // Format date for input fields
  const formatDateToInputValue = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ("0" + (d.getMonth() + 1)).slice(-2);
    const day = ("0" + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          backdropFilter: "blur(10px)",
        }}
      >
        <Modal.Header>
          <Modal.Title>{actionId ? "Edit" : "Create"} Training</Modal.Title>
          <Button
            variant="link"
            className="close-button p-0"
            onClick={handleClose}
          >
            <img src={CloseIcon} alt="close" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Name Of Training</Form.Label>
                  <Form.Control
                    type="text"
                    name="trainingTitle"
                    placeholder="Online mandatory trainings (global)"
                    value={formData.trainingTitle}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Select Category</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="text"
                      onDoubleClick={() =>
                        handleShowContent(
                          topicMapping && topicMapping.length
                            ? topicMapping.map((item) => item.topic).join(", ")
                            : "select training topic"
                        )
                      }
                      value={
                        topicMapping && topicMapping.length
                          ? topicMapping.map((item) => item.topic).join(", ")
                          : "select training topic"
                      }
                      readOnly
                    />
                    <div
                      className="filter-icon ms-2"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        openFilterModal();
                      }}
                    >
                      <img src={img} alt="Filter" />
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>KPI's</Form.Label>
                  <Form.Control
                    type="text"
                    name="kpis"
                    placeholder="Automated Response After Selecting The Topic"
                    value={
                      topicMapping && topicMapping.length
                        ? topicMapping
                            .map((item) => item.principleHeading)
                            .join(", ")
                        : "select kpi"
                    }
                    onDoubleClick={() =>
                      handleShowContent(
                        topicMapping && topicMapping.length
                          ? topicMapping
                              .map((item) => item.principleHeading)
                              .join(", ")
                          : "select kpi"
                      )
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Training Details</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    placeholder="Details of the training"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Training Facilitator</Form.Label>
                  <Form.Control
                    type="text"
                    name="trainingFacilitator"
                    placeholder="Name"
                    value={formData.trainingFacilitator}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Trainers Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="trainers"
                    placeholder="Add up-to 3 trainers (comma separated)"
                    value={formData.trainers}
                    onChange={handleTrainerInputChange}
                  />
                </Form.Group>

                {/* Modal for collecting trainer details */}
                <Modal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  centered
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Trainer Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={trainerDetails.name}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Email Address <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email address"
                          value={trainerDetails.email}
                          onChange={handleModalInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="tel"
                          name="phone"
                          placeholder="Enter phone number"
                          value={trainerDetails.phone}
                          onChange={handleModalInputChange}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={saveTrainerDetails}
                      disabled={!trainerDetails.email}
                    >
                      Save Details
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
              <Col md={12}>
                {trainers.length > 0 && (
                  <div
                    className="mt-2 trainer-validation-section"
                    style={{ marginRight: "10px" }}
                  >
                    <p className="mb-2 ">Trainer Validation:</p>
                    <div className="trainer-list">
                      {trainers.map((trainer, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center mb-2 p-2"
                          style={{
                            backgroundColor: "#f8f9fa",
                            borderRadius: "8px",
                          }}
                        >
                          <div className="me-3" style={{ width: "24px" }}>
                            {!trainer.isValidated && (
                              <Form.Check
                                type="checkbox"
                                checked={trainer.isSelected}
                                onChange={() => handleCheckboxChange(index)}
                                aria-label={`Select ${trainer.name}`}
                              />
                            )}
                          </div>
                          <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="trainer-name">
                                {trainer.name}
                              </span>
                              <div className="ms-auto d-flex">
                                {" "}
                                {/* ms-auto pushes this content to the right */}
                                {!trainer.isValidated && (
                                  <div className="ms-2">
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      onClick={() =>
                                        handleShowDetails(trainer, index)
                                      }
                                    >
                                      {trainer.email || trainer.phone
                                        ? "View Details"
                                        : "Add Details"}
                                    </Button>
                                  </div>
                                )}
                                <span
                                  className="ms-2"
                                  style={{
                                    backgroundColor: trainer.isValidated
                                      ? "#d1e7dd"
                                      : "#f8d7da",
                                    color: trainer.isValidated
                                      ? "#0f5132"
                                      : "#842029",
                                    padding: "0.24em 0.65em",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {trainer.isValidated
                                    ? "Validated"
                                    : "Needs Account ?"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={3}>
                <Form.Group>
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="fromDate"
                    value={formatDateToInputValue(formData.fromDate)}
                    onChange={handleChange}
                    min={new Date().toISOString().split("T")[0]}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="mx-0">
                <Form.Group>
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="toDate"
                    value={formatDateToInputValue(formData.toDate)}
                    onChange={handleChange}
                    min={
                      formatDateToInputValue(formData.fromDate) ||
                      new Date().toISOString().split("T")[0]
                    }
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>From Time</Form.Label>
                  <Form.Control
                    type="time"
                    name="fromTime"
                    value={formData.fromTime}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>To Time</Form.Label>
                  <Form.Control
                    type="time"
                    name="toTime"
                    value={formData.toTime}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Mode Of Training</Form.Label>
                  <Form.Select
                    name="modeOfTraining"
                    value={formData.modeOfTraining}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="ONLINE">Online</option>
                    <option value="OFFLINE">Offline</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Location</Form.Label>
                  {formData.modeOfTraining === "ONLINE" && (
                    <Form.Control
                      type="text"
                      name="linkOrVenues"
                      placeholder="Add venue link"
                      value={formData.linkOrVenues}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          linkOrVenues: e.target.value,
                        })
                      }
                      required
                    />
                  )}

                  {formData.modeOfTraining === "OFFLINE" && (
                    <PlacesAutocomplete
                      value={formData.linkOrVenues}
                      onChange={(address) =>
                        setFormData({ ...formData, linkOrVenues: address })
                      }
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Form.Control
                            {...getInputProps({
                              placeholder: "Search City, State, or Country...",
                              required: true,
                            })}
                          />
                          <div className="autocomplete-dropdown">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const terms = suggestion.terms || [];
                              const locationDetails = terms
                                .slice(0, 3)
                                .map((term) => term.value)
                                .join(", ");

                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#f4f4f4"
                                  : "#ffffff",
                                cursor: "pointer",
                                padding: "10px",
                              };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                  key={suggestion.placeId}
                                >
                                  {locationDetails}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  )}
                </Form.Group>
              </Col>
            </Row>

            {/* <Row className="mb-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label>Target Audience</Form.Label>
                  <div className="d-flex mb-2">
                    <Form.Check
                      type="radio"
                      id="internalAudience"
                      name="audienceType"
                      label="Internal"
                      checked={audienceType === "internal"}
                      onChange={() => setAudienceType("internal")}
                      className="me-3"
                    />
                    <Form.Check
                      type="radio"
                      id="externalAudience"
                      name="audienceType"
                      label="External"
                      checked={audienceType === "external"}
                      onChange={() => setAudienceType("external")}
                    />
                  </div>
                  
                  <div className="audience-dropdown">
                    <div 
                      className="form-control d-flex justify-content-between align-items-center" 
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      style={{ cursor: "pointer" }}
                    >
                      <span>
                        {selectedOptions.length > 0
                          ? `${selectedOptions.length} option(s) selected`
                          : "Select target audience"}
                      </span>
                      <span>{isDropdownOpen ? "▲" : "▼"}</span>
                    </div>
                    
                    {isDropdownOpen && (
                      <div className="dropdown-menu show w-100">
                        <div className="dropdown-item">
                          <Form.Check
                            type="checkbox"
                            id="selectAll"
                            label="Select All"
                            checked={isAllSelected}
                            onChange={() => toggleOption("ALL")}
                          />
                        </div>
                        <div className="dropdown-divider"></div>
                        {targetAudienceOptions.map((option) => (
                          <div className="dropdown-item" key={option.value}>
                            <Form.Check
                              type="checkbox"
                              id={option.value}
                              label={option.label}
                              checked={selectedOptions.includes(option.value)}
                              onChange={() => toggleOption(option.value)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={desable}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Topic Filter Modal */}
      {isFilterModalOpen && (
        <TrainingFilterModal
          onClose={closeFilterModal}
          topicMapping={topicMapping}
          setTopicMapping={setTopicMapping}
          trainingTopicData={trainingTopicData}
          show={isFilterModalOpen}
        />
      )}

      {/* Content Display Modal */}
      <Modal
        show={showContentModal}
        onHide={() => setShowContentModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>{contentModalData}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowContentModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TrainingModal;
