import React, { useState } from "react";
import BarComponentWater from "./FrameworkOne/BarComponentWater";
import WaterBarFourtyEight from "./FrameworkFourtyEight/WaterBarFourtyEight";
import TotalTreatedWater from "./FrameworkFourtyEight/TotalTreatedWater";
import WaterRecyclePercentage from "./FrameworkFourtyEight/WaterRecyclePercentage";
import VerticalBarWaterComponent from "./FrameworkFourtyEight/VerticalBarWaterComponent";
import ShowTotalValue from "./FrameworkFourtyEight/ShowTotalValue";
import VerticalBarComponentWater from "./FrameworkOne/VerticalBarComponentWater";

const AllLocAllTime = ({
  companyFramework,
  brief,
  matchedDataWater,
  matchedWaterDis,
}) => {
  const [activeTab, setActiveTab] = useState("stacked");

  // Shared tab button styles
  const tabButtonStyle = {
    flex: 1,
    background: "transparent",
    border: "none",
    padding: "12px 20px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#64748b",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    outline: "none",
  };

  const activeTabStyle = {
    backgroundColor: "white",
    color: "#4f46e5",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
  };

  const svgStyle = {
    width: "18px",
    height: "18px",
  };

  // Tab container styles
  const tabsContainerStyle = {
    display: "flex",
    backgroundColor: "#f5f7fa",
    borderRadius: "12px",
    padding: "6px",
    marginBottom: "24px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
    border: "1px solid #e2e8f0",
  };

  return (
    <div>
      {/* Tabs */}
      <div style={tabsContainerStyle}>
        <button
          style={{
            ...tabButtonStyle,
            ...(activeTab === "stacked" ? activeTabStyle : {}),
          }}
          onClick={() => setActiveTab("stacked")}
        >
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="3" y1="9" x2="21" y2="9"></line>
            <line x1="3" y1="15" x2="21" y2="15"></line>
            <line x1="9" y1="9" x2="9" y2="21"></line>
          </svg>
          Stacked View
        </button>
        <button
          style={{
            ...tabButtonStyle,
            ...(activeTab === "bar" ? activeTabStyle : {}),
          }}
          onClick={() => setActiveTab("bar")}
        >
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="20" x2="18" y2="10"></line>
            <line x1="12" y1="20" x2="12" y2="4"></line>
            <line x1="6" y1="20" x2="6" y2="14"></line>
            <line x1="3" y1="20" x2="21" y2="20"></line>
          </svg>
          Bar View
        </button>
      </div>

      {/* Stacked Bar View */}
      {activeTab === "stacked" &&
        (companyFramework.includes(1) ? (
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "30vh", marginBottom: "3%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <BarComponentWater
                  matchedDataWater={matchedDataWater}
                  title={"Water Withdrawal"}
                />
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <BarComponentWater
                  matchedDataWater={matchedWaterDis}
                  title={"Water Discharged"}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ marginBottom: "3%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
               
                <ShowTotalValue brief={brief} type="ALL" />

            
                <WaterRecyclePercentage brief={brief} />

               
                
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
              
                 <WaterBarFourtyEight brief={brief} />

               
               
             
                <TotalTreatedWater brief={brief} />

              
                
              </div>
            </div>
          </div>
        ))}

      {/* Bar View */}
      {activeTab === "bar" &&
        (companyFramework.includes(1) ? (
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{  marginBottom: "3%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <VerticalBarComponentWater
                  matchedDataWater={matchedDataWater}
                  title={"Water Withdrawal"}
                />
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <VerticalBarComponentWater
                  matchedDataWater={matchedWaterDis}
                  title={"Water Discharged"}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row flex-space-between" style={{ marginBottom: "2%" }}>
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
              marginTop: "10px",
            }}
          >
            <ShowTotalValue brief={brief} type="ALL" />
          </div>
        
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
              marginTop: "10px",
            }}
          >
            <VerticalBarWaterComponent brief={brief} type="FUEL" />
          </div>
        </div>
        ))}

   
    </div>
  );
};

export default AllLocAllTime;
