import React from "react";
import TrainingBarComponent from "./FrameworkOne/TrainingarComponent";
import TrainingBarFourtyEightTwo from "./FrameworkFourtyEight/TrainingBarTwo";

const TrainingSingleLocSingleTime = ({
  companyFramework,
  dataOne,
  number,
  dataThree,
  brief,
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "25vh", marginBottom: "3%" }}
      >
        <div style={{ width: "100%" }}>
          <TrainingBarComponent
            number={0}
            dataOne={dataOne}
            title={"Health & Safety Measures"}
          />
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "25vh", marginBottom: "3%" }}
      >
        <div style={{ width: "100%" }}>
          <TrainingBarComponent
            number={1}
            dataOne={dataOne}
            title={"On Skill Upgradation"}
          />
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "25vh", marginBottom: "3%" }}
      >
        <TrainingBarComponent
          number={number}
          dataOne={dataThree}
          title={"Human Rights"}
        />
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "30vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "45%",
            }}
          >
            <div style={{ height: "80%" }}>
              {/* <TrainingBarFourtyEight brief={brief} /> */}
              <TrainingBarFourtyEightTwo
                brief={brief}
                categories={["Average training hours per employee"]}
                shortenedMap={{
                  "Average training hours per employee":
                    "Average training hours per employee",
                }}
                title="Average training hours per employee"
              />
            </div>

            <div style={{ height: "0%" }}></div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
            }}
          >
            <div style={{ height: "80%" }}>
              <TrainingBarFourtyEightTwo
                brief={brief}
                categories={[
                  "mock drills",
                  "Fire Safety Audits",
                  "Safety Trainings",
                  "Safety Committee Meetings",
                ]}
                shortenedMap={{
                  "mock drills": "Mock Drills",
                  "Fire Safety Audits": "Fire Safety Audits",
                  "Safety Trainings": "Safety Trainings",
                  "Safety Committee Meetings": "Safety Committee Meetings",
                }}
                title="Development & Training"
              />
            </div>
            <div style={{ height: "0%" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingSingleLocSingleTime;
