import React, { useState } from "react";
import WasteGeneratedFourtyEight from "./FrameworkFourtyEight/WasteGeneratedFourtyEight";
import WasteDonutFourtyEight from "./FrameworkFourtyEight/WasteDonutFourtyEight";
import TotalWasteGeneratedByTpe from "./FrameworkOne/TotalWasteGeneratedByTpe";
import WasteRecyPie from "./FrameworkOne/WasteRecyPie";
import WasteConsumptionFourtyEight from "./FrameworkFourtyEight/WasteConsumptionFourtyEight";
import VerticalWasteBarComponent from "./FrameworkFourtyEight/VerticalWasteBarComponent";
import TotalWasteGeneratedByVertical from "./FrameworkOne/TotalWasteGeneratedByVertical";

const AllLocAllTime = ({
  companyFramework,
  timePeriods,
  brief,
  timePeriodValues,
  locationOption,
  matchedDataWaste,
  wasteDisposal,
  wasteRecovered,
  bioMedicalBrief,
}) => {
  const [activeTab, setActiveTab] = useState("stacked");

  // Inline styles for tabs
  const tabsContainerStyle = {
    display: "flex",
    backgroundColor: "#f5f7fa",
    borderRadius: "12px",
    padding: "6px",
    marginBottom: "24px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
    border: "1px solid #e2e8f0",
  };

  const tabButtonStyle = {
    flex: 1,
    background: "transparent",
    border: "none",
    padding: "12px 20px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#64748b",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    outline: "none",
  };

  const activeTabStyle = {
    backgroundColor: "white",
    color: "#4f46e5",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
  };

  const svgStyle = {
    width: "18px",
    height: "18px",
  };

  return (
    <div>
      {/* Enhanced Tabs with Inline Styles */}
      <div style={tabsContainerStyle}>
        <button
          style={{
            ...tabButtonStyle,
            ...(activeTab === "stacked" ? activeTabStyle : {}),
          }}
          onClick={() => setActiveTab("stacked")}
        >
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="3" y1="9" x2="21" y2="9"></line>
            <line x1="3" y1="15" x2="21" y2="15"></line>
            <line x1="9" y1="9" x2="9" y2="21"></line>
          </svg>
          Stacked View
        </button>
        <button
          style={{
            ...tabButtonStyle,
            ...(activeTab === "bar" ? activeTabStyle : {}),
            ":hover:not(.active)": {
              backgroundColor: "rgba(99, 102, 241, 0.05)",
              color: "#4f46e5",
            },
          }}
          onClick={() => setActiveTab("bar")}
        >
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="20" x2="18" y2="10"></line>
            <line x1="12" y1="20" x2="12" y2="4"></line>
            <line x1="6" y1="20" x2="6" y2="14"></line>
            <line x1="3" y1="20" x2="21" y2="20"></line>
          </svg>
          Bar View
        </button>
      </div>

      {/* Stacked Bar View */}
      {activeTab === "stacked" &&
        (companyFramework.includes(1) ? (
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "60vh", marginBottom: "3%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <div style={{ height: "48%" }}>
                  <TotalWasteGeneratedByTpe
                    title={"Total Waste Generated"}
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    locationOption={locationOption}
                    matchedDataWaste={matchedDataWaste}
                  />
                </div>

                <div style={{ height: "48%" }}>
                  <TotalWasteGeneratedByTpe
                    title={"Total Waste Disposed"}
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    locationOption={locationOption}
                    matchedDataWaste={wasteDisposal}
                  />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <WasteRecyPie
                  timePeriods={timePeriods}
                  timePeriodValues={timePeriodValues}
                  locationOption={locationOption}
                  matchedDataWaste={wasteRecovered}
                />
              </div>
            </div>
            {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "22vh", marginBottom: "3%" }}
          >
          
          </div> */}
          </div>
        ) : (
          <>
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{ height: "60vh", marginBottom: "3%" }}
              >
                <div
                  className="firsthalfprogressenergy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                  }}
                >
                  <div>
                  <WasteGeneratedFourtyEight timePeriods={timePeriods} timePeriodValues={timePeriodValues} locationOption={locationOption} brief={brief} />

                  </div>

                  {/* <div>
                    <WasteGeneratedFourtyEight
                      timePeriods={timePeriods}
                      timePeriodValues={timePeriodValues}
                      locationOption={locationOption}
                      brief={wasteDisposal}
                    />
                  </div> */}
                </div>
                <div
                  className="secondhalfprogress"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "55%",
                  }}
                >
                  <div>
                  <WasteDonutFourtyEight brief={brief} />

                  </div>
                  <div style={{ height: "0%" }}>
                    {/* <CommonBarComponent
                renewableEnergy={renewableEnergy}
                nonRenewableEnergy={nonRenewableEnergy}
                com={"non"}
    /> */}
                  </div>
                </div>
              </div>
              {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "22vh", marginBottom: "3%" }}
          >
        
          </div> */}
            </div>
          </>
        ))}

      {/* Bar View */}
      {activeTab === "bar" &&
        (companyFramework.includes(1) ? (
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{  marginBottom: "3%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <div className = 'my-3'style={{ height: "100%" }}>
                  <TotalWasteGeneratedByVertical
                    title={"Total Waste Generated"}
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    locationOption={locationOption}
                    matchedDataWaste={matchedDataWaste}
                  />
                </div>

                <div className = 'my-3'style={{ height: "100%" }}>
                  <TotalWasteGeneratedByVertical
                    title={"Total Waste Disposed"}
                    timePeriods={timePeriods}
                    timePeriodValues={timePeriodValues}
                    locationOption={locationOption}
                    matchedDataWaste={wasteDisposal}
                  />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                 <div className = 'my-3'style={{ height: "49%" }}>
                <TotalWasteGeneratedByVertical
                   title={"Total Waste Recovered"}
                   timePeriods={timePeriods}
                   timePeriodValues={timePeriodValues}
                   locationOption={locationOption}                  
                  matchedDataWaste={wasteRecovered}
                />
                 </div>
              </div>
            </div>
            {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
      
      </div> */}
          </div>
        ) : (
          <>
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{ marginBottom: "2%" }}
              >
                <div
                  className="firsthalfprogressenergy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "65%" }} className="my-2">
                    <WasteConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="GEN"
                    />
                  </div>

                  <div style={{ height: "65%" }} className="my-2">
                    <WasteConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={bioMedicalBrief}
                      timePeriods={timePeriods}
                      type="BIO"
                    />
                  </div>
                </div>
                <div
                  className="secondhalfprogress"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "65%" }} className="my-2">
                    <WasteConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="DIS"
                    />
                  </div>
                  
                </div>
              </div>
              <div
                className="d-flex flex-row flex-space-between"
                style={{ height: "60vh", marginBottom: "3%" }}
              >
                <div
                  className="firsthalfprogressenergy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                  }}
                >
                  <div className="container"  style={{
                      marginBottom: "20px",
                    }}>
                    <VerticalWasteBarComponent
                      timePeriods={timePeriods}
                      timePeriodValues={timePeriodValues}
                      locationOption={locationOption}
                      brief={brief}
                      type="GEN"
                    />
                  </div>

                  <div
                    className="container"
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <VerticalWasteBarComponent
                      timePeriods={timePeriods}
                      timePeriodValues={timePeriodValues}
                      locationOption={locationOption}
                      brief={bioMedicalBrief}
                      type="BIO"
                    />
                  </div>
                </div>
                <div
                  className="secondhalfprogress"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "55%",
                  }}
                >
                  <div className="container"  style={{
                      marginBottom: "20px",
                    }}>
                    <VerticalWasteBarComponent
                      timePeriods={timePeriods}
                      timePeriodValues={timePeriodValues}
                      locationOption={locationOption}
                      brief={brief}
                      type="DIS"
                    />
                  </div>
                  <div style={{ height: "0%" }}>
                    {/* <CommonBarComponent
            renewableEnergy={renewableEnergy}
            nonRenewableEnergy={nonRenewableEnergy}
            com={"non"}
/> */}
                  </div>
                </div>
              </div>
              {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
    
      </div> */}
            </div>
          </>
        ))}
    </div>
  );
};

export default AllLocAllTime;
