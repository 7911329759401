import React from "react";
import EnvBarComponent from "./FrameworkOne/EnvBarComponent";
import WasteRecyPie from "../Waste/FrameworkOne/WasteRecyPie";
import BarComponent from "../Energy/Framework48/BarComponent";
import WaterBarFourtyEight from "../Water/FrameworkFourtyEight/WaterBarFourtyEight";
import WasteDonutFourtyEight from "../Waste/FrameworkFourtyEight/WasteDonutFourtyEight";

const EnvironmentSingleLocSingleTime = ({
  companyFramework,
  sectorQuestionAnswerDataForGraph,
  timePeriods,
  briefEnergy,
  briefWater,
  briefWaste,
  graphData,
  totalConsumption,
  totalConsumptionTwo,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  locationOption,
  wasteDisposal,
  renewableEnergy,
  nonRenewableEnergy,
  keyTab,
  timePeriodValues,
}) => {
  return companyFramework.includes(1) ?
    (
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "55vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "45%",
            }}
          >
            <div style={{ height: "48%" }}>
              <EnvBarComponent
                type="energy"
                totalConsumptionRenewable={totalConsumptionRenewable}
                totalConsumptionNonRenewable={totalConsumptionNonRenewable}
              />
            </div>

            <div style={{ height: "48%" }}>
              <EnvBarComponent
                type="water"
                totalConsumptionRenewable={totalConsumption}
                totalConsumptionNonRenewable={totalConsumptionTwo}
              />
            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
            }}
          >
            <WasteRecyPie
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              matchedDataWaste={wasteDisposal}
            />
          </div>
        </div>
        {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
      
      </div> */}
      </div>
    ) :
    (
      <>
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div style={{ height: "50%" }}>
                <BarComponent brief={briefEnergy} />
              </div>

              <div style={{ height: "48%" }}>
                <WaterBarFourtyEight brief={briefWater} />
              </div>
            </div>
            <div
              className="secondhalfprogress"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "55%",
              }}
            >
              <div style={{ height: "100%" }}>
                <WasteDonutFourtyEight brief={briefWaste} />
              </div>
              <div style={{ height: "0%" }}>
                {/* <CommonBarComponent
            renewableEnergy={renewableEnergy}
            nonRenewableEnergy={nonRenewableEnergy}
            com={"non"}
/> */}
              </div>
            </div>
          </div>
          {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "22vh", marginBottom: "3%" }}
      >
    
      </div> */}
        </div>
      </>
    );
};

export default EnvironmentSingleLocSingleTime;
