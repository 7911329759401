import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import "./SignUpTrainee.css";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { history } from "../../_helpers/history";
import { NavLink } from "react-router-dom";

const RegisterForm = () => {
  const { token } = useParams();
  const [employeeId, setEmployeeId] = useState("");
  const [email, setEmail] = useState("");
  const [invitedEmail, setInvitedEmail] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("MALE");
  const [categoryId, setCategoryId] = useState("");
  const [departmentId, setDepartmentId] = useState("");

  const handleSubmit = async (e) => {
    const result = token.includes("&") ? token.split("&")[0] : null;
    e.preventDefault();
    const payload = {
      employeeId,
      email,
      firstName,
      lastName,
      gender,
      token: result ? result : token,
      userType: "TRAINEE",
      categoryId: parseInt(categoryId),
      departmentId: parseInt(departmentId),
    };
    const { isSuccess, data } = await apiCall(
      `${config.AUTH_API_URL_COMPANY}signupTrainee`,
      {},
      { ...payload },
      "POST"
    );
    if (isSuccess) {
      history.push(`/#/trainee_invite/${token}`);
      window.location.reload();
    }
  };

  const categoryOptions = [
    { label: "Permanent Employees", value: "EMPLOYEES_PERMANENT" },
    { label: "Other than Permanent Employees", value: "EMPLOYEES_TEMPORARY" },
    { label: "Permanent Workers", value: "WORKERS_PERMANENT" },
    { label: "Other than Permanent Workers", value: "WORKERS_TEMPORARY" },
    { label: "KMP", value: "KMP" },
    { label: "BOD", value: "BOD" },
    { label: "Customers", value: "CUSTOMERS" },
    { label: "Suppliers", value: "SUPPLIERS" },
    { label: "Distributors", value: "DISTRIBUTORS" },
  ];

  return (
    <div className="register-container">
      <div className="register-form">
        <h2 className="form-title">Register Now</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="formEmployeeID">
            <Form.Label column sm="12">
              Employee ID
            </Form.Label>
            <Col sm="12">
              <Form.Control
                type="text"
                className="form-controll"
                placeholder="Enter Employee Unique ID"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Row className="mt-4">
            <Col sm="6">
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  className="form-controll"
                  type="text"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm="6" className="ms-2">
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-controll"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col sm="6">
              <Form.Group controlId="formEmail">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Mail ID"
                  className="form-controll"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm="6" className="ms-2">
              <Form.Group controlId="formGender">
                <Form.Label>Gender</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    className="form-controll"
                    as="select"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="OTHER">Other</option>
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-4 mb-4">
            <Col sm="6">
              <Form.Group controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    className="form-controll"
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">Select category</option>
                    {categoryOptions.map((category) => (
                      <option key={category.value} value={category.value}>
                        {category.label}
                      </option>
                    ))}
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
            </Col>
            <Col sm="6" className="ms-2">
              <Form.Group controlId="formDepartment">
                <Form.Label>Business/Department</Form.Label>
                <div className="select-wrapper">
                  <Form.Control
                    as="select"
                    className="form-controll"
                    value={departmentId}
                    onChange={(e) => setDepartmentId(e.target.value)}
                  >
                    <option value="">Select Business</option>
                    <option value="1">HR</option>
                    <option value="2">EHS</option>
                  </Form.Control>
                  <FiChevronDown className="dropdown-icon" />
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit" className="register-btn">
            REGISTER
          </Button>        
        </Form>
      </div>
    </div>
  );
};

export default RegisterForm;
