import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar/sidebar';
import Header from "../header/header";
import { apiCall } from '../../_services/apiCall';
import config from "../../config/config.json";
import TrainingStats from './components/TrainingStats';
import Training from './components/Training';

const TraineeOverview = ({ financialYearId }) => {
  const [financeObjct, setFinanceObjct] = useState();
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinanceObjct(data.data[data.data.length - 1].id);
      return (data.data[data.data.length - 1].id)

    }
  };

  useEffect(() => {
    getFinancialYear()

  }, [])


  return (
    <div
      className="d-flex flex-row mainclass"
      style={{ height: "100vh" }}
    >

      <div style={{}}>

        <div className="main_wrapper p-3">

          <div
            className="w-100"
            style={{
              paddingRight: "2.5%",
              marginLeft: "2%",
            }}
          >
          </div>
          <div className="w-100 p-4 ">

            <TrainingStats financialYearId={financialYearId} />
            <div >
              <Training financialYearId={financialYearId} />

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default TraineeOverview
