import React, { useEffect, useState } from "react";
import EnergyConsumptionFourtyEight from "./EnergyConsumptionFourtyEight";
import BarComponent from "./BarComponent";
import VerticalBarComponent from "./VerticalBarComponent";
import TotalEmissionGeneratedSingle from "./TotalEmissionGenerated";
import TotalVerticalBarComonenet from "./TotalVerticalBarComonenet";

const AllLocAllTime = ({
  companyFramework,
  timePeriods,
  timePeriodValues,
  emission,
  locationOption,
  totalScope1,
  totalScope2,
}) => {
  const [activeTab, setActiveTab] = useState("stacked");
  const [quarters, setQuarters] = useState([]);
  const [locations, setLocations] = useState([]);
  const [data, setData] = useState({ time: {}, location: {} });
  const [data1, setData1] = useState({ time: {}, location: {} });

  // Inline styles for tabs
  const tabsContainerStyle = {
    display: "flex",
    backgroundColor: "#f5f7fa",
    borderRadius: "12px",
    padding: "6px",
    marginBottom: "24px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
    border: "1px solid #e2e8f0",
  };

  const tabButtonStyle = {
    flex: 1,
    background: "transparent",
    border: "none",
    padding: "12px 20px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#64748b",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    outline: "none",
  };

  const activeTabStyle = {
    backgroundColor: "white",
    color: "#4f46e5",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
  };

  const svgStyle = {
    width: "18px",
    height: "18px",
  };

  function convertMixedData(mixedArray) {
    return mixedArray.map((data) => {
      if (Array.isArray(data.answer) && Array.isArray(data.answer[0])) {
        const flattenedAnswer = data.answer.flat();
        const summedValue = flattenedAnswer.reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );

        return {
          questionId: data.questionId,
          sourceId: data.sourceId,
          answer: {
            process: 1,
            readingValue: summedValue.toString(),
            unit: "KG",
          },
          title: data.title,
          question_details: data.question_details,
          formDate: data.formDate,
          toDate: data.toDate,
          fuelType: data.fuelType, // Adding fuelType property
        };
      } else {
        return {
          ...data,
          answer: {
            ...data.answer,
            readingValue: data?.answer?.readingValue || "0",
          },
        };
      }
    });
  }

  useEffect(() => {
    if (
      emission &&
      locationOption &&
      companyFramework &&
      companyFramework.includes(48)
    ) {
      const valuesArray = locationOption
        ? locationOption.map((item) => item.unitCode || item.value)
        : [];

      const transformedKeys = Object.keys(timePeriods).map((key) => key);

      setQuarters(transformedKeys);
      setLocations(valuesArray);

      const summary = {
        time: {},
        location: {},
      };

      locationOption.forEach((location) => {
        transformedKeys.forEach((quarter) => {
          summary.location[quarter] = {
            Diesel: new Array(locationOption.length).fill(0),
            Petrol: new Array(locationOption.length).fill(0),
            CNG: new Array(locationOption.length).fill(0),
            PNG: new Array(locationOption.length).fill(0),
            LPG: new Array(locationOption.length).fill(0),
            "Furnace Oil": new Array(locationOption.length).fill(0),
            Coal: new Array(locationOption.length).fill(0),
            Briquette: new Array(locationOption.length).fill(0),
          };
        });
      });

      transformedKeys.forEach((quarter) => {
        locationOption.forEach((location) => {
          summary.time[location.unitCode] = {
            Diesel: new Array(transformedKeys.length).fill(0),
            Petrol: new Array(transformedKeys.length).fill(0),
            CNG: new Array(transformedKeys.length).fill(0),
            PNG: new Array(transformedKeys.length).fill(0),
            LPG: new Array(transformedKeys.length).fill(0),
            "Furnace Oil": new Array(transformedKeys.length).fill(0),
            Coal: new Array(transformedKeys.length).fill(0),
            Briquette: new Array(transformedKeys.length).fill(0),
          };
        });
      });

      const filteredData =
        emission &&
        emission.filter(
          (item) =>
            item.questionId === 289 ||
            item.questionId === 293 ||
            item.questionId === 294 ||
            item.questionId === 295 ||
            item.questionId === 292 ||
            item.questionId === 495 ||
            item.questionId === 497 ||
            item.questionId === 499
        );

      const convertedData = convertMixedData(filteredData);
      const timeKey = [];
      const locationKey = [];

      for (const period in summary.location) {
        timeKey.push(period);
      }

      for (const period in summary.time) {
        locationKey.push(period);
      }

      for (const location in summary.time) {
        const data = summary.time[location];
        for (const key in data) {
          for (let k = 0; k < summary.time[location][key].length; k++) {
            let time = timeKey[k];
            const obj = locationOption.find(
              (item) => item.unitCode === location
            );
            if (obj) {
              const lowerCaseKey = time;
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.fuelType === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.time[location][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
      }

      for (const time in summary.location) {
        const data = summary.location[time];
        for (const key in data) {
          for (let k = 0; k < summary.location[time][key].length; k++) {
            let location = locationKey[k];
            const obj = locationOption.find(
              (item) => item.unitCode === location
            );
            if (obj) {
              const lowerCaseKey = time;
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.fuelType === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.location[time][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
      }

      setData({
        time: summary.time,
        location: summary.location,
      });
    }
  }, [locationOption, timePeriods, emission]);

  useEffect(() => {
    if (
      emission &&
      locationOption &&
      companyFramework &&
      companyFramework.includes(48)
    ) {
      const valuesArray = locationOption
        ? locationOption.map((item) => item.unitCode || item.value)
        : [];

      const transformedKeys = Object.keys(timePeriods).map((key) => key);

      setQuarters(transformedKeys);
      setLocations(valuesArray);

      const summary = {
        time: {},
        location: {},
      };

      locationOption.forEach((location) => {
        transformedKeys.forEach((quarter) => {
          summary.location[quarter] = {
            "GRID electricity": new Array(locationOption.length).fill(0),
            "Electricity Power plant (Captive Power Plant - Natural Gas)":
              new Array(locationOption.length).fill(0),
          };
        });
      });

      transformedKeys.forEach((quarter) => {
        locationOption.forEach((location) => {
          summary.time[location.unitCode] = {
            "GRID electricity": new Array(transformedKeys.length).fill(0),
            "Electricity Power plant (Captive Power Plant - Natural Gas)":
              new Array(transformedKeys.length).fill(0),
          };
        });
      });

      const filteredData = emission.filter(
        (item) => item.questionId === 468 || item.questionId === 426
      );

      const convertedData = convertMixedData(filteredData);
      const timeKey = [];
      const locationKey = [];

      for (const period in summary.location) {
        timeKey.push(period);
      }

      for (const period in summary.time) {
        locationKey.push(period);
      }

      for (const location in summary.time) {
        const data = summary.time[location];
        for (const key in data) {
          for (let k = 0; k < summary.time[location][key].length; k++) {
            let time = timeKey[k];
            const obj = locationOption.find(
              (item) => item.unitCode === location
            );
            if (obj) {
              const lowerCaseKey = time;
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.fuelType === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.time[location][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
      }

      for (const time in summary.location) {
        const data = summary.location[time];
        for (const key in data) {
          for (let k = 0; k < summary.location[time][key].length; k++) {
            let location = locationKey[k];
            const obj = locationOption.find(
              (item) => item.unitCode === location
            );
            if (obj) {
              const lowerCaseKey = time;
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.fuelType === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.location[time][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
      }

      setData1({
        time: summary.time,
        location: summary.location,
      });
    }
  }, [locationOption, timePeriods, emission]);

  const renderDashboard = () => {
    if (companyFramework && companyFramework.includes(1)) {
      return (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "22vh", marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="my-2">
                <TotalEmissionGeneratedSingle
                  consumption={totalScope1 || 0}
                  notGenerated={totalScope2 || 0}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div style={{ height: "65%" }} className="my-2">
                <EnergyConsumptionFourtyEight
                  timePeriodValues={timePeriodValues}
                  brief={data}
                  timePeriods={timePeriods}
                  type="Scope1"
                />
              </div>

              <div style={{ height: "100%" }} className="my-2">
                <BarComponent brief={data} type="Scope1" />
              </div>
            </div>
            <div
              className="secondhalfprogress"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div style={{ height: "65%" }} className="my-2">
                <EnergyConsumptionFourtyEight
                  timePeriodValues={timePeriodValues}
                  brief={data1}
                  timePeriods={timePeriods}
                  type="Scope2"
                />
              </div>

              <div style={{ height: "100%" }} className="my-2">
                <BarComponent brief={data1} type="Scope2" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderVerticalDashboard = () => {
    if (companyFramework && companyFramework.includes(1)) {
      return (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ marginBottom: "3%" }}
          >
             <div
              className="firsthalfprogressenergy"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div className="my-2 container">
                <TotalVerticalBarComonenet
                  consumption={totalScope1 || 0}
                  notGenerated={totalScope2 || 0}
                />
              </div>
            </div>
         
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ marginBottom: "3%" }}
          >
            <div
              className="firsthalfprogressenergy"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div style={{ height: "100%" }} className="my-2">
                <EnergyConsumptionFourtyEight
                  timePeriodValues={timePeriodValues}
                  brief={data}
                  timePeriods={timePeriods}
                  type="Scope1"
                />
              </div>

              <div style={{ height: "100%" }} className="my-2 container">
                <VerticalBarComponent brief={data} type="Scope1" />
              </div>
            </div>
            <div
              className="secondhalfprogress"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div style={{ height: "100%" }} className="my-2">
                <EnergyConsumptionFourtyEight
                  timePeriodValues={timePeriodValues}
                  brief={data1}
                  timePeriods={timePeriods}
                  type="Scope2"
                />
              </div>

              <div style={{ height: "100%" }} className="my-2 container">
                <VerticalBarComponent brief={data1} type="Scope2" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {/* Enhanced Tabs with Inline Styles */}
      <div style={tabsContainerStyle}>
        <button
          style={{
            ...tabButtonStyle,
            ...(activeTab === "stacked" ? activeTabStyle : {}),
          }}
          onClick={() => setActiveTab("stacked")}
        >
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="3" y1="9" x2="21" y2="9"></line>
            <line x1="3" y1="15" x2="21" y2="15"></line>
            <line x1="9" y1="9" x2="9" y2="21"></line>
          </svg>
          Stacked View
        </button>
        <button
          style={{
            ...tabButtonStyle,
            ...(activeTab === "bar" ? activeTabStyle : {}),
          }}
          onClick={() => setActiveTab("bar")}
        >
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="20" x2="18" y2="10"></line>
            <line x1="12" y1="20" x2="12" y2="4"></line>
            <line x1="6" y1="20" x2="6" y2="14"></line>
            <line x1="3" y1="20" x2="21" y2="20"></line>
          </svg>
          Bar View
        </button>
      </div>

      {/* Content based on active tab */}
      {activeTab === "stacked" && renderDashboard()}
      {activeTab === "bar" && renderVerticalDashboard()}
    </div>
  );
};

export default AllLocAllTime;
