import React from "react";
import SafetyBarFourtyEight from "./FrameworkFourtyEight/SafetyBarFourtyEight";
import SafetyBar from "./FrameworkOne/SafetyBar";
import { StackedSafety } from "./FrameworkOne/StackedSafety";
import { StackedSafetyNonPerm } from "./FrameworkOne/StackedSafetyNonPermanant";
import SafetyVerticalBarFourtyEight from "./FrameworkFourtyEight/SafetyVerticalBarFourtyEight";

const AllLocAllTime = ({
  companyFramework,
  dataOne,
  titleOne,
  dataThree,
  dataTwo,
  titleTwo,
  titleThree,
  brief,
  type,
  safetyBrief,
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div style={{ width: "100%" }}>
          <StackedSafety title={titleOne} data={dataOne} />
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div style={{ width: "100%" }}>
          <StackedSafetyNonPerm title={titleTwo} data={dataTwo} />
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "30vh", marginBottom: "3%" }}
      >
        <SafetyBar
          type={type}
          title={titleThree}
          matchedDataWaste={dataThree}
        />
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "45%",
              marginBottom:'20px'
            }}
          >
            <div style={{ height: "100%" }} className="my-3">
              <SafetyBarFourtyEight
                brief={brief}
                heading = 'Safety Related Incidents (Bar View)'
                categories={[
                  "Fatalities (Number of cases)",
                  "High-consequence injuries (Number of cases)",
                  "Recordable injuries (Number of cases)",
                  "Recordable work-related ill health cases (Number of cases)",
                ]}
                shortenedMap={{
                  "Fatalities (Number of cases)": "Fatalities",
                  "High-consequence injuries (Number of cases)":
                    "High Consequences",
                  "Recordable injuries (Number of cases)": "Recordable",
                  "Recordable work-related ill health cases (Number of cases)":
                    "Work Related Ill Health",
                }}
              />
            </div>

            <div style={{ height: "100%" }} className="my-3">
              <SafetyVerticalBarFourtyEight
                brief={brief}
                heading = 'Safety Related Incidents (Stack View)'
                categories={[
                  "Fatalities (Number of cases)",
                  "High-consequence injuries (Number of cases)",
                  "Recordable injuries (Number of cases)",
                  "Recordable work-related ill health cases (Number of cases)",
                ]}
                shortenedMap={{
                  "Fatalities (Number of cases)": "Fatalities",
                  "High-consequence injuries (Number of cases)":
                    "High Consequences",
                  "Recordable injuries (Number of cases)": "Recordable",
                  "Recordable work-related ill health cases (Number of cases)":
                    "Work Related Ill Health",
                }}
              />
            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "55%",
              marginBottom:'20px'
            }}
          >
            <div style={{ height: "100%" }} className="my-3">
              <SafetyBarFourtyEight
                brief={safetyBrief}
                heading = 'Development & Training (Bar View)'
                categories={[
                  "Number of Mock Drills",
                  "High-consequence injuries (Number of cases)",
                  "Fire Safety Audits",
                  "Number of Safety Committee Meetings",
                ]}
                shortenedMap={{
                  "Number of Mock Drills": "Mock Drills",
                  "Number of Safety Trainings": "Safety Trainings",
                  "Fire Safety Audits": "Fire Safety Audits",
                  "Number of Safety Committee Meetings":
                    "Safety Committee Meetings",
                }}
              />
            </div>
            <div style={{ height: "100%" }} className="my-3">
              <SafetyVerticalBarFourtyEight
                brief={safetyBrief}
                heading = 'Development & Training (Stack View)'
                categories={[
                  "Number of Mock Drills",
                  "High-consequence injuries (Number of cases)",
                  "Fire Safety Audits",
                  "Number of Safety Committee Meetings",
                ]}
                shortenedMap={{
                  "Number of Mock Drills": "Mock Drills",
                  "Number of Safety Trainings": "Safety Trainings",
                  "Fire Safety Audits": "Fire Safety Audits",
                  "Number of Safety Committee Meetings":
                    "Safety Committee Meetings",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllLocAllTime;
