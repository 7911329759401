import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Close from "../img/Close.png";

const TrainingFilterModal = ({
  show,
  onClose,
  topicMapping,
  setTopicMapping,
  trainingTopicData,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTopicChange = (topic) => {
    const isAlreadySelected = topicMapping.some(
      (selectedTopic) => selectedTopic.id === topic.id
    );

    let updatedTopics;
    if (isAlreadySelected) {
      updatedTopics = topicMapping.filter(
        (selectedTopic) => selectedTopic.id !== topic.id
      );
    } else {
      updatedTopics = [...topicMapping, topic];
    }

    setTopicMapping(updatedTopics);
  };

  const handleRemoveSelectedTopic = (topicId) => {
    const updatedTopics = topicMapping.filter(
      (selectedTopic) => selectedTopic.id !== topicId
    );
    setTopicMapping(updatedTopics);
  };

  const filteredTopics = trainingTopicData.filter((topic) =>
    topic.topic?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isTopicSelected = (topicId) =>
    topicMapping.some((selectedTopic) => selectedTopic.id === topicId);

  return (
    <Modal 
      show={show} 
      onHide={onClose} 
      size="md"
      centered
      backdrop={false}
            // style={modalStyle()}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(10px)",
      }}
    >
      <Modal.Header style={modalHeaderStyle()}>
        <Modal.Title style={modalTitleStyle()}>Topic Filter</Modal.Title>
        <Button variant="link" style={closeButtonStyle()} onClick={onClose}>
          <img
            src={Close}
            alt="close"
            style={{ width: "20px", cursor: "pointer" }}
          />
        </Button>
      </Modal.Header>
      
      <Modal.Body style={modalBodyStyle()}>
        {/* Search Input */}
        <div style={{ marginBottom: "1em" }}>
          <Form.Control
            type="text"
            placeholder="Search topics"
            value={searchTerm}
            onChange={handleSearchChange}
            style={searchInputStyle()}
          />
        </div>

        {/* Selected Topics Box */}
        {topicMapping.length > 0 && (
          <div style={selectedTopicsBoxStyle()}>
            {topicMapping.map((topic) => (
              <div key={topic.id} style={selectedTopicItemStyle()}>
                <span>{topic.topic}</span>
                <button
                  style={removeButtonStyle()}
                  onClick={() => handleRemoveSelectedTopic(topic.id)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Training Topic List */}
        <div style={trainingTopicContainerStyle()}>
          <div style={{ fontSize: "16px", marginBottom: "4px", fontWeight: "bold" }}>
            Training Topics
          </div>
          {filteredTopics.length > 0 ? (
            filteredTopics.map((topic) => (
              <div
                key={topic.id}
                style={{ marginBottom: "10px", fontSize: "14px" }}
              >
                <label style={{ margin: "4px", display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    style={checkboxStyle()}
                    checked={isTopicSelected(topic.id)}
                    onChange={() => handleTopicChange(topic)}
                  />
                  <span style={{ marginLeft: "8px" }}>{topic?.topic}</span>
                </label>
              </div>
            ))
          ) : (
            <p>No topics available for your search term.</p>
          )}
        </div>
      </Modal.Body>
      
      <Modal.Footer style={modalFooterStyle()}>
        <Button 
          variant="primary" 
          onClick={onClose}
          style={saveButtonStyle()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Style functions
const modalStyle = () => ({
  zIndex: 1050
});

const modalHeaderStyle = () => ({
  borderBottom: "1px solid #dee2e6",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem"
});

const modalTitleStyle = () => ({
  fontSize: "1.25rem",
  fontWeight: "600"
});

const modalBodyStyle = () => ({
  padding: "1rem",
  maxHeight: "calc(100vh - 210px)",
  overflowY: "auto"
});

const modalFooterStyle = () => ({
  borderTop: "1px solid #dee2e6",
  display: "flex",
  justifyContent: "flex-end",
  padding: "1rem"
});

const closeButtonStyle = () => ({
  background: "transparent",
  border: "none",
  padding: "0",
  cursor: "pointer"
});

const searchInputStyle = () => ({
  width: "100%",
  padding: "10px",
  borderRadius: "5px",
  border: "1px solid #ccc",
  fontSize: "16px"
});

const trainingTopicContainerStyle = () => ({
  overflow: "auto",
  marginTop: "20px",
  paddingLeft: "10px",
  maxHeight: "250px"
});

const checkboxStyle = () => ({
  width: "16px",
  height: "16px",
  marginRight: "10px",
  border: "2px solid #3F88A5",
  borderRadius: "4px",
  cursor: "pointer"
});

const selectedTopicsBoxStyle = () => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  backgroundColor: "#f9f9f9",
  padding: "10px",
  borderRadius: "5px",
  marginBottom: "15px",
  maxHeight: "200px",
  overflowY: "auto"
});

const selectedTopicItemStyle = () => ({
  backgroundColor: "#3F88A5",
  color: "white",
  padding: "5px 10px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  height: "2rem"
});

const removeButtonStyle = () => ({
  background: "none",
  border: "none",
  color: "white",
  fontSize: "16px",
  cursor: "pointer",
  padding: "0",
  marginLeft: "5px"
});

const saveButtonStyle = () => ({
  backgroundColor: "#3F88A5",
  border: "none",
  padding: "6px 12px",
  borderRadius: "4px"
});

export default TrainingFilterModal;