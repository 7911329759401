import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { components } from "react-select";

const VerticalWasteBarComponent = ({ brief, type = null }) => {
  const [totalSum, setTotalSum] = useState(0);
  const [legendSums, setLegendSums] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const maxProducts = 5;

  const memoizedBrief = useMemo(() => brief, []);

  // Define a color map for the bars with more distinct colors
  const colorMap = [
    "#3498db", // Bright blue
    "#e74c3c", // Red
    "#2ecc71", // Green
    "#f39c12", // Orange
    "#9b59b6", // Purple
    "#1abc9c", // Turquoise
    "#d35400", // Pumpkin
    "#16a085", // Green sea
    "#c0392b", // Dark red
    "#8e44ad"  // Wisteria
  ];

  // Extract the list of product options based on the type
  const productOptions = useMemo(() => {
    // Define the products for each type
    const typeProducts = {
        GEN: [
            "Total e-waste generated",
            "Total hazardous waste (spent oil/lubricants etc)",
            "Total non-hazardous solid waste generated (black category general waste)",
            "Total non-hazardous solid waste sent to landfill (construction waste/other waste to landfill)",
            "Total packaging waste (Non-Plastic) generated",
            "Total plastic packaging waste generated",
          ],
          DIS: [
            "Total e-waste disposed",
            "Total metal scraps disposed",
          ],
          BIO: [
            "Yellow",
            "Red",
            "White",
            "Blue",
            "Cytotoxic"
          ],
    };
    
    // Get the product list for the current type
    const products = typeProducts[type] || [];
    
    // Convert the list to options format for the dropdown
    return products.map(product => ({
      label: product,
      value: product
    }));
  }, [type]);

  // Set initial selected products to the first five products or update when type changes
  useEffect(() => {
    if (productOptions.length > 0) {
      const initialProducts = productOptions
        .slice(0, maxProducts)
        .map((item) => item.value);
      
      // Always update selected products when type changes
      setSelectedProducts(initialProducts);
    }
  }, [productOptions, type]);

  // Define product lists for each type
   const typeProductsMap = useMemo(() => ({
    GEN: [
      "Total e-waste generated",
      "Total hazardous waste (spent oil/lubricants etc)",
      "Total non-hazardous solid waste generated (black category general waste)",
      "Total non-hazardous solid waste sent to landfill (construction waste/other waste to landfill)",
      "Total packaging waste (Non-Plastic) generated",
      "Total plastic packaging waste generated",
    ],
    DIS: [
      "Total e-waste disposed",
      "Total metal scraps disposed",
    ],
    BIO: [
      "Yellow",
      "Red",
      "White",
      "Blue",
      "Cytotoxic"
    ],
  }), []);
  
  // Function to calculate the total sum for each legend (key)
  useEffect(() => {
    if (brief && brief.time) {
      let filteredKeys = Object.values(brief.time);
      const currentTypeProducts = typeProductsMap[type] || [];
      
      // Filter data based on type
      filteredKeys = filteredKeys.map(obj => {
        const filteredObj = {};
        Object.keys(obj).forEach(key => {
          if (currentTypeProducts.includes(key)) {
            filteredObj[key] = obj[key];
          }
        });
        return filteredObj;
      });

      const locationData = filteredKeys;
      const legendTotals = {};

      // Loop through each time object
      locationData.forEach((time) => {
        for (const key in time) {
          if (time.hasOwnProperty(key)) {
            const valueArray = time[key];
            const value = Array.isArray(valueArray) ? (valueArray.reduce((acc, curr) => acc + curr, 0)).toFixed(2) : 0;
            legendTotals[key] = Number(Number((legendTotals[key] || 0) + Number(value)).toFixed(2));
          }
        }
      });

      setLegendSums(legendTotals);
    }
  }, [brief]);

  const adjustAndRoundTotalSum = (totalSum) => {
    const thresholds = [
      10, 25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000,
    ];

    if (totalSum < 1) {
      if (totalSum < 0.01) {
        return Math.ceil(totalSum * 200) / 200;
      } else if (totalSum < 0.1) {
        return Math.ceil(totalSum * 100) / 100;
      } else {
        return Math.ceil(totalSum * 2) / 2;
      }
    }

    for (let i = thresholds.length - 1; i >= 0; i--) {
      if (totalSum > thresholds[i]) {
        return Math.ceil(totalSum / thresholds[i]) * thresholds[i];
      }
    }

    return totalSum;
  };

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const selectedTotal = Object.keys(legendSums)
        .filter((key) => selectedProducts.includes(key))
        .reduce((sum, key) => sum + (legendSums[key] || 0), 0);

      if (selectedTotal !== totalSum) {
        setTotalSum(adjustAndRoundTotalSum(selectedTotal));
      }
    } else {
      const total = Object.values(legendSums).reduce(
        (sum, val) => sum + val,
        0
      );
      if (total !== totalSum) {
        setTotalSum(adjustAndRoundTotalSum(total));
      }
    }
  }, [selectedProducts, legendSums]);

  // Filter the legendSums to only include selected products
  const filteredLegendSums = selectedProducts.length
    ? Object.fromEntries(
        Object.entries(legendSums).filter(([key]) =>
          selectedProducts.includes(key)
        )
      )
    : legendSums;

  // Custom components for Select
  const CustomOption = (props) => {
    const { isSelected, data } = props;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            style={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                border: "2px solid #3f88a5",
                borderRadius: "2px",
                backgroundColor: isSelected ? "transparent" : "transparent",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isSelected && (
                <span style={{ color: "white", fontSize: "14px" }}>✔</span>
              )}
            </div>
          </div>

          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div style={{ fontSize: "14px", fontWeight: 300 }}>
              {data.label}
            </div>
          </div>
        </div>
      </components.Option>
    );
  };

  const CustomMultiValue = () => null;

  const CustomControl = (props) => {
    const { selectProps } = props;
    const { value } = selectProps;

    return (
      <components.Control {...props}>
        {(!value || value.length === 0) && (
          <div
            style={{
              color: "#3f88a5",
              fontWeight: 600,
              fontSize: "13px",
              position: "absolute",
              left: "5px",
              pointerEvents: "none",
            }}
          >
          </div>
        )}
        {value && value.length > 0 && (
          <div
            style={{
              color: "#3f88a5",
              marginLeft: "5px",
              fontSize: "12px",
              width: "70%",
            }}
          >
            {value[0].label}
          </div>
        )}
        {props.children}
      </components.Control>
    );
  };

  const CustomClearIndicator = () => null;

  return (
    <div className="vertical-bar-container" style={{ width: "100%", height: "100%" }}>
      <div
        className="vertical-bar-header"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "56px",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#011627",
          }}
        >
          Product Wise{" "}
          {type === "GEN"
              ? "Waste"
              : type === "DIS"
              ? "Waste Disposed"
              : type === "BIO"
              ? "Bio medical Waste"
              : ""}{" "}
        
        </div>
        <div style={{ marginBottom: "10px", width: "45%" }}>
          <Select
            isMulti
            options={productOptions}
            value={productOptions.filter((option) =>
              selectedProducts.includes(option.value)
            )}
            onChange={(selected) => {
              const selectedValues = selected.map((item) => item.value);

              if (selectedValues.length < 1) {
                alert("You must select at least one product.");
              } else if (selectedValues.length <= maxProducts) {
                setSelectedProducts(selectedValues);
              } else {
                alert(`You can only select up to ${maxProducts} products.`);
              }
            }}
            placeholder={`Select products`}
            hideSelectedOptions={false}
            components={{
              Option: CustomOption,
              Control: CustomControl,
              MultiValue: CustomMultiValue,
              ClearIndicator: CustomClearIndicator,
            }}
            closeMenuOnSelect={false}
            styles={{
              control: (base) => ({
                ...base,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 100,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#3f88a5",
                padding: "0 10px",
                fontSize: "20px",
                minHeight: "20px",
                minWidth: "20px",
              }),
              placeholder: (base) => ({
                ...base,
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "transparent"
                  : state.isFocused
                  ? "white"
                  : "white",
                color: state.isSelected ? "black" : "black",
                cursor: "pointer",
              }),
            }}
          />
        </div>
      </div>

      {/* Chart container */}
      <div style={{ 
        display: "flex", 
        height: "300px", 
        alignItems: "flex-end",
        marginBottom: "10px",
        position: "relative",
        paddingLeft: "40px"
      }}>
        {/* Y-axis labels */}
        <div style={{ 
          position: "absolute", 
          left: "0", 
          height: "100%", 
          display: "flex", 
          flexDirection: "column", 
          justifyContent: "space-between" 
        }}>
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>{(totalSum).toFixed(0)}</div>
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>{(totalSum * 0.75).toFixed(0)}</div>
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>{(totalSum * 0.5).toFixed(0)}</div>
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>{(totalSum * 0.25).toFixed(0)}</div>
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>0</div>
        </div>

        {/* Grid lines */}
        <div style={{ 
          position: "absolute", 
          left: "40px",
          width: "calc(100% - 40px)", 
          height: "100%", 
          borderLeft: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          pointerEvents: "none"
        }}>
          <div style={{ width: "100%", borderBottom: "1px dashed #ddd" }}></div>
          <div style={{ width: "100%", borderBottom: "1px dashed #ddd" }}></div>
          <div style={{ width: "100%", borderBottom: "1px dashed #ddd" }}></div>
          <div style={{ width: "100%", borderBottom: "1px dashed #ddd" }}></div>
          <div style={{ width: "100%", borderBottom: "1px solid #ddd" }}></div>
        </div>

        {/* Bars */}
        <div style={{ 
          display: "flex", 
          width: "calc(100% - 40px)", 
          height: "100%", 
          alignItems: "flex-end", 
          justifyContent: "space-around",
          position: "relative",
          zIndex: 1
        }}>
          {/* Individual product bars */}
          {Object.keys(filteredLegendSums).map((key, index) => {
            const value = filteredLegendSums[key];
            // Make sure heightPercentage is never zero, minimum 1% for visibility
            const heightPercentage = Math.max((value / totalSum) * 100, 1);
            
            return (
              <div key={key} style={{ 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                width: `${100 / (Object.keys(filteredLegendSums).length + 1)}%`, 
                height: "100%"
              }}>
                <div style={{ position: "relative", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                  {/* Value label */}
                  <div style={{
                    position: "absolute",
                    top: `calc(100% - ${heightPercentage}% - 25px)`,
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "center",
                    whiteSpace: "nowrap"
                  }}>
                    {value.toFixed(1)} GJ
                  </div>
                  
                  {/* Bar */}
                  <div style={{
                    height: `${heightPercentage}%`,
                    width: "60px",
                    backgroundColor: colorMap[index],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px"
                  }}></div>
                </div>
                
            
              </div>
            );
          })}
          
          {/* Total bar */}
          {Object.keys(filteredLegendSums).length > 0 && (
            <div style={{ 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              width: `${100 / (Object.keys(filteredLegendSums).length + 1)}%`, 
              height: "100%"
            }}>
              <div style={{ position: "relative", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                {/* Value label */}
                <div style={{
                  position: "absolute",
                  top: "-25px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textAlign: "center",
                  whiteSpace: "nowrap"
                }}>
                  {Object.values(filteredLegendSums).reduce((sum, value) => sum + value, 0).toFixed(1)} GJ
                </div>
                
                {/* Total Bar */}
                <div style={{
                  height: "100%",
                  width: "60px",
                  backgroundColor: "#34495e", // Dark color for total
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px"
                }}></div>
              </div>
              
           
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px"
        }}
      >
        <div style={{ fontSize: "12px", fontWeight: 400 }}>
          (in GJ)
        </div>
      </div>

      {/* Legend */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          marginTop: "20px"
        }}
      >
        {/* Product legends */}
        {Object.keys(filteredLegendSums).map((legend, index) => (
          <div
            key={legend}
            style={{
              width: "25%",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: colorMap[index],
                marginRight: "8px"
              }}
            ></div>
            <div
              style={{
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "90%"
              }}
              title={legend}
            >
              {legend}
            </div>
          </div>
        ))}
        
        {/* Total legend */}
        {Object.keys(filteredLegendSums).length > 0 && (
          <div
            style={{
              width: "25%",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: "#34495e", // Dark color for total
                marginRight: "8px"
              }}
            ></div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "bold"
              }}
            >
              Total
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerticalWasteBarComponent;