import React, { useEffect, useState } from 'react'
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
function Welcome({setFinancialYearId,financialYearId}) {
  const [financialYear, setFinancialYear] = useState([]);


  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data.reverse());
      if (data?.data?.length) {
        setFinancialYearId(data.data.reverse()[data?.data?.length - 1].id);
      }
    }
  };

  useEffect(() => {
    getFinancialYear();
  }, []);
  // Inline CSS styles
const headerContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "15px",
  marginBottom: "1em",
  marginRight: "3vw"
};
const headerTitleStyle = {
  fontSize: "28px",
  fontWeight: "bold",
};
const createButtonStyle = {
  backgroundColor: "#3F88A5",
  color: "white",
  borderRadius: "22px",
  border: "none",
  padding: "10px 20px",
  cursor: "pointer",
};
  return (
    <div className='text-center ' >
      <div style={headerContainerStyle}>
          <div style={headerTitleStyle}>Trainee Portal</div>
          <div>
            <select
              className="sector-question-select"
              value={
                financialYearId ||
                (financialYear.length > 0
                  ? financialYear[financialYear.length - 1].id
                  : "")
              }
              onChange={async (e) => {
                setFinancialYearId(e.target.value);
              }}
            >
              <option value={0}>Select Financial Year</option>
              {financialYear?.map((item, key) => (
                <option key={key} value={item.id}>
                  {item.financial_year_value}
                </option>
              ))}
            </select>
          </div>
        </div>
    </div>
  )
}

export default Welcome
