import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEmission from "./TopComponentEmission";
import LocationWiseEmission from "./LocationWiseEmission";
import GHGEmissions from "./GHGEmissions";
import EmissionAcrossLocation from "./EmissionAcrossLocation";
import ProductWiseEmission from "./ProductWiseEmission";
import ScopeTwo from "./ScopeTwo";
import ComparisonScope from "./ComparisonScope";
import ComparisonAcrossYear from "./ComparisonAcrossYear";
import TotalEmissionGenerated from "./TotalCommissionGranted";
import TotalGHGEmissionPercentage from "./TotalGHGEmission";
import ScopeMultiLocComparison from "./ScopeComMulLoc";
import TotalCarbonEmission from "./TotalCarbonEmission";
import EmissionIntensityOverTime from "./EmissionIIntensityOverTime";
import CarbonEmissionByScope from "./CarbonEmissionByScope";
import LineChartWithOptions from "./LineChartEmission";
import MixedChart from "./MixedChart";
import Speedometer from "./Speedometer";
import DifferentColumnCharts from "../Company Sub Admin/Pages/home/DifferentColumnCharts";
import TotalProductWise from "../Company Sub Admin/Pages/home/TotalProductWise";
import TotalEmissionGeneratedSingle from "./TotalEmissionGenerated";
import ScopeComSingleLoc from "./ScopeComSingleLoc";
import TotalCarbonGeneratedSingle from "./TotalCarbonSingleLoc";
import CarbonEmissionByScopeSingle from "./CarbonEmissionByScopeSingle";
import ProductWiseScopeTwo from "./ProductWiseScopeTwo";
import IntensityOverTime from "./IntensityOverTime";
import ProductWiseEmissionSingle from "./ProductWiseEmissionSingle";
import COTWOReduction from "./COTWOReduction";
import SingleLocMultTime from "./SingleLocMultTime";
import MultipleLocationMultipleTime from "../Energy/MultipleYearMultipleTime";
import MultipleYearMultipleTime from "./MultipleYearMultipleTime";
import AllLocAllTime from "./AllLocAllTime";

const Emission = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
  sectorQuestionAnswerDataForGraph,
  keyTab,
  compareLastTimePeriods,
  compareTCurrentimePeriods,
  financialYear,
}) => {
  const [lastWeekAcitivities, setLastWeekActivities] = useState();
  const [companyFramework, setCompanyFramework] = useState([]);
  const [timePeriodValues, setTimePeriodValues] = useState([]);
  const [totalConsumptionRenewable, setTotalConsumptionRenewable] = useState(0);
  const [totalConsumptionNonRenewable, setTotalConsumptionNonRenewable] =
    useState(0);
  const [renewableEnergy, setRenewableEnergy] = useState();
  const [nonRenewableEnergy, setNonRenewableEnergy] = useState();
  const [totalScope1, setTotalScope1] = useState(0);
  const [totalScope2, setTotalScope2] = useState(0);
  const [emission, setEmission] = useState(null);

  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };
  const getEnergyEmissionComparison = async () => {
    if (financialYearId) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getEnergyEmissionComparison`,
          {},
          { financialYearId, key:'EMISSION' },
          "GET"
        );

        if (isSuccess ) {
          setEmission(data.data)
        }
      } catch (error) {
        console.error("Error fetching total training data:", error);
        // Optionally, handle any error states here (e.g., show an error message)
      }
    }
  };
  useEffect(() => {
    if (companyFramework && companyFramework.includes(1)) {
      // this below code is for energy
      const timePeriodsArray = Object.values(timePeriods || []);
      setTimePeriodValues(timePeriodsArray);

      const energyRenew =
        graphData?.filter(
          (item) => item.title === "Energy Consumption from Renewable Sources"
        ) || [];
      const nonEnergyRenew =
        graphData?.filter(
          (item) =>
            item.title === "Energy Consumption from Non-renewable Sources"
        ) || [];

      // Safeguard against undefined timePeriodsArray
      const newEnergyRenew = energyRenew.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );
      const newNonEnergyRenew = nonEnergyRenew.filter((item) =>
        timePeriodsArray.includes(item.formDate)
      );

      const finalEnergy = newEnergyRenew.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalNonEnergy = newNonEnergyRenew.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      setRenewableEnergy(finalNonEnergy);
      setNonRenewableEnergy(finalNonEnergy);

      if (!Array.isArray(finalEnergy) || finalEnergy.length === 0) {
        setTotalConsumptionRenewable(0);
        return;
      }

      const firstObject = finalEnergy[0];

      // Extract all the rows but skip the first one
      const rowOptions = firstObject.question_details
        .filter((detail) => detail.option_type === "row") // Filter by "row" option_type
        .slice(1) // Skip the first row
        .map((detail) => detail.option)
        .reverse(); // Extract the "option" values

      const aggregatedValuesEnergy = rowOptions.map((_, index) =>
        finalEnergy.reduce((acc, obj) => {
          const value =
            obj.energyAndEmission &&
            Array.isArray(obj.energyAndEmission) &&
            obj.energyAndEmission[index]?.[0];
          return (
            acc +
            (value === "NA" || !value || value === "No" || value === "Yes"
              ? 0
              : parseFloat(value || 0))
          );
        }, 0)
      );

      const totalEnergy = aggregatedValuesEnergy.reduce(
        (sum, value) => sum + value,
        0
      );
      setTotalConsumptionRenewable(totalEnergy);
      const firstObjectNon = finalNonEnergy[0];

      // Extract all the rows but skip the first one
      const rowOptionsNon = firstObjectNon.question_details
        .filter((detail) => detail.option_type === "row") // Filter by "row" option_type
        .slice(1) // Skip the first row
        .map((detail) => detail.option)
        .reverse(); // Extract the "option" values

      const aggregatedValuesNon = rowOptionsNon.map((_, index) =>
        finalNonEnergy.reduce((acc, obj) => {
          const value =
            obj.energyAndEmission &&
            Array.isArray(obj.energyAndEmission) &&
            obj.energyAndEmission[index]?.[0];
          return (
            acc +
            (value === "NA" || !value || value === "No" || value === "Yes"
              ? 0
              : parseFloat(value || 0))
          );
        }, 0)
      );

      const totalNon = aggregatedValuesNon.reduce(
        (sum, value) => sum + value,
        0
      );
      setTotalConsumptionNonRenewable(totalNon);

      const aggregatedValuesEmission = rowOptionsNon.map((_, index) =>
        finalNonEnergy.reduce((acc, obj) => {
          const value =
            obj.energyAndEmission &&
            Array.isArray(obj.energyAndEmission) &&
            obj.energyAndEmission[index]?.[1];
          return (
            acc +
            (value === "NA" || !value || value === "No" || value === "Yes"
              ? 0
              : parseFloat(value || 0))
          );
        }, 0)
      );

      // Scope 2: Only consider the 0th index
      const totalEmissionScope2 = aggregatedValuesEmission[0] || 0;

      // Scope 1: Sum all other indices (excluding index 0)
      const totalEmissionScope1 = aggregatedValuesEmission
        .slice(1)
        .reduce((sum, value) => sum + value, 0);
      setTotalScope2(totalEmissionScope2);
      setTotalScope1(totalEmissionScope1);
      //this below code is for waste
    }else{
      getEnergyEmissionComparison();
    }
  }, [locationOption, graphData, timePeriods, companyFramework]);

  useEffect(() => {
    const activityData = {
      "Total Emission": {
        number: `${Number(totalScope1) + Number(totalScope2)} tCo2`,
        questionId: [],
      },
      "Scope 1": {
        number: `${Number(totalScope1 || 0)} tCo2`,
        questionId: [],
      },
      "Scope 2": {
        number: `${Number(totalScope2 || 0)} tCo2`,
        questionId: [],
      },
      message: "Good Evening, Sunil Kumar",
    };
    setLastWeekActivities(activityData);
  }, [totalConsumptionRenewable, totalConsumptionNonRenewable]);

  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue
        .map((value) => value?.id)
        .filter((id) => id !== undefined);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);

  return (
    <div className="progress-container">
       {companyFramework &&
        companyFramework.length > 0 &&
        companyFramework.includes(1) && (
          <div className="topcompo">
            {lastWeekAcitivities && (
              <TopComponentEmission
              lastWeekAcitivities={lastWeekAcitivities}
              icons={icons}
            />
            )}
          </div>
        )}
      

      {
        <div className="d-flex flex-column flex-space-between">
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <TotalEmissionGenerated />
            </div>
            <div className="secondhalfprogress">
              <TotalGHGEmissionPercentage />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <ScopeMultiLocComparison />
            </div>
            <div className="secondhalfprogress">
              <TotalCarbonEmission />
            </div>
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <EmissionIntensityOverTime />
            </div>
            <div className="secondhalfprogress">
              <CarbonEmissionByScope />
            </div>
          </div> */}
          {keyTab === "combinedAll" ? (
          <AllLocAllTime
          graphData={graphData}
          keyTab={keyTab}
          companyFramework={companyFramework}
          emission={emission}
          locationOption={locationOption}
          timePeriods={timePeriods}
          totalScope1={totalScope1}
          totalScope2={totalScope2}
        />
      ) : keyTab === "compareToYear" ? (
            <MultipleYearMultipleTime
              keyTab={keyTab}
              locationOption={locationOption}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              companyFramework={companyFramework}
              compareLastTimePeriods={compareLastTimePeriods}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              financialYear={financialYear}
            />
          ) : companyFramework &&
            companyFramework.length &&
            companyFramework.includes(1) ? (
            (timePeriodValues &&
              locationOption.length === 1 &&
              timePeriodValues.length === 1) ||
            (locationOption.length > 1 &&
              timePeriodValues.length === 1 &&
              keyTab === "combined") ? (
              <>
                <div className="d-flex flex-column flex-space-between">
                  <div
                    className="d-flex flex-row flex-space-between"
                    style={{ height: "22vh", marginBottom: "3%" }}
                  >
                    <TotalEmissionGeneratedSingle
                      consumption={totalScope1}
                      notGenerated={totalScope2}
                    />
                  </div>
                  <div
                    className="d-flex flex-row flex-space-between"
                    style={{ height: "30vh", marginBottom: "3%" }}
                  >
                    {/* <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
                <ScopeComSingleLoc title={"Scope 1 and Scope 2 Comparison"} />
              </div> */}
                    {/* <div className="secondhalfprogress" style={{ width: "50%" }}> */}
                    {/* <div style={{ height: "55%" , marginBottom:"2%"  }}>
                  <TotalCarbonGeneratedSingle />
                </div> */}
                    {/* <div style={{ height: "100%", width: "100%" }}>
                  <CarbonEmissionByScopeSingle
                    notGenerated={scopeOne}
                    maxConsumption={scopeOne + scopeTwo}
                    consumption={scopeTwo}
                  />
                </div> */}
                    {/* </div> */}
                  </div>
                  {/* <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
                <div style={{ height: "55%" ,  marginBottom:"2%"}}>
                  <ProductWiseScopeTwo
                    notGenerated={15}
                    maxConsumption={40}
                    consumption={10}
                  />
                </div>

                <div style={{ height:"48%" , }}>
                  <IntensityOverTime />
                </div>
              </div>
              <div className="secondhalfprogress" style={{ width: "50%" }}>
                <ProductWiseEmissionSingle />
              </div>
            </div> */}
                  {/* <div className="d-flex flex-row flex-space-between" style={{height:"30vh"}}>
              <COTWOReduction/>
  
            </div> */}
                </div>
              </>
            ) : (locationOption.length > 1 &&
                timePeriodValues.length > 1 &&
                keyTab === "combined") ||
              (locationOption.length > 1 && timePeriodValues.length === 1) ||
              (locationOption.length == 1 && timePeriodValues.length > 1) ? (
              <>
                <SingleLocMultTime
                  graphData={graphData}
                  keyTab={keyTab}
                  locationOption={locationOption}
                  timePeriods={timePeriods}
                  timePeriodValues={timePeriodValues}
                  renewableEnergy={renewableEnergy}
                  nonRenewableEnergy={nonRenewableEnergy}
                  totalConsumptionRenewable={totalConsumptionRenewable}
                  totalConsumptionNonRenewable={totalConsumptionNonRenewable}
                  companyFramework={companyFramework}
                />
              </>
            ) : (
              <>{/* <MultipleYearMultipleTime /> */}</>
            )
          ) : (
            // (
            //   <div className="d-flex flex-column flex-space-between">
            //     <div
            //       className="d-flex flex-row flex-space-between"
            //       style={{ height: "22vh", marginBottom: "3%" }}
            //     >
            //       <TotalEmissionGeneratedSingle
            //         consumption={totalScope1}
            //         notGenerated={totalScope2}
            //       />
            //     </div>
            //     <div
            //       className="d-flex flex-row flex-space-between"
            //       style={{ height: "30vh", marginBottom: "3%" }}
            //     >
            //       {/* <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
            //       <ScopeComSingleLoc title={"Scope 1 and Scope 2 Comparison"} />
            //     </div> */}
            //       {/* <div className="secondhalfprogress" style={{ width: "50%" }}> */}
            //       {/* <div style={{ height: "55%" , marginBottom:"2%"  }}>
            //         <TotalCarbonGeneratedSingle />
            //       </div> */}
            //       {/* <div style={{ height: "100%", width: "100%" }}>
            //         <CarbonEmissionByScopeSingle
            //           notGenerated={scopeOne}
            //           maxConsumption={scopeOne + scopeTwo}
            //           consumption={scopeTwo}
            //         />
            //       </div> */}
            //       {/* </div> */}
            //     </div>
            //     {/* <div
            //     className="d-flex flex-row flex-space-between"
            //     style={{ height: "70vh", marginBottom: "3%" }}
            //   >
            //     <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
            //       <div style={{ height: "55%" ,  marginBottom:"2%"}}>
            //         <ProductWiseScopeTwo
            //           notGenerated={15}
            //           maxConsumption={40}
            //           consumption={10}
            //         />
            //       </div>

            //       <div style={{ height:"48%" , }}>
            //         <IntensityOverTime />
            //       </div>
            //     </div>
            //     <div className="secondhalfprogress" style={{ width: "50%" }}>
            //       <ProductWiseEmissionSingle />
            //     </div>
            //   </div> */}
            //     {/* <div className="d-flex flex-row flex-space-between" style={{height:"30vh"}}>
            //     <COTWOReduction/>

            //   </div> */}
            //   </div>
            // )
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy">
                <LineChartWithOptions
                  locationOption={locationOption}
                  timePeriods={timePeriods}
                  financialYearId={financialYearId}
                  graphData={graphData}
                  emission={emission}
                />
              </div>
              <div className="secondhalfprogress">
                <ScopeMultiLocComparison
                  locationOption={locationOption}
                  timePeriods={timePeriods}
                  financialYearId={financialYearId}
                  graphData={graphData}
                  emission={emission}
                />
              </div>
            </div>
          )}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <LineChartWithOptions locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} />
            </div>
            <div className="secondhalfprogress">
              <ScopeMultiLocComparison   locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <MixedChart />
            </div>
            <div className="secondhalfprogress">
              <Speedometer />
            </div>
          </div> */}
        </div>
      }
    </div>
  );
};

export default Emission;
