import React, { Component } from "react";
import "./signup.css";
import logo from "../../img/Zais_logo.png";


export default class privacy extends Component {
  render() {
    return (
      <div>
        <section className="login">
          <div className="sing_log1">
            <div className="sing_one mb-4">
              <img style={{ width: "10%" }} src={logo} alt="logo" />
            </div>
            <div className="step-forms step-forms-active">
              <div className="text_sing1">
                <h4 className="Privacy">Privacy Policy</h4>
                <h4 className="justify-content-end textHead">Privacy Policy</h4>
                <p className="justify-content-end textPara">
                  This privacy statement is made on behalf of RIU and its related body corporates (“Zais”). All references to “Zais”, “we”, “us” or “our” in this policy are references to RIU. RIU is committed to protecting your privacy in accordance with the Australian Privacy Principles contained in the Privacy Act 1988 (Cth). This document sets out our policy with respect to the collection and management of personal information. We are committed to ensuring the privacy of your information and recognise that you, as a customer, are concerned about your privacy and about the confidentiality and security of information that we may hold about you. By using our website and/or our services, you consent to the collection, use and disclosure of your personal information in accordance with this policy.
                </p>
                <h4 className="justify-content-end textHead">Personal Information</h4>
                <p className="justify-content-end textPara">
                  Personal information is information or an opinion about an individual whose identity is apparent, or can reasonably be ascertained, from that information or opinion.
                </p>
                <h4 className="justify-content-end textHead">Open and transparent management of personal information</h4>
                <p className="justify-content-end textPara">
                  We seek to ensure that personal information we hold about an individual is managed in an open and transparent manner. We have implemented procedures to ensure compliance with the Australian Privacy Principles and any applicable codes, and to deal with any complaints relating to our compliance therewith.
                  <br />We have a clear and up-to-date privacy policy outlining our management of personal information, including details regarding the kind of personal information we collect and hold; how and why we collect and hold this information, and how an individual may access and seek correction of the information we hold about them. We further provide details regarding our complaints handling process, our policy on disclosure of information to overseas recipients.
                </p>
                <h4 className="justify-content-end textHead">Collection of personal information</h4>
                <p className="justify-content-end textPara">
                  We will, from time to time, receive and store personal information you enter onto our website, provided to us directly, or give to us in other forms.
                  <br />Information which you may provide and which is collected by us includes, but is not limited to your name, phone number, address and email address.
                  <br />We may collect additional information at other times including but not limited to, when you provide feedback, when you provide information about your personal or business affairs, change your content or email preferences, respond to surveys and/or promotions, provide financial or credit card information or communicate with our customer support.
                  <br />We only collect personal information directly from individuals, when it is reasonably necessary for us to do so, and only by lawful and fair means. We will always ensure you are apprised of our purpose in collecting information, and your right to gain access to such information. If you do not provide the information requested, we may be unable to provide you with our services.
                  <br />Wherever lawful and practicable, you may request to deal with us anonymously however, given the nature of our services, it is unlikely that this will be a viable option.
                  <br />RIU will also collect any personal information necessary for the purposes of complying with the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (Cth).
                </p>
                <h4 className="justify-content-end textHead">How we collect your personal information</h4>
                <p className="justify-content-end textPara">
                  We collect personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our website and when we provide our services to you.
                  <br />We may also receive personal information from third parties. If we do, we will protect it in accordance with this policy. You consent to such indirect collection of information in these circumstances, unless you advise us otherwise.
                </p>
                <h4 className="justify-content-end textHead">Unsolicited personal information</h4>
                <p className="justify-content-end textPara">
                  Where we receive personal information about an individual which is unsolicited by us and not required for the provision of our services, we will destroy the information (provided it is lawful and reasonable for us to do so).
                </p>
                <h4 className="justify-content-end textHead">Use of personal information</h4>
                <p className="justify-content-end textPara">
                  We will use personal information collected from you for the primary purpose of providing our services.
                  We may also use personal information for secondary purposes. This may include providing you with information, updates and other services. We may also make you aware of new and additional products, services and opportunities available to you.
                </p>
                <h4 className="justify-content-end textHead">Direct marketing</h4>
                <p className="justify-content-end textPara">
                  We may use personal information for direct marketing purposes where:
                  <ul>
                    <li>we have collected the personal information from you</li>
                    <li>you would reasonably expect us to use your personal information for the purpose of direct marketing</li>
                    <li>we provide you with a mechanism to request us not to send you marketing communications</li>
                    <li>you have not requested such communications cease.</li>
                  </ul>
                  We allow you to opt out of the receipt of direct marketing in each direct marketing communication. You can change your mind about receiving information at any time by emailing us at <a target="_blank" rel="noreferrer" href="mailto:contact@zais.tech">contact@zais.tech</a>. On occasion, the law requires us to advise you of certain changes to products/services or regulations. You will continue to receive this information from us even if you choose not to receive direct marketing information from us. We will not disclose your information to any outside parties for the purpose of allowing them to directly market to you.
                </p>
                <h4 className="justify-content-end textHead">Disclosure of personal information</h4>
                <p className="justify-content-end textPara">
                  We may disclose your personal information to our employees, insurers, professional advisers, agents, suppliers or subcontractors where it is reasonably necessary and for purposes set out in this document.
                  <br />We may also provide your personal information to third parties in order to provide you with our services.
                  <br />We may exchange your personal information with our professional advisers or agents, external service providers, your nominated professional advisers or representatives, government departments/agencies/bodies, other financial institutions, our insurers, or debt collectors. Examples include disclosure of your information to an external party providing electronic identification services, or to intermediary banks in order to process transactions on your behalf.
                  <br />We will only share personal information with overseas entities or persons to facilitate your transactions or comply with our legal obligations. Such entities or persons may include overseas intermediary banks, as well as our overseas branches and service providers.
                  <br />We may also disclose your personal information without consent where it is required or authorised by law.
                  <br />By providing us with personal information, you consent to the terms of this Privacy Policy and the types of disclosure covered by this policy.
                </p>
                <h4 className="justify-content-end textHead">Security of personal information</h4>
                <p className="justify-content-end textPara">
                  We are committed to ensuring that the information you provide to us is secure. In order to prevent unauthorised access or we have put in place suitable physical, electronic and managerial procedures to safeguard and secure information and protect it from misuse, loss and unauthorized access, modification and disclosure.
                  <br />Personal information imaged and stored on electronic databases requires password access and access is restricted to authorised personnel.
                  <br />Where information is no longer required to be held or retained by us for any purpose or legal obligation, we will take all reasonable steps to destroy or de-identify the information.
                  <br />The transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any information that you transmit to us or receive from us. Although we take measures to safeguard against unauthorised disclosure of information, we cannot assure you that personal information that we collect will not be disclosed in a manner that is inconsistent with our Privacy Policy.
                </p>
                <h4 className="justify-content-end textHead">Access to personal information</h4>
                <p className="justify-content-end textPara">
                  You may request access to details of personal information we hold about you in accordance with the law.
                  <br />We take all reasonable steps to ensure the personal information held about individuals is accurate, up-to-date and complete.
                  <br />Where we believe information we hold about an individual is inaccurate, out-of-date, incomplete, irrelevant or misleading, or you request us to correct information held about you, we will take all reasonable steps to correct such information in a reasonable time frame.
                  <br />If we refuse to comply with your correction request, we will notify you in writing of our reasons for such refusal, and the complaints process you may avail if you wish to challenge that decision. You may also request that we associate the personal information we hold with a statement regarding your view of its inaccuracy.
                  <br />If you would like a copy of the information which we hold about your or believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at <a target="_blank" rel="noreferrer" href="mailto:contact@zais.tech">contact@zais.tech</a>. We will not charge for reasonable access and correction requests.
                </p>
                <h4 className="justify-content-end textHead">Privacy complaints</h4>
                <p className="justify-content-end textPara">
                  If you have a complaint relating to our compliance with privacy laws or our treatment of your personal information, please contact us at <a target="_blank" rel="noreferrer" href="mailto:contact@zais.tech">contact@zais.tech</a>. We will investigate your complaint and endeavour to resolve the issue to your satisfaction.
                </p>
                <h4 className="justify-content-end textHead">Changes to our Privacy Policy</h4>
                <p className="justify-content-end textPara">
                  Please be aware that we may change this Privacy Policy in the future. We may modify this policy at any time, in our sole discretion and all modifications will be effective immediately upon us posting the changes on our website.
                </p>
                <h4 className="justify-content-end textHead">Website &amp; cookies</h4>
                <p className="justify-content-end textPara">
                  When you visit our website,  <a target="_blank" rel="noreferrer" href="https://www.zais.tech/">www.zais.tech</a>, we may collect certain information such as browser type, operating system, website visited immediately before coming to our site. This information is used in an aggregated manner to analyse how people use our site so that we can improve our services.
                  <br />We may from time to time use cookies on our website. Cookies are very small files which a website uses to identify you when you come back to the site and to store details about your use of the side. Cookies are not malicious programs that access or damage your computer. Most web browsers accept cookies, however you can choose to reject cookies by changing your browser setting. However, this may prevent you from taking full advantage of our website. Our website may from time to time use cookies to analyse website traffic and help us provide a better website visitor experience.
                  <br />Our site may from time to time have links to other websites not owned or controlled by us. These links are meant for your convenience only. Links to third party websites do not constitute our endorsement or approval of these websites. Please be aware that we are not responsible for the privacy practices of other websites. We encourage our users to be aware, when they leave our website, to read the privacy statement of each and every website that collects personal identifiable information.
                </p>
                <br />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
