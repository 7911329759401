import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import SafetyMultiBarYear from "./SafetyMultiBarYear";

const MultipleYearMultipleTimeForWorker = ({
  keyTab,
  locationOption,
  companyFramework,
  compareLastTimePeriods,
  compareTCurrentimePeriods,
  financialYear,
  type
}) => {
  const [graphData, setGraphData] = useState(null);
  const isMounted = useRef(true);
  const [permanentEmployeDiversity, setPermanentEmployeDiversity] = useState(null);
  const [otherThanPermanentEmployeDiversity, setOtherThanPermanentEmployeDiversity] = useState(null);
  const [timePerids, setTimePerids] = useState(null);


  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getCompareDiversityData = async () => {
    if (financialYear && financialYear.length >= 2) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getCompareSafetyData`,
          {},
          {
            financialYearIds: [
              financialYear[financialYear.length - 2].id,
              financialYear[financialYear.length - 1].id,
            ],
          },
          "GET"
        );

        if (isSuccess && isMounted.current) {
            const permanentEmployeDiversity =  data.data.filter(item => item.questionId === 123);
            setPermanentEmployeDiversity(permanentEmployeDiversity);
          setGraphData(data.data);
        }
      } catch (error) {
        console.error("Error fetching total training data:", error);
      }
    }
  };



  useEffect(() => {
     // Helper function to extract the year and month from a date
const getYearMonth = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}`; // Format as "YYYY-MM"
  };
  
  // Get the latest date based on year and month from compareLastTimePeriods
  const latestLastPeriodDate = Object.values(compareLastTimePeriods)
    .map((date) => getYearMonth(date))
    .sort((a, b) => b.localeCompare(a))[0]; // Sort and get the latest year-month
  
  // Get the corresponding key for the latest year-month in compareLastTimePeriods
  const latestLastPeriodKey = Object.keys(compareLastTimePeriods)
    .find((key) => getYearMonth(compareLastTimePeriods[key]) === latestLastPeriodDate);
  
  // Create an object with the key-value pair of the latest year-month
  const latestLastPeriodObj = {
    [latestLastPeriodKey]: latestLastPeriodDate
  };
  
  // Extract the latest year-month from compareTCurrentimePeriods
  const latestCurrentPeriodDate = Object.values(compareTCurrentimePeriods)
    .map((date) => getYearMonth(date))
    .sort((a, b) => b.localeCompare(a))[0]; // Sort and get the latest year-month
  
  // Get the corresponding key for the latest year-month in compareTCurrentimePeriods
  const latestCurrentPeriodKey = Object.keys(compareTCurrentimePeriods)
    .find((key) => getYearMonth(compareTCurrentimePeriods[key]) === latestCurrentPeriodDate);
  
  // Create an object with the key-value pair of the latest year-month
  const latestCurrentPeriodObj = {
    [latestLastPeriodKey]: latestLastPeriodDate,
    [latestCurrentPeriodKey]: latestCurrentPeriodDate
  };

  setTimePerids(latestCurrentPeriodObj)
  }, [compareLastTimePeriods,compareTCurrentimePeriods]);

  useEffect(() => {
    getCompareDiversityData();
  }, [financialYear]);

  const isCompareLastTimePeriodsValid =
    compareLastTimePeriods && Object.keys(compareLastTimePeriods).length > 0;
  const isCompareTCurrentimePeriodsValid =
    compareTCurrentimePeriods &&
    Object.keys(compareTCurrentimePeriods).length > 0;

  const shouldRenderGraphs =
    isCompareLastTimePeriodsValid && isCompareTCurrentimePeriodsValid;

  return companyFramework && companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
   {permanentEmployeDiversity && <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "80vh", marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "49%" }}>
          <SafetyMultiBarYear
            type={type}
            matchedDataWater={permanentEmployeDiversity}
            locationOption={locationOption}
            timePeriodValues={timePerids}
            number={0}
            timePeriods={timePerids}
            compareLastTimePeriods={compareLastTimePeriods}
            compareTCurrentimePeriods={compareTCurrentimePeriods}
            financialYear={financialYear}
          />
        </div>

        <div style={{ height: "49%" }}>
          <SafetyMultiBarYear
            type={type}
            timePeriods={timePerids}
            compareLastTimePeriods={compareLastTimePeriods}
            compareTCurrentimePeriods={compareTCurrentimePeriods}
            financialYear={financialYear}
            matchedDataWater={permanentEmployeDiversity}
            locationOption={locationOption}
            timePeriodValues={timePerids}
            number={2}
          />
        </div>
      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <div style={{ height: "49%" }}>
          <SafetyMultiBarYear
             timePeriods={timePerids}
             compareLastTimePeriods={compareLastTimePeriods}
             compareTCurrentimePeriods={compareTCurrentimePeriods}
             financialYear={financialYear}
            matchedDataWater={permanentEmployeDiversity}
            type={type}

            locationOption={locationOption}
            timePeriodValues={timePerids}
            number={1}
          />
        </div>
        <div style={{ height: "49%" }}>
          <SafetyMultiBarYear
             timePeriods={timePerids}
             compareLastTimePeriods={compareLastTimePeriods}
             compareTCurrentimePeriods={compareTCurrentimePeriods}
             financialYear={financialYear}
            number={3}
            locationOption={locationOption}
            type={type}

            timePeriodValues={timePerids}
            matchedDataWater={permanentEmployeDiversity}
          />
        </div>
      </div>
    </div>}
    {/* <div
      className="d-flex flex-row flex-space-between"
      style={{ height: "35vh", marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >
        <SafetyMultiBarYear
          timePeriods={timePeriods}
          locationOption={locationOption}
          timePeriodValues={timePeriodValues}
          number={4}

          matchedDataWater={dataThree}
        />

      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "50%",
        }}
      >

      </div>
    </div> */}
  </div>
  ) : (
    <></>
  );
};

export default MultipleYearMultipleTimeForWorker;
