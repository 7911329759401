import { faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import NoDataFound from "../../../img/no.png";
import AddTrainee from "./AddTrainee";
import TrainingModal from "./TrainingModal";

const TrainingTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [trainings, setTrainings] = useState([]);
  const [filterTrainings, setfilterTrainings] = useState([]);

  const [actionId, setActionId] = useState(null);
  const [mode, setMode] = useState(null);
  const [actionModalShow, setActionModalShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showAddTraineeModal, setShowAddTraineeModal] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showUserData, setShowUserData] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [financialYear, setFinancialYear] = useState([]);
  const [users, setUsers] = useState([]);

  const [financialYearId, setFinancialYearId] = useState(0);
  const [key, setKey] = useState("register"); // Default active tab

  // Helper function to render table rows
  const renderTableRows = (rows) => {
    return rows.map((user) => (
      <tr key={user.id}>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.emailId}</td>
        <td>{user.mobileNumber}</td>
        <td>{user.employeeId}</td>
        <td>{user.gender}</td>
      </tr>
    ));
  };

  const handleActionClose = () => {
    setActionId(null);
    setActionModalShow(false);
    setShowAddTraineeModal(false);
  };

  const handleQRActionClose = () => {
    setActionId(null);
    setShowQRModal(false);
  };

  const getUserData = async (id) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTraineeUserData`,
      {},
      { id },
      "GET"
    );

    if (isSuccess) {
      setUsers(data?.data);
    }
  };

  const getTrainingData = async (fId, status = 1) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getTrainingData`,
      {},
      { financialYearId: fId || financialYearId, status },
      "GET"
    );

    if (isSuccess) {
      const tmpData =
        data?.data && data?.data.length ? data?.data.reverse() : [];
      const formattedData = tmpData.map((item) => ({
        item,
        id: item.id,
        fromDate: `${new Date(item.fromDate).toLocaleDateString()}`,
        toDate: `${new Date(item.toDate).toLocaleDateString()}`,
        fromTime: `${item.fromTime} `,
        toTime: `${item.toTime}`,
        trainer: item.trainers,
        title: item.trainingTitle,
        trainingLink: item.trainingLink,
        mode: item.modeOfTraining,
        mappingUser: item.userId,
        targetAudience: JSON.parse(item.targetAudience),
      }));

      setTrainings(formattedData);
      setfilterTrainings(formattedData);
    }
  };

  const handleActionAPI = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}deleteTrainingData`,
      {},
      { trainingId: actionId, status: mode === "Delete" ? 0 : 2 },
      "POST"
    );

    if (isSuccess) {
      handleActionClose();
      getTrainingData();
    }
  };

  const handleSaveTrainee = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}addTraineeOrInviteTrainee`,
      {},
      {
        trainingId: actionId,
        traineeEmails: selectedEmails.map((item) => item.email),
      },
      "POST"
    );

    if (isSuccess) {
      handleActionClose();
    }
  };

  const handleDelete = (id) => {
    setActionId(id);
    setActionModalShow(true);
  };

  const handleCloseModal = () => {
    getTrainingData();
    setShowModal(false);
    setActionId(null);
    setEditData(null);
  };

  const handleEdit = (id, training) => {
    setShowModal(true);
    setActionId(id);
    setEditData(training.item);
  };

  const handleCopyLink = (link) => {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setShowToast(true);
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    } else {
      alert("No link available to copy.");
    }
  };
  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
    return `${formattedHours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${period}`;
  };
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length) {
        setFinancialYearId(data.data[data?.data?.length - 1].id);
        getTrainingData(data.data[data?.data?.length - 1].id);
      }
    }
  };
  const handleTabSelect = (key) => {
    getTrainingData(financialYearId, key === "new" ? 1 : 2);
  };

  useEffect(() => {
    getFinancialYear();
  }, []);
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search input changes
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    setfilterTrainings(
      trainings.filter(
        (item) =>
          item.trainer?.toLowerCase()?.includes(query) ||
          item.title?.toLowerCase()?.includes(query)
      )
    );
  };

  useEffect(() => {
    getTrainingData();
  }, [financialYearId]);

  const renderTable = () => {
    return (
      <div
        className="training-container"
        style={{
          padding: "24px",
          borderRadius: "12px",
          backgroundColor: "white",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.08)",
        }}
      >
        <div className="search-filters mb-4">
          <div className="row align-items-center">
            <input
              type="text"
              className="form-control"
              placeholder="Search training sessions..."
              value={searchQuery}
              onChange={handleSearch}
              style={{
                borderRadius: "8px",
                padding: "10px 16px",
                border: "1px solid #ddd",
                backgroundColor: "#bfd7e0",
                fontFamily: "Arial, sans-serif",
                boxShadow: "0 4px 6px rgba(0, 0, 0, .1)",
                transition: "transform .2s, box-shadow .2s",
              }}
            />
          </div>
        </div>

        {filterTrainings && filterTrainings.length ? (
          <div className="training-list">
            <div
              className="row mb-3"
              style={{
                padding: "12px 8px",
                borderRadius: "8px",
                fontWeight: "500",
                borderRadius: "10px",
                backgroundColor: "rgb(63, 136, 165)",
                fontFamily: "Arial, sans-serif",
                boxShadow: "0 4px 6px rgba(0, 0, 0, .1)",
                transition: "transform .2s, box-shadow .2s",
                color: "white", // Set text color to white
              }}
            >
              <div style={{ width: "10%" }}>From Date</div>
              <div style={{ width: "9%" }}>To Date</div>
              <div style={{ width: "10%" }}>From Time</div>
              <div style={{ width: "9%" }}>To Time</div>
              <div style={{ width: "13%" }}>Trainer</div>
              <div style={{ width: "19%" }}>Training Title</div>
              <div style={{ width: "9%" }}>Mode</div>
              <div style={{ width: "20%" }}>Actions</div>
            </div>
            <div
              style={{
                maxHeight: "49vh",
                overflowY: "auto",
                overflowX: "hidden",
                width: "100%",
                borderRadius: "8px",
              }}
            >
              {filterTrainings.map((item) => (
                <div
                  key={item.id}
                  className="row mb-3 align-items-center training-card"
                  style={{
                    backgroundColor: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    backgroundColor: "#bfd7e0",
                    fontFamily: "Arial, sans-serif",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, .1)",
                    transition: "transform .2s, box-shadow .2s",
                  }}
                >
                  <div style={{ width: "10%" }}>{item.fromDate}</div>
                  <div style={{ width: "9%" }}>{item.toDate}</div>
                  <div style={{ width: "10%" }}>
                    {formatTime(item.fromTime)}
                  </div>
                  <div style={{ width: "9%" }}>{formatTime(item.toTime)}</div>
                  <div style={{ width: "13%" }}>{item.trainer}</div>
                  <div style={{ width: "19%" }}>{item.title}</div>
                  <div style={{ width: "9%" }}>
                    <span
                      style={{
                        backgroundColor:
                          item.mode === "ONLINE" ? "#e3f2fd" : "#e8f5e9",
                        color: item.mode === "ONLINE" ? "#0d47a1" : "#1b5e20",
                        padding: "4px 12px",
                        borderRadius: "16px",
                        fontSize: "0.85rem",
                      }}
                    >
                      {item.mode === "ONLINE" ? "Online" : "Offline"}
                    </span>
                  </div>

                  <div style={{ width: "20%" }}>
                    <div className="d-flex gap-2 action-buttons">
                      <button
                        className="btn-action"
                        onClick={() => {
                          setMode("Contract Employee Register");
                          setShowQRModal(true);
                          setActionId(item?.item?.registerExternalQrLink);
                        }}
                        style={{
                          backgroundColor: "#e3f2fd",
                          color: "#1565c0",
                          border: "none",
                          padding: "6px",
                          borderRadius: "6px",
                        }}
                        title="Contract Employee Register Link"
                      >
                        <i className="fas fa-link"></i>
                      </button>

                      <button
                        className="btn-action"
                        onClick={() => {
                          setMode("Internal Employee Register");
                          setShowQRModal(true);
                          setActionId(item?.item?.registerInternalQrLink);
                        }}
                        style={{
                          backgroundColor: "#e8f5e9",
                          color: "#2e7d32",
                          border: "none",
                          padding: "6px",
                          borderRadius: "6px",
                        }}
                        title="Internal Employee Register Link"
                      >
                        <i className="fas fa-link"></i>
                      </button>

                      <button
                        className="btn-action"
                        onClick={() => {
                          setMode("Contract Employee Attendance");
                          setShowQRModal(true);
                          setActionId(item?.item?.attendenceExternalQrLink);
                        }}
                        style={{
                          backgroundColor: "#fff3e0",
                          color: "#e65100",
                          border: "none",
                          padding: "6px",
                          borderRadius: "6px",
                        }}
                        title="Contract Employee Attendance QR"
                      >
                        <i className="fas fa-qrcode"></i>
                      </button>

                      <button
                        className="btn-action"
                        onClick={() => {
                          setMode("Internal Employee Attendance");
                          setShowQRModal(true);
                          setActionId(item?.item?.attendenceInternalQrLink);
                        }}
                        style={{
                          backgroundColor: "#f3e5f5",
                          color: "#6a1b9a",
                          border: "none",
                          padding: "6px",
                          borderRadius: "6px",
                        }}
                        title="Internal Employee Attendance QR"
                      >
                        <i className="fas fa-qrcode"></i>
                      </button>

                      <button
                        className="btn-action"
                        onClick={() => handleCopyLink(item?.item?.trainingLink)}
                        style={{
                          backgroundColor: "#e8eaf6",
                          color: "#3949ab",
                          border: "none",
                          padding: "6px",
                          borderRadius: "6px",
                        }}
                        title="Copy Training Link"
                      >
                        <i className="fa fa-copy"></i>
                      </button>

                      <button
                        className="btn-action"
                        onClick={() => {
                          setShowAddTraineeModal(true);
                          setActionId(item?.id);
                        }}
                        style={{
                          backgroundColor: "#e0f7fa",
                          color: "#0097a7",
                          border: "none",
                          padding: "6px",
                          borderRadius: "6px",
                        }}
                        title="Add Trainee"
                      >
                        <i className="fa fa-user-plus"></i>
                      </button>

                      <button
                        className="btn-action"
                        onClick={() => handleEdit(item.id, item)}
                        style={{
                          backgroundColor: "#f1f8e9",
                          color: "#558b2f",
                          border: "none",
                          padding: "6px",
                          borderRadius: "6px",
                        }}
                        title="Edit Training"
                      >
                        <i className="fa fa-edit"></i>
                      </button>

                      {item.mappingUser.length ? (
                        <button
                          className="btn-action"
                          onClick={() => {
                            handleDelete(item.id);
                            setMode("Cancel");
                          }}
                          style={{
                            backgroundColor: "#ffebee",
                            color: "#c62828",
                            border: "none",
                            padding: "6px",
                            borderRadius: "6px",
                          }}
                          title="Cancel Training"
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                      ) : (
                        <button
                          className="btn-action"
                          onClick={() => {
                            handleDelete(item.id);
                            setMode("Delete");
                          }}
                          style={{
                            backgroundColor: "#ffebee",
                            color: "#c62828",
                            border: "none",
                            padding: "6px",
                            borderRadius: "6px",
                          }}
                          title="Delete Training"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      )}

                      <button
                        className="btn-action"
                        onClick={() => {
                          setShowUserData(true);
                          getUserData(item.id);
                        }}
                        style={{
                          backgroundColor: "#ede7f6",
                          color: "#4527a0",
                          border: "none",
                          padding: "6px",
                          borderRadius: "6px",
                        }}
                        title="View Participants"
                      >
                        <i className="fas fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center py-5">
            <img
              src={NoDataFound}
              alt="No data found"
              style={{ maxWidth: "250px", opacity: "0.7" }}
              className="mb-3"
            />
          </div>
        )}

        <Modal
          show={showUserData}
          onHide={() => setShowUserData(false)}
          size="lg"
          centered
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Modal.Header
            closeButton
            style={{ borderBottom: "1px solid #eaedf0", padding: "16px 24px" }}
          >
            <Modal.Title style={{ fontSize: "1.25rem", fontWeight: "600" }}>
              Participants
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "0" }}>
            <Tabs
              id="user-data-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-0"
              style={{ borderBottom: "1px solid #eaedf0" }}
            >
              <Tab
                eventKey="register"
                title={
                  <span style={{ padding: "12px 16px", display: "block" }}>
                    Registered{" "}
                    <span className="badge bg-primary ms-2">
                      {users.registerUser?.length || 0}
                    </span>
                  </span>
                }
              >
                <div
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    padding: "16px",
                  }}
                >
                  <Table hover size="md" className="mb-0">
                    <thead>
                      <tr style={{ backgroundColor: "#f6f9fc" }}>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Name
                        </th>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Email
                        </th>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Mobile
                        </th>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Employee ID
                        </th>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Gender
                        </th>
                      </tr>
                    </thead>
                    {users.registerUser && users.registerUser.length ? (
                      <tbody>
                        {users.registerUser.map((user, index) => (
                          <tr key={index}>
                            <td
                              style={{ padding: "12px 16px" }}
                            >{`${user.firstName} ${user.lastName}`}</td>
                            <td style={{ padding: "12px 16px" }}>
                              {user.email}
                            </td>
                            <td style={{ padding: "12px 16px" }}>
                              {user.mobile}
                            </td>
                            <td style={{ padding: "12px 16px" }}>
                              {user.employeeId}
                            </td>
                            <td style={{ padding: "12px 16px" }}>
                              {user.gender}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="5" className="text-center py-4">
                            <p className="text-muted mb-0">
                              No registered participants found
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              </Tab>
              <Tab
                eventKey="attended"
                title={
                  <span style={{ padding: "12px 16px", display: "block" }}>
                    Attended{" "}
                    <span className="badge bg-success ms-2">
                      {users.attendenceUser?.length || 0}
                    </span>
                  </span>
                }
              >
                <div
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    padding: "16px",
                  }}
                >
                  <Table hover size="md" className="mb-0">
                    <thead>
                      <tr style={{ backgroundColor: "#f6f9fc" }}>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Name
                        </th>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Email
                        </th>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Mobile
                        </th>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Employee ID
                        </th>
                        <th style={{ padding: "12px 16px", fontWeight: "600" }}>
                          Gender
                        </th>
                      </tr>
                    </thead>
                    {users.attendenceUser && users.attendenceUser.length ? (
                      <tbody>
                        {users.attendenceUser.map((user, index) => (
                          <tr key={index}>
                            <td
                              style={{ padding: "12px 16px" }}
                            >{`${user.firstName} ${user.lastName}`}</td>
                            <td style={{ padding: "12px 16px" }}>
                              {user.email}
                            </td>
                            <td style={{ padding: "12px 16px" }}>
                              {user.mobile}
                            </td>
                            <td style={{ padding: "12px 16px" }}>
                              {user.employeeId}
                            </td>
                            <td style={{ padding: "12px 16px" }}>
                              {user.gender}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="5" className="text-center py-4">
                            <p className="text-muted mb-0">
                              No attendance records found
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "1px solid #eaedf0", padding: "16px 24px" }}
          >
            <button
              className="btn btn-outline-secondary"
              onClick={() => setShowUserData(false)}
              style={{ borderRadius: "8px", padding: "8px 16px" }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Add Trainee Modal */}
        <Modal
          show={showAddTraineeModal}
          onHide={handleActionClose}
          centered
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Modal.Header
            closeButton
            style={{ borderBottom: "1px solid #eaedf0", padding: "16px 24px" }}
          >
            <Modal.Title style={{ fontSize: "1.25rem", fontWeight: "600" }}>
              Add Guest
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "24px" }}>
            <AddTrainee
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
            />
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "1px solid #eaedf0", padding: "16px 24px" }}
          >
            <Button
              variant="primary"
              style={{ width: "100%", borderRadius: "8px", padding: "10px" }}
              onClick={handleSaveTrainee}
            >
              Send Invite
            </Button>
          </Modal.Footer>
        </Modal>

        {/* QR Code Modal */}
        <Modal
          show={showQRModal}
          onHide={handleQRActionClose}
          centered
          size="sm"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Modal.Header
            closeButton
            style={{ borderBottom: "1px solid #eaedf0", padding: "16px 24px" }}
          >
            <Modal.Title style={{ fontSize: "1.25rem", fontWeight: "600" }}>
              {mode} QR Code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center" style={{ padding: "24px" }}>
            {actionId ? (
              <div>
                <img
                  src={actionId}
                  alt="QR Code"
                  style={{ maxWidth: "100%", borderRadius: "8px" }}
                />
                <div className="mt-3">
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => handleCopyLink(actionId)}
                    style={{ borderRadius: "6px" }}
                  >
                    <i className="fas fa-copy me-2"></i> Copy Link
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-muted">No QR Code available</p>
            )}
          </Modal.Body>
        </Modal>

        {/* Confirmation Modal */}
        <Modal
          show={actionModalShow}
          onHide={handleActionClose}
          centered
          size="sm"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Modal.Header
            closeButton
            style={{ borderBottom: "1px solid #eaedf0", padding: "16px 24px" }}
          >
            <Modal.Title style={{ fontSize: "1.25rem", fontWeight: "600" }}>
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "24px" }}>
            <div className="text-center mb-3">
              <i
                className={`fas ${
                  mode === "Delete" || mode === "Cancel"
                    ? "fa-exclamation-triangle"
                    : "fa-question-circle"
                }`}
                style={{
                  fontSize: "3rem",
                  color:
                    mode === "Delete" || mode === "Cancel"
                      ? "#f44336"
                      : "#2196f3",
                  marginBottom: "16px",
                }}
              ></i>
              <p style={{ fontSize: "1.1rem" }}>
                Are you sure you want to {mode?.toLowerCase()} this training?
              </p>
              <p className="text-muted" style={{ fontSize: "0.9rem" }}>
                {mode === "Delete"
                  ? "This action cannot be undone."
                  : mode === "Cancel"
                  ? "This will cancel the training for all participants."
                  : "Please confirm to proceed."}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              borderTop: "1px solid #eaedf0",
              padding: "16px 24px",
              display: "flex",
              gap: "8px",
            }}
          >
            <Button
              variant="outline-secondary"
              style={{ flex: "1", borderRadius: "8px" }}
              onClick={handleActionClose}
            >
              Cancel
            </Button>
            <Button
              variant={
                mode === "Delete" || mode === "Cancel" ? "danger" : "primary"
              }
              style={{ flex: "1", borderRadius: "8px" }}
              onClick={handleActionAPI}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Toast Notification */}
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{
            position: "fixed",
            bottom: "24px",
            right: "24px",
            zIndex: 1050,
            minWidth: "250px",
            backgroundColor: "#4caf50",
            color: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Toast.Body className="d-flex align-items-center">
            <i className="fas fa-check-circle me-2"></i>
            <span>Link copied to clipboard!</span>
          </Toast.Body>
        </Toast>
      </div>
    );
  };

  return (
    <>
      {/* Fixed Header */}
      <div style={headerContainerStyle}>
        <div style={headerTitleStyle}>Trainer Portal</div>
        <div>
          <select
            className="sector-question-select"
            value={
              financialYearId ||
              (financialYear.length > 0
                ? financialYear[financialYear.length - 1].id
                : "")
            }
            onChange={async (e) => {
              setFinancialYearId(e.target.value);
            }}
          >
            <option value={0}>Select Financial Year</option>
            {financialYear?.map((item, key) => (
              <option key={key} value={item.id}>
                {item.financial_year_value}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            style={{ ...createButtonStyle, marginRight: "10px" }}
            onClick={() => setShowModal(true)}
          >
            Create new training
          </button>
          <button
            className=""
            style={createButtonStyle}
            onClick={() =>
              handleCopyLink(`${window.location.origin}/#/trainee_registration`)
            }
          >
            Registration Trainee Link
          </button>
        </div>
      </div>

      {/* Container with Scrollable Table */}
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f4f7fa", // Light background color
          margin: "0 auto", // Center the layout horizontally
        }}
      >
        <Tabs
          defaultActiveKey="new"
          id="training-tabs"
          className="mb-3"
          onSelect={handleTabSelect} // Update active tab on selection
        >
          <Tab eventKey="new" title="New Training">
            <div>{renderTable()}</div>
          </Tab>
          <Tab eventKey="canceled" title="Canceled Training">
            <div>{renderTable()}</div>
          </Tab>
        </Tabs>
      </div>

      <TrainingModal
        show={showModal}
        handleClose={() => handleCloseModal()}
        actionId={actionId}
        editData={editData}
        financialYearId={financialYearId}
      />
    </>
  );
};

// Inline CSS styles
const headerContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  fontSize: "15px",
  marginBottom: "1em",
};
const headerTitleStyle = {
  fontSize: "28px",
  fontWeight: "bold",
};
const createButtonStyle = {
  backgroundColor: "#3F88A5",
  color: "white",
  borderRadius: "22px",
  border: "none",
  padding: "10px 20px",
  cursor: "pointer",
};

export default TrainingTable;
