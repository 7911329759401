import React from "react";
import SafetyMultiBar from "./FrameworkOne/SafetyMultiBar";
import SafetyLine from "./FrameworkFourtyEight/SafetyLine";


const SafetySingleLocMultTime = ({
  timePeriods,
  dataOne,
  dataThree,
  type,
  brief,
  companyFramework,
  locationOption,
  timePeriodValues,
}) => {

  return companyFramework.includes(1) ?
    (
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "80vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "49%" }}>
              <SafetyMultiBar
                type={type}
                timePeriods={timePeriods}
                matchedDataWater={dataOne}
                locationOption={locationOption}
                timePeriodValues={timePeriodValues}
                number={0}
              />
            </div>

            <div style={{ height: "49%" }}>
              <SafetyMultiBar
                type={type}

                timePeriods={timePeriods}
                matchedDataWater={dataOne}
                locationOption={locationOption}
                timePeriodValues={timePeriodValues}
                number={2}
              />
            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "49%" }}>
              <SafetyMultiBar
                timePeriods={timePeriods}
                matchedDataWater={dataOne}
                type={type}

                locationOption={locationOption}
                timePeriodValues={timePeriodValues}
                number={1}
              />
            </div>
            <div style={{ height: "49%" }}>
              <SafetyMultiBar
                timePeriods={timePeriods}
                number={3}
                locationOption={locationOption}
                type={type}

                timePeriodValues={timePeriodValues}
                matchedDataWater={dataOne}
              />
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "35vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <SafetyMultiBar
              timePeriods={timePeriods}
              locationOption={locationOption}
              timePeriodValues={timePeriodValues}
              number={4}

              matchedDataWater={dataThree}
            />

          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >

          </div>
        </div>
      </div>
    ) : (
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "60vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "100%" }}>
              <SafetyLine locationOption={locationOption}
                timePeriodValues={timePeriodValues} timePeriods={timePeriods} brief={brief} />
            </div>

            <div style={{ height: "0%" }}></div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "100%" }}></div>
            <div style={{ height: "0%" }}></div>
          </div>
        </div>
      </div>
    );
};

export default SafetySingleLocMultTime;
