import React, { useState } from "react";
import BarComponent from "./Framework48/BarComponent";
import EnergyConsumptionFourtyEight from "./Framework48/EnergyConsumptionFourtyEight";
import CommonBarComponent from "./FrameworkOne/CommonBarComponent";
import EnergyConsumptionChart from "./FrameworkOne/EnergyConsumptionChart";
import EnergyConsumptionCard from "./FrameworkOne/TotalEnergyConsumption";
import VerticalBarComponent from "./Framework48/VerticalBarComponent";
import VerticalEnergyBarComonent from "./FrameworkOne/VerticalEnergyBarComonent";

const AllLocAllTime = ({
  companyFramework,
  timePeriods,
  brief,
  totalConsumptionRenewable,
  totalConsumptionNonRenewable,
  renewableEnergy,
  nonRenewableEnergy,
  timePeriodValues,
  locationOption,
}) => {
  const [activeTab, setActiveTab] = useState("stacked");

  // Inline styles for tabs
  const tabsContainerStyle = {
    display: "flex",
    backgroundColor: "#f5f7fa",
    borderRadius: "12px",
    padding: "6px",
    marginBottom: "24px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
    border: "1px solid #e2e8f0",
  };

  const tabButtonStyle = {
    flex: 1,
    background: "transparent",
    border: "none",
    padding: "12px 20px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#64748b",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "all 0.2s ease",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    outline: "none",
  };

  const activeTabStyle = {
    backgroundColor: "white",
    color: "#4f46e5",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
  };

  const svgStyle = {
    width: "18px",
    height: "18px",
  };

  return (
    <div>
      {/* Enhanced Tabs with Inline Styles */}
      <div style={tabsContainerStyle}>
        <button
          style={{
            ...tabButtonStyle,
            ...(activeTab === "stacked" ? activeTabStyle : {}),
          }}
          onClick={() => setActiveTab("stacked")}
        >
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
            <line x1="3" y1="9" x2="21" y2="9"></line>
            <line x1="3" y1="15" x2="21" y2="15"></line>
            <line x1="9" y1="9" x2="9" y2="21"></line>
          </svg>
          Stacked View
        </button>
        <button
          style={{
            ...tabButtonStyle,
            ...(activeTab === "bar" ? activeTabStyle : {}),
            ":hover:not(.active)": {
              backgroundColor: "rgba(99, 102, 241, 0.05)",
              color: "#4f46e5",
            },
          }}
          onClick={() => setActiveTab("bar")}
        >
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="18" y1="20" x2="18" y2="10"></line>
            <line x1="12" y1="20" x2="12" y2="4"></line>
            <line x1="6" y1="20" x2="6" y2="14"></line>
            <line x1="3" y1="20" x2="21" y2="20"></line>
          </svg>
          Bar View
        </button>
      </div>

      {/* Stacked Bar View */}
      {activeTab === "stacked" &&
        (companyFramework.includes(1) ? (
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "55vh", marginBottom: "3%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "45%",
                }}
              >
                <div style={{ height: "21%" }}>
                  <EnergyConsumptionCard
                    timePeriodValues={timePeriodValues}
                    totalConsumptionRenewable={totalConsumptionRenewable}
                    totalConsumptionNonRenewable={totalConsumptionNonRenewable}
                    timePeriods={timePeriods}
                  />
                </div>

                <div style={{ height: "75%" }}>
                  <EnergyConsumptionChart
                    totalConsumptionRenewable={totalConsumptionRenewable}
                    totalConsumptionNonRenewable={totalConsumptionNonRenewable}
                  />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "55%",
                }}
              >
                <div style={{ height: "48%" }}>
                  <CommonBarComponent
                    renewableEnergy={renewableEnergy}
                    nonRenewableEnergy={nonRenewableEnergy}
                    com={"ren"}
                  />
                </div>
                <div style={{ height: "48%" }}>
                  <CommonBarComponent
                    renewableEnergy={renewableEnergy}
                    nonRenewableEnergy={nonRenewableEnergy}
                    com={"non"}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{ marginBottom: "2%" }}
              >
                <div
                  className="firsthalfprogressenergy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "65%" }} className="my-2">
                    <EnergyConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="ALL"
                    />
                  </div>

                  <div style={{ height: "65%" }} className="my-2">
                    <EnergyConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="FUEL"
                    />
                  </div>
                </div>
                <div
                  className="secondhalfprogress"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "65%" }} className="my-2">
                    <EnergyConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="ELE"
                    />
                  </div>
                  <div style={{ height: "65%" }} className="my-2">
                    <EnergyConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="REW"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row flex-space-between">
                <div
                  className="firsthalfprogressenergy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "100%" }} className="my-2">
                    <BarComponent
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="FUEL"
                    />
                  </div>

                  <div style={{ height: "100%" }} className="my-2">
                    <BarComponent
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="REW"
                    />
                  </div>
                </div>
                <div
                  className="secondhalfprogress"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "44%" }} className="my-2">
                    <BarComponent
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="ELE"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}

      {/* Bar View */}
      {activeTab === "bar" &&
        (companyFramework.includes(1) ? (
          <div className="d-flex flex-column flex-space-between">
           

            <div
              className="d-flex flex-row flex-space-between"
              style={{marginBottom: "3%" }}
            >
              <div
                className="firsthalfprogressenergy"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
               

                <div style={{ height: "55vh", marginBottom:"20px"}}>
                  <EnergyConsumptionChart
                    totalConsumptionRenewable={totalConsumptionRenewable}
                    totalConsumptionNonRenewable={totalConsumptionNonRenewable}
                  />
                </div>
                <div style={{ height: "55vh"}}>
                  <VerticalEnergyBarComonent
                    renewableEnergy={renewableEnergy}
                    nonRenewableEnergy={nonRenewableEnergy}
                    com={"non"}
                  />
                </div>
              </div>
              <div
                className="secondhalfprogress"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "50%",
                }}
              >
                <div style={{ height: "55vh"}}>
                  <VerticalEnergyBarComonent
                    renewableEnergy={renewableEnergy}
                    nonRenewableEnergy={nonRenewableEnergy}
                    com={"ren"}
                  />
                </div>
              
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{ marginBottom: "2%" }}
              >
                <div
                  className="firsthalfprogressenergy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "65%" }} className="my-2">
                    <EnergyConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="ALL"
                    />
                  </div>

                  <div style={{ height: "65%" }} className="my-2">
                    <EnergyConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="FUEL"
                    />
                  </div>
                </div>
                <div
                  className="secondhalfprogress"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "65%" }} className="my-2">
                    <EnergyConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="ELE"
                    />
                  </div>
                  <div style={{ height: "65%" }} className="my-2">
                    <EnergyConsumptionFourtyEight
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="REW"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row flex-space-between">
                <div
                  className="firsthalfprogressenergy"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "100%" }} className="my-2 container">
                    <VerticalBarComponent
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="FUEL"
                    />
                  </div>

                  {/* <div style={{ height: "100%" }} className="my-2 container">
                    <VerticalBarComponent
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="REW"
                    />
                  </div> */}
                </div>
                <div
                  className="secondhalfprogress"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ height: "100%" }} className="my-2 container">
                    <VerticalBarComponent
                      timePeriodValues={timePeriodValues}
                      brief={brief}
                      timePeriods={timePeriods}
                      type="ELE"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default AllLocAllTime;
