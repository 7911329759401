import React from "react";
import TotalEnergySingLocMultTime from "./TotalEnergySingLocMultTime";
import ProductWiseStacked from "./ProductWiseStacked";

const SingleLocMultTime = ({
  companyFramework,
  timePeriods,
  brief,
  locationOption,
  renewableEnergy,
  nonRenewableEnergy,
  timePeriodValues,
}) => {

  if (!companyFramework.includes(1)) {
    return null; // Return null if companyFramework doesn't include 1
  }

  return (
    <div className="d-flex flex-column flex-space-between">
      {/* First Row */}
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <TotalEnergySingLocMultTime
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
            />
          </div>
        </div>

        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <ProductWiseStacked
              title={"Product Wise Emission"}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              product={nonRenewableEnergy}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleLocMultTime;
