import React from "react";
import Chart from "react-apexcharts";
import no from "../../../img/no.png";

export const StackedSafetyNonPerm = ({ title, data }) => {
  if (!data || data.length === 0) {
    return (
      <div className="container">
        <img
          src={no} // Replace with the actual image path or URL
          alt="No Data Available"
          style={{ width: "150px", height: "125px", display: "block", margin: "0 auto" }}
        />
      </div>
    );
  }

  // Extract categories (X-axis) from question_details
  const categories = data[0].question_details
    .filter((detail) => detail.option_type === "column")
    .map((detail) => detail.option)
    .reverse();

  // Extract values for Male and Female Non-Permanent Workers and replace NaN with 0
  const maleNonPermanentWorkers = data[0].answer[2].map((val) => (isNaN(val) ? 0 : Number(val)));
  const femaleNonPermanentWorkers = data[0].answer[3].map((val) => (isNaN(val) ? 0 : Number(val)));

  // Chart options
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false, // Remove toolbar
      },
    },
    xaxis: {
      categories, // Categories for X-axis
    },
    plotOptions: {
      bar: {
        horizontal: false, // Vertical bars
        columnWidth: "20%", // Reduce the bar width
        dataLabels: {
          position: 'center', // Set the data label position to center
        },
      },
    },
    yaxis: {
      title: {
        text: "Number of Individuals", // Y-axis title
      },
    },
    legend: {
      position: "bottom", // Legend at the bottom
      horizontalAlign: "center", // Center the legend
      markers: {
        shape: "circle",
        height: 12,
        width: 12,
        radius: 12, // Legend markers as circles
      },
    },
    dataLabels: {
      enabled: true, // Enable data labels on the bars
      offsetY: 0, // Center data labels vertically
      style: {
        colors: ["#fff"], // Color of the labels (white for better contrast)
      },
      formatter: function (val) {
        return val === 0 ? '0' : val; // If the value is 0, display it as '0'
      },
    },
    colors: [     "#6fa8dc",
      "#ffa9d0"], // Custom colors for the bars (Male and Female)
    title: {
      text: "",
      align: "center",
    },
  };

  // Series data for Non-Permanent Male and Female Workers
  const series = [
    {
      name: "Male",
      data: maleNonPermanentWorkers,
    },
    {
      name: "Female",
      data: femaleNonPermanentWorkers,
    },
  ];

  return (
    <div className="container" style={{ width: "100%" }}>
      <div
        style={{
          height: "10%",
          fontSize: "20px",
          fontWeight: 600,
          color: "#011627",
        }}
      >
        {title}
      </div>

      <div style={{ height: "90%" }}>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={"100%"}
          width={"100%"}
        />
      </div>
    </div>
  );
};
