import React, { useState, useMemo, useEffect } from "react";
import Select, { components } from "react-select";
import noDataImage from "../../../img/no.png";

const VerticalEnergyBarComponent = ({ renewableEnergy, nonRenewableEnergy, com }) => {
  const colors = [
    "#83bbd5",
    "#11546f",
    "#65b1b6",
    "#4a849f",
    "#86caea",
    "#f4a300",
    "#D32F2F",
    "#7B1FA2",
    "#1976D2",
    "#0288D1",
    "#388E3C",
    "#FBC02D",
    "#8E24AA",
    "#0288D1",
    "#0288D1",
  ];

  // Define total bar color
  const totalBarColor = "#333333";

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [aggregatedData, setAggregatedData] = useState({});
  const [totalSum, setTotalSum] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const recoverySeries = useMemo(() => {
    if (nonRenewableEnergy || renewableEnergy) {
      if (com === "non") {
        return nonRenewableEnergy[0]?.question_details
          .filter((detail) => detail.option_type === "row")
          .slice(1)
          .map((detail) => detail.option)
          .reverse();
      } else {
        return renewableEnergy[0]?.question_details
          .filter((detail) => detail.option_type === "row")
          .slice(1)
          .map((detail) => detail.option)
          .reverse();
      }
    }
  }, [com, nonRenewableEnergy, renewableEnergy]);

  const productOptions = useMemo(() => {
    if (recoverySeries) {
      return recoverySeries.map((product, index) => ({
        value: product,
        label: product,
        color: colors[index % colors.length],
      }));
    }
  }, [recoverySeries]);

  // Initialize with first 5 products
  useEffect(() => {
    if (productOptions) {
      if (productOptions.length > 0) {
        const firstFiveProducts = productOptions.slice(0, 5);
        setSelectedProducts(firstFiveProducts);
      }
    }
  }, [productOptions]);

  // Calculate aggregated data when selection changes
  useEffect(() => {
    if (selectedProducts.length > 0) {
      const aggregated = selectedProducts.reduce((acc, product) => {
        acc[product.value] = 0; // Initialize each product value to 0
        return acc;
      }, {});

      const dataSource = com === "non" ? nonRenewableEnergy : renewableEnergy;

      dataSource.forEach((item) => {
        const answers =
          item.energyAndEmission && Array.isArray(item.energyAndEmission)
            ? item.energyAndEmission
            : [];

        answers.forEach((answerArray, index) => {
          if (Array.isArray(answerArray) && answerArray.length > 0) {
            const recoveryType = recoverySeries[index];
            const value = answerArray[0]; // First element corresponds to recoverySeries value

            // Apply conditions to check and parse value
            const numericValue =
              value === "NA" ||
              value === "No" ||
              value === "Yes" ||
              value === "" ||
              value === "KWH" ||
              !value
                ? 0
                : parseFloat(value);

            // Only aggregate if the recoveryType is in selectedProducts
            if (
              selectedProducts.some((product) => product.label === recoveryType)
            ) {
              aggregated[recoveryType] += numericValue;
            }
          }
        });
      });

      const sum = Object.values(aggregated).reduce(
        (total, value) => total + value,
        0
      );

      const adjustAndRoundTotalSum = (totalSum) => {
        // Define the thresholds or rounding steps
        const thresholds = [
          10, 25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000,
        ];

        // Handle values less than 1 (same logic as before)
        if (totalSum < 1) {
          if (totalSum < 0.01) {
            return Math.ceil(totalSum * 200) / 200; // Round to nearest 0.005
          } else if (totalSum < 0.1) {
            return Math.ceil(totalSum * 100) / 100; // Round to nearest 0.01
          } else {
            return Math.ceil(totalSum * 2) / 2; // Round to nearest 0.5
          }
        }

        // For values greater than or equal to 1, round based on the defined thresholds
        for (let i = thresholds.length - 1; i >= 0; i--) {
          if (totalSum > thresholds[i]) {
            return Math.ceil(totalSum / thresholds[i]) * thresholds[i]; // Round up to the next threshold
          }
        }

        // If no threshold is applicable, return the value as is (e.g., for values below 10)
        return totalSum;
      };

      const adjustedTotal = adjustAndRoundTotalSum(Number(sum));
      
      // Find the maximum value among individual products for proper scaling
      const maxProductValue = Math.max(...Object.values(aggregated), 0);
      // Use whichever is larger: the max individual value or the total sum
      const chartMaxValue = Math.max(adjustedTotal, maxProductValue);

      setAggregatedData(aggregated);
      setTotalSum(adjustedTotal);
      setMaxValue(chartMaxValue);
    }
  }, [selectedProducts, com, nonRenewableEnergy, renewableEnergy, recoverySeries]);

  const formatValue = (value) => {
    if (value >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`; // Format millions, e.g., 1.2M
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(1)}K`; // Format thousands, e.g., 1.2K
    } else {
      return Math.round(value); // Format normal numbers
    }
  };

  const handleProductChange = (selectedOptions) => {
    if (selectedOptions.length > 5) {
      alert("You can only select up to 5 products.");
      return;
    }

    if (selectedOptions.length < 1) {
      alert("You have to select at least 1 option");
      return;
    }

    setSelectedProducts(selectedOptions || []);
  };

  // Custom components for React Select
  const CustomClearIndicator = () => null;

  const CustomOption = (props) => {
    const { isSelected, data } = props;

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Square Box */}
          <div
            style={{
              width: "20px",
              height: "20px",
              border: "2px solid #3f88a5",
              borderRadius: "2px",
              backgroundColor: isSelected ? "transparent" : "transparent",
              marginRight: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Tick mark when selected */}
            {isSelected && (
              <span style={{ color: "white", fontSize: "14px" }}>✔</span>
            )}
          </div>
          {/* Option Label */}
          <span style={{ fontSize: "14px", fontWeight: 300 }}>
            {data.label}
          </span>
        </div>
      </components.Option>
    );
  };

  const CustomMultiValue = () => null;

  const CustomControl = (props) => {
    const { selectProps } = props;
    const { value, placeholder } = selectProps;

    return (
      <components.Control {...props}>
        {/* Placeholder or selected value */}
        {(!value || value.length === 0) && (
          <div
            style={{
              color: "#3f88a5",
              fontWeight: 600,
              fontSize: "13px",
              position: "absolute",
              left: "5px",
              pointerEvents: "none",
            }}
          >
            {/* {placeholder} */}
          </div>
        )}
        {/* Display only the first selected product */}
        {value && value.length > 0 && (
          <div style={{ color: "#3f88a5", marginLeft: "5px" }}>
            {value[0].label}
          </div>
        )}

        {/* Ensure you still render the child components like the dropdown indicator and input */}
        {props.children}
      </components.Control>
    );
  };

  if (renewableEnergy.length === 0 && nonRenewableEnergy.length === 0) {
    return (
      <div className="container">
        <img
          src={noDataImage}
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      </div>
    );
  }

  return (
    <div className="renewable-bar-container" style={{ height: "100%" }}>
      <div
        className="renewable-bar-header"
        style={{
          height: "10%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {com === "non" ? (
          <div style={{ width: "40%" }}>
            Non Renewable Energy Consumption Product Wise
          </div>
        ) : (
          <div style={{ width: "40%" }}>
            Renewable Energy Consumption Product Wise
          </div>
        )}
        <div style={{ width: "40%" }}>
          <Select
            options={productOptions}
            onChange={handleProductChange}
            isMulti
            value={productOptions.filter((option) =>
              selectedProducts.some(
                (selectedProduct) => selectedProduct.value === option.value
              )
            )}
            placeholder={`Select products`}
            hideSelectedOptions={false}
            className=""
            components={{
              Option: CustomOption,
              Control: CustomControl,
              MultiValue: CustomMultiValue,
              ClearIndicator: CustomClearIndicator,
            }}
            closeMenuOnSelect={false}
            styles={{
              control: (base) => ({
                ...base,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 100,
                border: "2px solid #3f88a5",
                borderRadius: "10px",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#3f88a5",
                padding: "0 10px",
                fontSize: "20px",
                minHeight: "20px",
                minWidth: "20px",
              }),
              placeholder: (base) => ({
                ...base,
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                pointerEvents: "none",
              }),
              multiValue: (base) => ({
                ...base,
                background: "transparent",
                border: "2px solid #3f88a5",
                borderRadius: "10px",
                marginTop: "0.5em",
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "transparent"
                  : state.isFocused
                  ? "white"
                  : "white",
                color: state.isSelected ? "black" : "black",
                cursor: "pointer",
              }),
            }}
          />
        </div>
      </div>

      {selectedProducts && selectedProducts.length > 0 && (
        <>
          {/* Chart Container */}
          <div 
            className="vertical-bar-chart-container" 
            style={{ 
              position: "relative",
              height: "300px",
              marginTop: "20px",
              marginBottom: "20px"
            }}
          >
            {/* Y-axis and bars container - this defines the actual chart area */}
            <div style={{
              display: "flex",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              paddingBottom: "30px" // Space for x-axis labels
            }}>
              {/* Y-axis */}
              <div style={{
                width: "60px",
                height: "100%",
                position: "relative",
                borderRight: "1px solid #ccc"
              }}>
                {/* Y-axis tick marks and values */}
                {[0, 0.2, 0.4, 0.6, 0.8, 1].reverse().map((ratio, i) => (
                  <div key={i} style={{
                    position: "absolute",
                    right: 0,
                    top: `${ratio * 100}%`,
                    transform: "translateY(-50%)",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    paddingRight: "5px"
                  }}>
                    <div style={{
                      width: "100%",
                      textAlign: "right",
                      fontSize: "12px",
                      fontWeight: "bold",
                      paddingRight: "8px"
                    }}>
                      {formatValue(maxValue * (1 - ratio))}
                    </div>
                    <div style={{
                      position: "absolute",
                      right: "-4px",
                      width: "8px",
                      height: "1px",
                      backgroundColor: "#666"
                    }}></div>
                  </div>
                ))}
              </div>

              {/* Chart area */}
              <div style={{
                flex: 1,
                height: "100%",
                position: "relative",
                borderBottom: "1px solid #ccc"
              }}>
                {/* Grid lines */}
                {[0, 0.2, 0.4, 0.6, 0.8, 1].map((ratio, i) => (
                  <div key={i} style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: `${ratio * 100}%`,
                    borderTop: i === 0 ? "none" : "1px dashed #ddd",
                    height: "1px"
                  }}></div>
                ))}

                {/* Bars container */}
                <div style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-end",
                  justifyContent: "space-around",
                  padding: "0 10px"
                }}>
                  {/* Product bars */}
                  {selectedProducts
                    .map((product) => ({
                      id: product.value,
                      label: product.value,
                      value: aggregatedData[product.value] || 0,
                      color: product.color,
                    }))
                    .map((item, index) => {
                      const heightPercent = maxValue > 0 ? (item.value / maxValue) * 100 : 0;
                      const displayLabel = item.label === "Energy Consumption through other sources" 
                        ? "Other Source" 
                        : item.label;
                      
                      return (
                        <div key={index} style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: `${80 / (selectedProducts.length + 1)}%`,
                          height: "100%",
                          position: "relative"
                        }}>
                          {/* Bar */}
                          <div style={{
                            position: "absolute",
                            bottom: 0,
                            width: "80%",
                            height: `${heightPercent}%`,
                            backgroundColor: item.color,
                            minHeight: item.value > 0 ? "2px" : "0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start"
                          }}>
                            {/* Value label inside/above bar */}
                            {item.value > 0 && (
                              <div style={{
                                position: "absolute",
                                top: heightPercent < 10 ? "-20px" : "5px",
                                color: heightPercent < 10 ? "#000" : "#fff",
                                fontSize: "11px",
                                fontWeight: "bold",
                                whiteSpace: "nowrap"
                              }}>
                                {formatValue(item.value)}
                              </div>
                            )}
                          </div>

                          {/* X-axis label */}
                          <div style={{
                            position: "absolute",
                            bottom: "-30px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "11px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}>
                            {displayLabel}
                          </div>
                        </div>
                      );
                    })}

                  {/* Total bar */}
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: `${80 / (selectedProducts.length + 1)}%`,
                    height: "100%",
                    position: "relative"
                  }}>
                    {/* Bar */}
                    <div style={{
                      position: "absolute",
                      bottom: 0,
                      width: "80%",
                      height: `${maxValue > 0 ? (totalSum / maxValue) * 100 : 0}%`,
                      backgroundColor: totalBarColor,
                      minHeight: totalSum > 0 ? "2px" : "0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start"
                    }}>
                      {/* Value label inside/above bar */}
                      {totalSum > 0 && (
                        <div style={{
                          position: "absolute",
                          top: (totalSum / maxValue) * 100 < 10 ? "-20px" : "5px",
                          color: (totalSum / maxValue) * 100 < 10 ? "#000" : "#fff",
                          fontSize: "11px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap"
                        }}>
                          {formatValue(totalSum)}
                        </div>
                      )}
                    </div>

                    {/* X-axis label */}
                    <div style={{
                      position: "absolute",
                      bottom: "-30px",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "11px",
                      fontWeight: "bold"
                    }}>
                      Total
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="unit"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "5%",
              marginTop: "10px"
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: 400,
                height: "100%",
                padding: "10px",
              }}
            >
              (in GJ)
            </div>
          </div>
          
          <div
            className="renewable-legends-container"
            style={{ width: "100%", marginTop: "20px", display: "flex", flexWrap: "wrap" }}
          >
            {/* Product legends */}
            {selectedProducts
              .map((product) => ({
                recoveryType: product.value,
                color: product.color,
              }))
              .map(({ recoveryType, color }, index) => {
                const displayRecoveryType =
                  recoveryType === "Energy Consumption through other sources"
                    ? "Other Source"
                    : recoveryType;

                return (
                  <div
                    className="renewable-legend"
                    key={index}
                    style={{ display: "flex", width: "25%", marginBottom: "10px" }}
                  >
                    <div
                      style={{
                        width: "20%",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        className="renewable-legend-color"
                        style={{
                          display: "inline-block",
                          width: "17px",
                          height: "17px",
                          borderRadius: "50%",
                          backgroundColor: color,
                          marginRight: "10px",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        width: "80%",
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ fontSize: "12px" }}>
                        {displayRecoveryType}
                      </div>
                    </div>
                  </div>
                );
              })}
              
            {/* Total legend */}
            <div
              className="renewable-legend"
              style={{ display: "flex", width: "25%", marginBottom: "10px" }}
            >
              <div
                style={{
                  width: "20%",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <div
                  className="renewable-legend-color"
                  style={{
                    display: "inline-block",
                    width: "17px",
                    height: "17px",
                    borderRadius: "50%",
                    backgroundColor: totalBarColor,
                    marginRight: "10px",
                  }}
                />
              </div>

              <div
                style={{
                  width: "80%",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Total
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VerticalEnergyBarComponent;