import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import DiversityMultiLocYear from "./DiversityMultiYear";
import DiversityMultiYearTwo from "./DiversityMultiYearTwo";
import CustomerMultiBarTurnOver from "./CustomerMultiBarTurnOver";

const MultipleYearMultipleTime = ({
  keyTab,
  locationOption,
  companyFramework,
  compareLastTimePeriods,
  compareTCurrentimePeriods,
  financialYear,
}) => {
  const [graphData, setGraphData] = useState(null);
  const isMounted = useRef(true);
  const [permanentEmployeDiversity, setPermanentEmployeDiversity] =
    useState(null);
  const [
    otherThanPermanentEmployeDiversity,
    setOtherThanPermanentEmployeDiversity,
  ] = useState(null);
  const [timePerids, setTimePerids] = useState(null);
  const [turnOverDataEmployee, setTurnOverDataEmployee] = useState(null);
  const [turnOverDataWorker, setTurnOverDataWorker] = useState(null);


  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getTurnOverRate = async () => {
    if (financialYear && financialYear.length >= 2) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getTurnOverRate`,
          {},
          {
            financialYearIds: [
              financialYear[financialYear.length - 2].id,
              financialYear[financialYear.length - 1].id,
            ],
          },
          "GET"
        );

        if (isSuccess && isMounted.current) {
          const datas = data.data;

          // Step 1: Group data by formDate
          const groupedData = datas.reduce((acc, entry) => {
            if (!acc[entry.formDate]) {
              acc[entry.formDate] = { 26: null, 46: null, 28: null, 48: null };
            }

            // Store answers separately for 26, 46, 28, and 48
            if (entry.questionId === 26)
              acc[entry.formDate]["26"] = entry.answer;
            if (entry.questionId === 46)
              acc[entry.formDate]["46"] = entry.answer;
            if (entry.questionId === 28)
              acc[entry.formDate]["28"] = entry.answer;
            if (entry.questionId === 48)
              acc[entry.formDate]["48"] = entry.answer;

            return acc;
          }, {});

          // Step 2: Compute percentage for (46 / 26) * 100 and (48 / 28) * 100
          const mergedData = Object.entries(groupedData)
            .map(([formDate, values]) => {
              const { 26: ans26, 46: ans46, 28: ans28, 48: ans48 } = values;

              // Function to calculate percentage correctly (46/26 and 48/28)
              const calculatePercentage = (numArray, denArray) => {
                if (!numArray || !denArray) return null;
                return denArray.map((denRow, rowIndex) =>
                  denRow.map((den, colIndex) => {
                    const num = numArray[rowIndex]?.[colIndex] || 1; // Avoid division by zero
                    return num !== 0
                      ? ((Number(den) / Number(num)) * 100).toFixed(2)
                      : "N/A";
                  })
                );
              };

              return [
                {
                  questionId: "26_46",
                  formDate,
                  answer26: ans26,
                  answer46: ans46,
                  percentage: calculatePercentage(ans26, ans46),
                },
                {
                  questionId: "28_48",
                  formDate,
                  answer28: ans28,
                  answer48: ans48,
                  percentage: calculatePercentage(ans28, ans48),
                },
              ];
            })
            .flat();
          const filteredDataEmployee = mergedData.filter(entry => entry.questionId !== "26_46");
          const filteredDataWorker = mergedData.filter(entry => entry.questionId !== "28_48");
          setTurnOverDataWorker(filteredDataEmployee);
          setTurnOverDataEmployee(filteredDataWorker);
        }
      } catch (error) {
        console.error("Error fetching total training data:", error);
      }
    }
  };

  const getCompareDiversityData = async () => {
    if (financialYear && financialYear.length >= 2) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getCompareDiversityData`,
          {},
          {
            financialYearIds: [
              financialYear[financialYear.length - 2].id,
              financialYear[financialYear.length - 1].id,
            ],
          },
          "GET"
        );

        if (isSuccess && isMounted.current) {
          const permanentEmployeDiversity = data.data.filter(
            (item) => item.questionId === 26
          );
          const otherThanPermanentEmployeDiversity = data.data.filter(
            (item) => item.questionId === 30
          );
          setPermanentEmployeDiversity(permanentEmployeDiversity);
          setOtherThanPermanentEmployeDiversity(
            otherThanPermanentEmployeDiversity
          );
          setGraphData(data.data);
        }
      } catch (error) {
        console.error("Error fetching total training data:", error);
      }
    }
  };

 
   useEffect(() => {
     // Helper function to extract the year and month from a date
     const getYearMonth = (date) => {
       const d = new Date(date);
       return `${d.getFullYear()}-${d.getMonth() + 1}`; // Format as "YYYY-MM"
     };
 
     // Get the latest date based on year and month from compareLastTimePeriods
     const latestLastPeriodDate = Object.values(compareLastTimePeriods)
       .map((date) => getYearMonth(date))
       .sort((a, b) => b.localeCompare(a))[0]; // Sort and get the latest year-month
 
     // Get the corresponding key for the latest year-month in compareLastTimePeriods
     const latestLastPeriodKey = Object.keys(compareLastTimePeriods).find(
       (key) =>
         getYearMonth(compareLastTimePeriods[key]) === latestLastPeriodDate
     );
 
     // Create an object with the key-value pair of the latest year-month
     const latestLastPeriodObj = {
       [latestLastPeriodKey]: latestLastPeriodDate,
     };
 
     // Extract the latest year-month from compareTCurrentimePeriods
     const latestCurrentPeriodDate = Object.values(compareTCurrentimePeriods)
       .map((date) => getYearMonth(date))
       .sort((a, b) => b.localeCompare(a))[0]; // Sort and get the latest year-month
 
     // Get the corresponding key for the latest year-month in compareTCurrentimePeriods
     const latestCurrentPeriodKey = Object.keys(compareTCurrentimePeriods).find(
       (key) =>
         getYearMonth(compareTCurrentimePeriods[key]) === latestCurrentPeriodDate
     );
 
     // Create an object with the key-value pair of the latest year-month
     const latestCurrentPeriodObj = {
       [latestLastPeriodKey]: latestLastPeriodDate,
       [latestCurrentPeriodKey]: latestCurrentPeriodDate,
     };
 
     setTimePerids(latestCurrentPeriodObj);
 
   
   
//    // Extract the values that should be included
//    const allowedFormDates = Object.values(compareLastTimePeriods);
   
//    const allowedFormDatesCurrent = Object.values(compareTCurrentimePeriods);
 
//    // Filter data where questionId is 46 or 48 and formDate matches exactly
//    const filteredData = graphData.filter(item => 
//      (item.questionId === 48) &&
//      allowedFormDates.includes(item.formDate)
//  );
 
//  const filteredDataCurrent = graphData.filter(item => 
//      (item.questionId === 48) &&
//      allowedFormDatesCurrent.includes(item.formDate)
//  );
 
//  // Combine the filtered arrays
//  const excludedItems = [...filteredData, ...filteredDataCurrent];
 
//  // Get the remaining objects
//  const remainingData = graphData.filter(item => 
//      !excludedItems.includes(item)
//  );
   
//    // Initialize a map to store combined answers by questionId
//    const combinedAnswers = {};
   
//    // Iterate through filteredData and merge answers index-wise
//    filteredData.forEach(item => {
//        if (!combinedAnswers[item.questionId]) {
//            combinedAnswers[item.questionId] = item.answer.map(row => row.map(Number));
//        } else {
//            item.answer.forEach((row, i) => {
//                row.forEach((val, j) => {
//                    combinedAnswers[item.questionId][i][j] += Number(val) || 0;
//                });
//            });
//        }
//    });
 
//    filteredDataCurrent.forEach(item => {
//      if (!combinedAnswers[item.questionId]) {
//          combinedAnswers[item.questionId] = item.answer.map(row => row.map(Number));
//      } else {
//          item.answer.forEach((row, i) => {
//              row.forEach((val, j) => {
//                  combinedAnswers[item.questionId][i][j] += Number(val) || 0;
//              });
//          });
//      }
//  });
   
//    // Add the combined answers back into the filtered data
//    const result1 = filteredData.map(item => ({
//        ...item,
//        answer: combinedAnswers[item.questionId]
//    }));
 
//    const result2 = filteredDataCurrent.map(item => ({
//      ...item,
//      answer: combinedAnswers[item.questionId]
//  }));
 
//  const mergedArray = [...result1, ...result2,...remainingData];
 
//        // Step 1: Group data by formDate
//        const groupedData = mergedArray.reduce((acc, entry) => {
//          if (!acc[entry.formDate]) {
//            acc[entry.formDate] = { 26: null, 46: null, 28: null, 48: null };
//          }
 
//          // Store answers separately for 26, 46, 28, and 48
//          if (entry.questionId === 26)
//            acc[entry.formDate]["26"] = entry.answer;
//          if (entry.questionId === 46)
//            acc[entry.formDate]["46"] = entry.answer;
//          if (entry.questionId === 28)
//            acc[entry.formDate]["28"] = entry.answer;
//          if (entry.questionId === 48)
//            acc[entry.formDate]["48"] = entry.answer;
 
//          return acc;
//        }, {});
 
//        // Step 2: Compute percentage for (46 / 26) * 100 and (48 / 28) * 100
//        const mergedData = Object.entries(groupedData)
//          .map(([formDate, values]) => {
//            const { 26: ans26, 46: ans46, 28: ans28, 48: ans48 } = values;
 
//            // Function to calculate percentage correctly (46/26 and 48/28)
//            const calculatePercentage = (numArray, denArray) => {
//              if (!numArray || !denArray) return null;
//              return denArray.map((denRow, rowIndex) =>
//                denRow.map((den, colIndex) => {
//                  const num = numArray[rowIndex]?.[colIndex] || 1; // Avoid division by zero
//                  return num !== 0
//                    ? ((Number(den) / Number(num)) * 100).toFixed(2)
//                    : "N/A";
//                })
//              );
//            };
 
//            return [
//              {
//                questionId: "26_46",
//                formDate,
//                answer26: ans26,
//                answer46: ans46,
//                percentage: calculatePercentage(ans26, ans46),
//              },
//              {
//                questionId: "28_48",
//                formDate,
//                answer28: ans28,
//                answer48: ans48,
//                percentage: calculatePercentage(ans28, ans48),
//              },
//            ];
//          })
//          .flat();
//        console.log(mergedData);
//        const filteredDataEmployee = mergedData.filter(
//          (entry) => entry.questionId !== "26_46"
//        );
//        const filteredDataWorker = mergedData.filter(
//          (entry) => entry.questionId !== "28_48"
//        );
//        setTurnOverDataWorker(filteredDataEmployee);
//        setTurnOverDataEmployee(filteredDataWorker);
 
//      console.log(compareLastTimePeriods, compareTCurrentimePeriods)
   }, [compareLastTimePeriods, compareTCurrentimePeriods]);

  useEffect(() => {
    getCompareDiversityData();
    getTurnOverRate();
  }, [financialYear]);

  const isCompareLastTimePeriodsValid =
    compareLastTimePeriods && Object.keys(compareLastTimePeriods).length > 0;
  const isCompareTCurrentimePeriodsValid =
    compareTCurrentimePeriods &&
    Object.keys(compareTCurrentimePeriods).length > 0;

  return companyFramework && companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
            {permanentEmployeDiversity && (
              <DiversityMultiLocYear
                horizontal={false}
                title={"Total Permanant Employees Including Differently Abled"}
                timePeriods={timePerids}
                matchedDataWaste={permanentEmployeDiversity}
                compareLastTimePeriods={compareLastTimePeriods}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                financialYear={financialYear}
              />
            )}
          </div>

          <div style={{ height: "49%" }}>
            {permanentEmployeDiversity && (
              <DiversityMultiYearTwo
                horizontal={false}
                title={
                  "Total Non-Permanent Employees Including Differently Abled"
                }
                timePeriods={timePerids}
                matchedDataWaste={permanentEmployeDiversity}
                compareLastTimePeriods={compareLastTimePeriods}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                financialYear={financialYear}
              />
            )}
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
            {otherThanPermanentEmployeDiversity && (
              <DiversityMultiLocYear
                horizontal={false}
                title={"Total Number Of Differently Abled Permanent Employees"}
                timePeriods={timePerids}
                matchedDataWaste={otherThanPermanentEmployeDiversity}
                compareLastTimePeriods={compareLastTimePeriods}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                financialYear={financialYear}
              />
            )}
          </div>
          <div style={{ height: "49%" }}>
            {otherThanPermanentEmployeDiversity && (
              <DiversityMultiYearTwo
                horizontal={false}
                title={
                  "Total Number Of Differently Abled Non-Permanent Employees"
                }
                timePeriods={timePerids}
                matchedDataWaste={otherThanPermanentEmployeDiversity}
                compareLastTimePeriods={compareLastTimePeriods}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                financialYear={financialYear}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
            {turnOverDataEmployee && (
              <CustomerMultiBarTurnOver
                horizontal={false}
                title={"Permanant Male Employees TurnOver"}
                timePeriods={timePerids}
                graphData={turnOverDataEmployee}
                compareLastTimePeriods={compareLastTimePeriods}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                financialYear={financialYear}
                columnIndex={0}
                rowIndex={0}
              />
            )}
          </div>

          <div style={{ height: "49%" }}>
          {turnOverDataEmployee && (
              <CustomerMultiBarTurnOver
                horizontal={false}
                title={"Other Than Permanant Male Employees TurnOver"}
                timePeriods={timePerids}
                graphData={turnOverDataEmployee}
                compareLastTimePeriods={compareLastTimePeriods}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                financialYear={financialYear}
                columnIndex={1}
                rowIndex={0}
              />
            )}
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
          {turnOverDataEmployee && (
              <CustomerMultiBarTurnOver
                horizontal={false}
                title={"Permanant Female Employees TurnOver"}
                timePeriods={timePerids}
                graphData={turnOverDataEmployee}
                compareLastTimePeriods={compareLastTimePeriods}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                financialYear={financialYear}
                columnIndex={0}
                rowIndex={1}
              />
            )}
          </div>
          <div style={{ height: "49%" }}>
          {turnOverDataEmployee && (
              <CustomerMultiBarTurnOver
                horizontal={false}
                title={"Other Than Permanant Female Employees TurnOver"}
                timePeriods={timePerids}
                graphData={turnOverDataEmployee}
                compareLastTimePeriods={compareLastTimePeriods}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                financialYear={financialYear}
                columnIndex={1}
                rowIndex={1}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MultipleYearMultipleTime;
