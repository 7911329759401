import React from "react";
import TotalEnergySingLocMultTime from "../Energy/FrameworkOne/TotalEnergySingLocMultTime";
import CombinedBarWaterDynamic from "./FrameworkOne/CombinedBarWater";
import RenewableAndNonRenewable from "../Energy/FrameworkOne/RenewableAndNonRenewable";
import TotalWasteDisposedMulti from "../Waste/FrameworkOne/TotalWasteDisposedMulti";
import FourtyEightTotalEnergy from "../Energy/Framework48/FourtyEightTotalEnergy";
import SingleLocMultTimeBar from "./FrameworkFourtyEight/SingleLocMultTime";
import ProductWiseFourtyEight from "../Energy/Framework48/ProductWiseFourtyEight";

const EnvironmentSingleLocMultipleTime = ({
  companyFramework,
  timePeriods,
  matchedDataWater,
  wasteDisposal,
  renewableEnergy,
  nonRenewableEnergy,
  matchedWaterDis,
  briefEnergy,
  briefWaste,
  briefWater,
  locationOption,
  timePeriodValues,
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "50vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <TotalEnergySingLocMultTime
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "50%" }}>
            <CombinedBarWaterDynamic
              title={"Total Water Withdrawn"}
              matchedDataWater={matchedDataWater}
              unit={"KL"}
              timePeriods={timePeriods}
            />
          </div>
          <div style={{ height: "48%" }}>
            <CombinedBarWaterDynamic
              title={"Total Water Disposed"}
              unit={"KL"}
              matchedDataWater={matchedWaterDis}
              timePeriods={timePeriods}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "35%" }}>
            <CombinedBarWaterDynamic
              title={"Total Waste Disposed"}
              unit={"MT"}
              matchedDataWater={wasteDisposal}
              timePeriods={timePeriods}
            />
          </div>
          <div style={{ height: "55%" }}>
            <TotalWasteDisposedMulti
              wasteDisposal={wasteDisposal}
              timePeriods={timePeriods}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <RenewableAndNonRenewable
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              timePeriods={timePeriods}
              renewableEnergy={renewableEnergy}
              nonRenewableEnergy={nonRenewableEnergy}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
          {briefEnergy &&  <FourtyEightTotalEnergy
              brief={briefEnergy}
              locationOption={locationOption}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
            />}
          </div>
        </div>
       <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
            <SingleLocMultTimeBar
              title={"Total Water Consumption"}
              unit={"KL"}
              brief={briefWater}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
          <div style={{ height: "49%" }}>
            <SingleLocMultTimeBar
              title={"Total Water Treated"}
              unit={"KL"}
              brief={briefWater}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <SingleLocMultTimeBar
              title={"Total Waste Generated"}
              brief={briefWaste}
              unit={"MT"}
              timePeriodValues={timePeriodValues}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>

          <div style={{ height: "48%" }}>
            <SingleLocMultTimeBar
              title={"Total Waste Disposed"}
              brief={briefWaste}
              timePeriodValues={timePeriodValues}
              unit={"MT"}
              timePeriods={timePeriods}
              locationOption={locationOption}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <ProductWiseFourtyEight
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
              brief={briefEnergy}
              timePeriods={timePeriods}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentSingleLocMultipleTime;
