import React, { useEffect, useRef, useState } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import RenewableAndNonRenewableMultiYear from "./RenewableAndNonRenewableMultiYear";
import RenewableNonRenewableMultiYear from "./RenewableNonRenewableMultiYear";
import SeparateEnergyMultTimeMultTime from "./SeparateEnergyMultTimeMultTime";
import SeparateEnergyMultTimeMultTimeYear from "./SeparateEnergyMultTimeMultTimeYear";
import TotalEnergyMultTimeMultTime from "./TotalEnergyMultTimeMultTime";

const MultipleYearMultipleTime = ({
  keyTab,
  locationOption,
  companyFramework,
  compareLastTimePeriods,
  compareTCurrentimePeriods,
  financialYear,
}) => {
  const [graphData, setGraphData] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getCompareEnergyData = async () => {
    if (financialYear && financialYear.length >= 2) {
      try {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getCompareEnergyData`,
          {},
          {
            financialYearIds: [
              financialYear[financialYear.length - 2].id,
              financialYear[financialYear.length - 1].id,
            ],
          },
          "GET"
        );

        if (isSuccess && isMounted.current) {
          setGraphData(data.data);
        }
      } catch (error) {
        console.error("Error fetching total training data:", error);
      }
    }
  };

  useEffect(() => {
    getCompareEnergyData();
  }, [financialYear]);

  const isCompareLastTimePeriodsValid =
    compareLastTimePeriods && Object.keys(compareLastTimePeriods).length > 0;
  const isCompareTCurrentimePeriodsValid =
    compareTCurrentimePeriods &&
    Object.keys(compareTCurrentimePeriods).length > 0;

  const shouldRenderGraphs =
    isCompareLastTimePeriodsValid && isCompareTCurrentimePeriodsValid;

  return companyFramework && companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      {/* First Row */}
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {shouldRenderGraphs && (
            <div style={{ height: "48%" }}>
              <TotalEnergyMultTimeMultTime
                compareLastTimePeriods={compareLastTimePeriods}
                locationOption={locationOption}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                graphData={graphData}
                financialYear={financialYear}
              />
            </div>
          )}

          <div style={{ height: "48%" }}>
            <SeparateEnergyMultTimeMultTime
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
            />
          </div>
        </div>

        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <RenewableAndNonRenewableMultiYear
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
            />
          </div>
          <div style={{ height: "48%" }}>
            <RenewableNonRenewableMultiYear
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
              title={"Renewable Energy Consumption"}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          {shouldRenderGraphs && (
            <div style={{ height: "48%" }}>
              <RenewableNonRenewableMultiYear
                compareLastTimePeriods={compareLastTimePeriods}
                locationOption={locationOption}
                compareTCurrentimePeriods={compareTCurrentimePeriods}
                graphData={graphData}
                financialYear={financialYear}
                title={"Non-Renewable Energy Consumption"}
              />
            </div>
          )}

          <div style={{ height: "48%" }}>
            <SeparateEnergyMultTimeMultTimeYear
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
              title={"Non-Renewable Energy Consumption"}
            />
          </div>
        </div>

        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "48%" }}>
            <SeparateEnergyMultTimeMultTimeYear
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
              title={"Renewable Energy Consumption"}
            />
          </div>
          {/* <div style={{ height: "48%" }}>
          <RenewableNonRenewableMultiYear
              compareLastTimePeriods={compareLastTimePeriods}
              locationOption={locationOption}
              compareTCurrentimePeriods={compareTCurrentimePeriods}
              graphData={graphData}
              financialYear={financialYear}
            />
          </div> */}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default MultipleYearMultipleTime;
