import React, { useState, useEffect, useRef } from "react";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";
import ParentExcel from "./parentexcel";

const Analytics = () => {
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [fetchingAnswers, setFetchingAnswers] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess && isMounted.current) {
      return (data?.data[data.data.length - 1]?.id);
    }
  };

  const fetchFrameworkApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
      {},
      { type: "ALL" }
    );

    if (isSuccess && isMounted.current) {
      const frameworkIds = data?.data.map((item) => item.id);
      return frameworkIds;
    }
    return [];
  };

  const fetchData = async () => {
    try {
      // Step 1: Fetch financial year and framework IDs
      const financialYearId = await getFinancialYear();
      const frameworkIds = await fetchFrameworkApi();
  
      // Step 2: Fetch questions
      const questionResponse = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
        {},
        { financialYearId, frameworkIds },
        "GET"
      );
  
      // Check if the response data is an array or an object
      let tmpData = questionResponse.data;
  
      // Ensure the data is an array before attempting to sort
      if (Array.isArray(tmpData)) {
        tmpData.sort((a, b) => {
          if (a.serialNumber == null) return 1; // Move null/undefined to last
          if (b.serialNumber == null) return -1;
          return a.serialNumber - b.serialNumber;
        });
  
        setQuestions(tmpData);
        const questionIds = tmpData.map((question) => question.questionId);
  
        if (questionIds.length === 0) {
          throw new Error("No questions found");
        }
  
        // Step 3: Fetch answers for each question
        setLoadingQuestions(false);
        setFetchingAnswers(true);
  
        for (const questionId of questionIds) {
          const answerResponse = await apiCall(
            `${config.POSTLOGIN_API_URL_COMPANY}getReportingAnswer`,
            {},
            { financialYearId, questionId },
            "GET"
          );
  
          if (answerResponse.isSuccess) {
            setAnswers((prevAnswers) => [
              ...prevAnswers,
              ...(answerResponse.data?.answers || []),
            ]);
          }
        }
  
        setFetchingAnswers(false);
      } else if (typeof tmpData === "object" && tmpData !== null) {
        // If data is an object, convert to an array
        const questionsArray = Object.values(tmpData.data || {});
        questionsArray.sort((a, b) => {
          if (a.serialNumber == null) return 1;
          if (b.serialNumber == null) return -1;
          return a.serialNumber - b.serialNumber;
        });
  
        setQuestions(questionsArray);
        const questionIds = questionsArray.map((question) => question.questionId);
  
        if (questionIds.length === 0) {
          throw new Error("No questions found");
        }
  
        setLoadingQuestions(false);
        setFetchingAnswers(true);
  
        for (const questionId of questionIds) {
          const answerResponse = await apiCall(
            `${config.POSTLOGIN_API_URL_COMPANY}getReportingAnswer`,
            {},
            { financialYearId, questionId },
            "GET"
          );
  
          if (answerResponse.isSuccess) {
            setAnswers((prevAnswers) => [
              ...prevAnswers,
              ...(answerResponse.data?.answers || []),
            ]);
          }
        }
  
        setFetchingAnswers(false);
      } else {
        console.error("Unexpected data format:", tmpData);
        throw new Error("Unexpected data format");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingQuestions(false);
      setFetchingAnswers(false);
    }
  };
  


  if (loadingQuestions) {
    return <div>Loading questions...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Entered values</h1>
      {fetchingAnswers && (
        <div className="flex items-center space-x-2 text-blue-500">
          <div
            style={{
              width: "3rem",
              height: "3rem",
              border: "2px solid #3b82f6",
              borderTop: "2px solid transparent",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            }}
          ></div>
        </div>
      )}
      <ParentExcel allAnswers={answers} allQuestions={questions} />
      <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
    </div>
  );
};

export default Analytics;
