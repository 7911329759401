import React from "react";
import img from "../../../img/no.png";

const DiversityBarComponentTwo = ({
  title,
  dataOne,
}) => {
  // Extract categories dynamically based on the specified condition
  if (!dataOne || dataOne.length === 0) {
    return (
      <div className="container">
        <img
          src={img} // Replace with the actual image path or URL
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      </div>
    );
  }

  const categories = dataOne.reduce((acc, item) => {
    if (item.question_details) {
      // Check for 'column1' first
      let filteredOptions = item.question_details
        .filter((detail) => detail.option_type === "column1")
        .map((detail) => detail.option);

      // If no 'column1' found, check for 'column'
      if (filteredOptions.length === 0) {
        filteredOptions = item.question_details
          .filter((detail) => detail.option_type === "column")
          .map((detail) => detail.option);
      }

      return acc.concat(filteredOptions);
    }

    return acc;
  }, []);

  // Remove duplicates
  const uniqueCategories = [...new Set(categories)].reverse();

  // Map the categories to their corresponding answer values using the first array in answer
  const categoryValues = uniqueCategories.map((category, categoryIndex) => {
    const totalValue = dataOne.reduce((sum, item) => {
      if (item.question_details && item.answer && item.answer[1]) {
        // Ensure the first array in answer exists
        const matchedDetail = item.question_details.find(
          (detail) => detail.option === category
        );
        const answerValue = item.answer[1][categoryIndex]; // Use the first array in answer
        if (matchedDetail && answerValue !== undefined) {
          return sum + Number(answerValue); // Sum values for each category
        }
      }
      return sum;
    }, 0);

    return {
      category,
      totalValue,
    };
  });

  const adjustAndRoundTotalSum = (totalSum) => {
    // Define the thresholds or rounding steps
    const thresholds = [
      10, 25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000,
    ];

    // Handle values less than 1 (same logic as before)
    if (totalSum < 1) {
      if (totalSum < 0.01) {
        return Math.ceil(totalSum * 200) / 200; // Round to nearest 0.005
      } else if (totalSum < 0.1) {
        return Math.ceil(totalSum * 100) / 100; // Round to nearest 0.01
      } else {
        return Math.ceil(totalSum * 2) / 2; // Round to nearest 0.5
      }
    }

    // For values greater than or equal to 1, round based on the defined thresholds
    for (let i = thresholds.length - 1; i >= 0; i--) {
      if (totalSum > thresholds[i]) {
        // Debugging step: log the threshold and the result of rounding up
        console.log(
          `Rounding ${totalSum} up to the next threshold: ${thresholds[i]}`
        );
        return Math.ceil(totalSum / thresholds[i]) * thresholds[i]; // Round up to the next threshold
      }
    }

    // If no threshold is applicable, return the value as is (e.g., for values below 10)
    return totalSum;
  };

  const tmpSum = categoryValues.reduce(
    (sum, item) =>
      sum + (isNaN(Number(item.totalValue)) ? 0 : Number(item.totalValue)),
    0
  );

  const totalSum = adjustAndRoundTotalSum(tmpSum);

  // Colors for each category
  const colors = [
    "#6fa8dc",
    "#ffa9d0",
    "#cccccc",
    "#6fa8dc",
    "#ffa9d0",
    "#cccccc",
    "#6fa8dc",
    "#ffa9d0",
    "#cccccc",
    "#6fa8dc",
    "#ffa9d0",
  ];

  return (
    <div className="container">
      <div className="renewable-bar-header">{title}</div>

      {/* Conditional check right after the header */}
      {categoryValues.length === 0 || totalSum === 0 ? (
        <img
          src={img} // Replace with the actual image path or URL
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      ) : (
        <>
          <div className="renewable-bar-labels">
            <span
              style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
            >
              0
            </span>
            <span
              style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
            >
              {(totalSum / 5 / 10) * 10}
            </span>
            <span
              style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
            >
              {(((totalSum / 5) * 2) / 10) * 10}
            </span>
            <span
              style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
            >
              {(((totalSum / 5) * 3) / 10) * 10}
            </span>
            <span
              style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
            >
              {(((totalSum / 5) * 4) / 10) * 10}
            </span>
            <span
              style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
            >
              {(totalSum / 10) * 10}
            </span>
          </div>
          <div className="renewable-bar-dotted-line"></div>

          <div>
            {/* The bar chart */}
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "30px",
                border: "1px solid #ccc",
              }}
            >
              {categoryValues.map((item, index) => {
                if (Number(item.totalValue) === 0) return null;
                const widthPercentage = (item.totalValue / totalSum) * 100;

                return (
                  <div
                    key={index}
                    style={{
                      width: `${widthPercentage}%`, // Use logarithmic width
                      backgroundColor: colors[index % colors.length],
                      position: "relative", // For absolute positioning the true value text
                    }}
                    title={`${item.category}: ${Number(item.totalValue)}`} // Tooltip with the true value
                  >
                    {/* Show the true value in the middle of the bar */}
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "12px",
                        color: "#fff",
                      }}
                    >
                      {isNaN(item.totalValue) ? "0" : item.totalValue.toFixed(1)} {/* Show 0 if NaN */}
                    </span>
                  </div>
                );
              })}
            </div>

            <div
              className="unit"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "3%",
                marginTop: "1%",
                marginBottom: "1%",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  height: "100%",
                  padding: "0%",
                }}
              >
                (Number of Individuals)
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                overflow: "auto",
              }}
            >
              {categoryValues.map((item, index) => {
                const shortNames = {
                  "Number of Males": "Males",
                  "Number of Females": "Females",
                  "Other non-hazardous wastes": "ONHW",
                };

                const displayName = shortNames[item.category] || item.category;

                return (
                  <div
                    key={index}
                    style={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "15px",
                    }}
                    title={item.category}
                  >
                    <div
                      style={{
                        width: "40%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          borderRadius: "50%",
                          backgroundColor: colors[index % colors.length],
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <div style={{ width: "70%" }}>
                      <div style={{ fontSize: "12px" }}>{displayName}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DiversityBarComponentTwo;
