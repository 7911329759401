import React from "react";
import DiversityMultiLoc from "./FrameworkOne/DiversityMultiLoc";
import DiversityMultiLocTwo from "./FrameworkOne/DiversityMultiLocTwo";
import DiversityMultiLocSingle from "./FrameworkFourtyEight/DiversityMultiLocSingle";
import DiversityMultiLocSingleForPie from "./FrameworkFourtyEight/DiversityMultiLocSingleForPie";
import DiversityBarComponent from "./FrameworkOne/DiversityBarComponent";
import DiversityBarComponentTwo from "./FrameworkOne/DiversityBarComponentTwo";
import DiversityBarComponentVertical from "./FrameworkOne/DiversityBarComponentVertical";

const AllLocAllTime = ({
  companyFramework,
  timePeriods,
  dataOne,
  titleOne,
  dataTwo,
  titleTwo,
  diversity,
  titleThree,
  titleFour,
  titleFive,
  brief,
  timePeriodValues,
  locationOption
}) => {

  return companyFramework.includes(1) ? (
   
    <div className="d-flex flex-column flex-space-between">
    <div
      className="d-flex flex-row flex-space-between"
      style={{ marginBottom: "3%" }}
    >
      <div
        className="firsthalfprogressenergy"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "45%",
        }}
      >
        <div style={{ height: "100%" }} className="my-3">
          <DiversityBarComponentVertical
            title={titleOne}
            dataOne={dataOne}
            timePeriodValues={timePeriodValues}
            timePeriods={timePeriods}
            locationOption={locationOption}
          />
        </div>

        <div style={{ height: "100%" }} className="my-3">
          <DiversityBarComponentVertical
            title={titleFive}
            dataOne={dataTwo}
            timePeriodValues={timePeriodValues}
            timePeriods={timePeriods}
            locationOption={locationOption}
          />
        </div>
      </div>
      <div
        className="secondhalfprogress"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "55%",
        }}
      >
        <div style={{ height: "100%" }} className="my-3">
          <DiversityBarComponentVertical
            title={titleFour}
            dataOne={dataOne}
            timePeriodValues={timePeriodValues}
            timePeriods={timePeriods}
            locationOption={locationOption}
          />
        </div>

        <div style={{ height: "100%" }}className="my-3" >
          <DiversityBarComponentVertical
            title={titleTwo}
            dataOne={dataTwo}
            timePeriodValues={timePeriodValues}
            timePeriods={timePeriods}
            locationOption={locationOption}
          />
        </div>
      </div>
    </div>
   
  </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }} >
            <DiversityMultiLocSingleForPie
              brief={brief}
              timePeriodValues={timePeriodValues}
              categories={[
                "Current employees by Gender (in %) Male",
                "Current employees by Gender (in %) Female",
                'New hires by Gender Male',
                'New hires by Gender Female'
              ]}
              shortenedCategories={["Males", "Females","New hires Males", "New hires Females"]}
              title="Gender Diversity"
            />
          </div>

          <div style={{ height: "0%" }}></div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLocSingleForPie
              brief={brief}
              timePeriodValues={timePeriodValues}
              categories={[
                "Employees less than 30 years of age",
                "Employees between 30-50 years of age",
                "Employees more than 50 years of age",
              ]}
              shortenedCategories={["<30", "30-50", ">50"]}
              title="Age Based Diversity"
            />
          </div>
          <div style={{ height: "0%" }}></div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        {/* <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLocSingle
              brief={brief}
              timePeriodValues={timePeriodValues}
              categories={[
                "New hires by Gender Male",
                "New hires by Gender Female",
              ]}
              shortenedCategories={["Males", "Females"]}
              title="New hires by Gender"
            />
          </div>

          <div style={{ height: "0%" }}></div>
        </div> */}
        {/* <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLocSingle
              brief={brief}
              timePeriodValues={timePeriodValues}
              categories={[
                "New hires by age groups (<30)",
                "New hires by age groups (30-50)",
                "New hires by age groups (>50)",
              ]}
              title="Age Wise Distribution of New Hire Employees"
              shortenedCategories={["<30", "30-50", ">50"]}
            />
          </div>
          <div style={{ height: "0%" }}></div>
        </div> */}
      </div>
    </div>
  );
};

export default AllLocAllTime;
