import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Alert,
  Dropdown,
  Spinner,
  Table,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import image from "../../../img/Close.svg";
import "./operationalmodal.css";
import down from "../../../img/DownArrow.svg";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { Nav, Tab, Tabs } from "react-bootstrap";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const AssignQuestionModal = ({
  handleAssignedDetails,
  financeObjct,
  questionIds,
  managementListValue,
  moduleName,
  moduleData,
  showAssignModal,
  handleAssignClose,
}) => {
  const today = formatDate(new Date());
  const [answer, setAnswer] = useState({
    financialYearId: financeObjct,
    assignedToIds: "",
    questionIds: questionIds,
    moduleType: "SQ",
    questionnaireType: "CA",
    dueDate: today,
  });
  const [assignedQuestions, setAssignedQuestions] = useState([]);
  useEffect(() => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      questionIds: questionIds || [],
    }));
  }, [questionIds]);
  const [lowNumber, setLowNumber] = useState("");
  const [highNumber, setHighNumber] = useState("");
  const [activeTab, setActiveTab] = useState("module");
  const [activeSubTab, setActiveSubTab] = useState("module");
  const [selectedUserTwo, setSelectedUserTwo] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRoleTwo, setSelectedRoleTwo] = useState("");
  const [error, setError] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [dueDate, setDueDate] = useState(new Date());
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [reAssignedToIds, setReAssignedToIds] = useState();
  const [range, setRange] = useState("");
  const fetchAssignedQuestions = async (userId) => {
    setLoadingUsers(true);
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getAssignedReportingQuestionDetails`,
        {},
        { userId: userId },
        "GET"
      );
      if (response.isSuccess) {
        const data = response.data?.data || [];
        setAssignedQuestions(data.data);
      } else {
        console.error("Failed to fetch users:", response.message);
        setUsers([]);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setAssignedQuestions([]);
    } finally {
      setLoadingUsers(false);
    }
  };
  const handleUserChangeTwo = (userId) => {
    setSelectedUserTwo(userId);

    if (userId) {
      setReAssignedToIds(userId);
      fetchAssignedQuestions(userId);
    }

    // setAnswer((prevAnswer) => ({
    //   ...prevAnswer,
    //   assignedToIds: checkboxChecked ? [currentUserId] : [userId],
    // }));
  };

  const handleSave = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}assignedQuestionToUser`,
      {},
      answer,
      "POST"
    );
    if (isSuccess) {
    }
    handleAssignedDetails();

    handleAssignClose();
  };

  const handleRangeChange = (e) => {
    const input = e.target.value;
    setRange(input);

    // Check if the input matches the "lower number - higher number" format
    const rangePattern = /^\d+\s*-\s*\d+$/;
    if (!rangePattern.test(input)) {
      setError("Please use the correct format: lower number - higher number.");
    } else {
      // Split the input into two parts and validate the range
      const [low, high] = input.split("-").map(Number);
      if (low >= high) {
        setError("The lower number must be less than the higher number.");
      } else {
        setError("");
        // You can update the state or perform other actions here if needed
        const newQuestionIds = moduleData
          .slice(low - 1, high)
          .map((item) => item.id);

        // Update the answer state with the valid question IDs
        setAnswer((prevAnswer) => ({
          ...prevAnswer,
          questionIds: newQuestionIds,
        }));
      }
    }
  };
  const data = "top";

  const handleSaveChanges = () => {
    // Validation: Ensure that the low number is less than the high number
    if (Number(lowNumber) >= Number(highNumber)) {
      setError("Low number must be less than high number.");
      return;
    }

    // Close the modal and reset form
    setError("");
    handleAssignClose();
    // Process the range here (e.g., send to server, update state, etc.)
  };

  const fetchUsers = async (roleId) => {
    if (roleId) {
      setLoadingUsers(true);
      try {
        const response = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getSubUserBasedOnRoleId`,
          {},
          { roleId: roleId },
          "GET"
        );
        if (response.isSuccess) {
          const data = response.data?.data || [];
          const users = data.map((user) => ({
            id: user.userId,
            fullName: `${user.firstName} ${user.lastName} (${user.designation})`,
          }));
          setUsers(users);
        } else {
          console.error("Failed to fetch users:", response.message);
          setUsers([]);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
      } finally {
        setLoadingUsers(false);
      }
    }
  };

  const handleSaveReassign = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}reassignedQuestionToUser`,
      {},
      answer,
      "POST"
    );
    if (isSuccess) {
    }
    handleAssignedDetails();
    handleAssignClose();
  };

  const handleDate = (date) => {
    setDueDate(date);
    setAnswer({ ...answer, dueDate: date });
  };
  const handleCheckbox = (isChecked) => {
    setCheckboxChecked(isChecked);
    // Update answer state based on checkbox state
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let currentUserId = currentUser ? currentUser.id : null;
    setAnswer({
      ...answer,
      assignedToIds: isChecked ? [currentUserId] : [selectedUser],
    });
  };

  const handleUserChange = (userId) => {
    setSelectedUser(userId);

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const currentUserId = currentUser ? currentUser.id : null;
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      assignedToIds: checkboxChecked ? [currentUserId] : [userId],
    }));
  };

  useEffect(() => {
    if (selectedRole) {
      fetchUsers(selectedRole);
    } else {
      setUsers([]);
    }
  }, [selectedRole]);

  useEffect(() => {
    setAnswer((prevAnswer) => {
      return {
        ...prevAnswer,
        financialYearId: financeObjct,
      };
    });
  }, [financeObjct]);

  const handleSaveClick = () => {
    if (activeTab === "module" || activeTab === "questions") {
      handleSave();
    } else if (activeTab === "reassign") {
      handleSaveReassign();
    }
  };

  return (
    <Modal
      show={showAssignModal}
      onHide={handleAssignClose}
      size="xl"
      style={{ height: "120%" }}
    >
      <Modal.Header
        style={{
          borderBottom: "none",
          padding: "15px 30px",
          marginBottom: "10px",
        }}
      >
        <Modal.Title
          style={{
            color: "#3F88A5",
            fontSize: "14px",
            fontFamily: "Open Sans",
            fontWeight: 700,
          }}
        >
          Assign
        </Modal.Title>
        <img
          src={image}
          alt="Close"
          onClick={handleAssignClose}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body
        style={{
          borderBottom: "none",
          marginTop: "-20px",
          padding: "10px 30px",
        }}
      >
        <Tabs
          activeKey={activeTab}
          onSelect={(selectedKey) => setActiveTab(selectedKey)}
          className="custom-tabs"
        >
          <Tab eventKey="module" title="Module">
            {error && <Alert variant="danger">{error}</Alert>}
            <Form style={{ marginTop: "1%" }}>
              <>
                <Form.Group controlId="formModuleName">

                  <Form.Control
                    type="text"
                    value={moduleName
                      .replace(/-/g, " ")
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                    readOnly
                    style={{
                      border: "1px solid #3F88A5",
                      borderColor: "#3F88A5",
                      background: "white"
                    }}
                  />
                </Form.Group>
                <Form.Group
                  controlId="formCheckbox"
                  style={{ marginTop: "10px" }}
                >
                  <Form.Check
                    className="greenCheckbox"
                    type="checkbox"
                    label="Self Assign"
                    checked={checkboxChecked}
                    onChange={(e) => handleCheckbox(e.target.checked)}
                  />
                </Form.Group>
                {checkboxChecked ? (
                  <Form.Group className="mt-3">
                    <Form.Label
                      style={{
                        width: "100%",
                        height: "100%",
                        color: "black",
                        fontSize: "18px",
                        fontFamily: "Open Sans",
                        fontWeight: "400",
                        wordWrap: "break-word",
                        marginBottom: "2px",

                      }}
                    >
                      Choose Due Date
                    </Form.Label>
                    <DatePicker
                      selected={dueDate}
                      onChange={(date) => setDueDate(date)}
                      minDate={new Date()}
                      className="form-control"
                    />
                  </Form.Group>
                ) : (
                  <>
                    <Form.Group className="mt-3">
                      <Form.Label
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "black",
                          fontSize: "18px",
                          fontFamily: "Open Sans",
                          fontWeight: "400",
                          wordWrap: "break-word",
                          marginBottom: "2px",
                        }}
                      >
                        Select Role
                      </Form.Label>
                      <div className="gradient-border-only">
                        <Form.Control
                          as="select"
                          className="gradient-border custom-select"
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                          style={{
                            border: "1px solid #3F88A5",
                            borderColor: "#3F88A5",
                            background: "white"
                          }}
                        >
                          <option value="">Select a Role</option>
                          {managementListValue?.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.role_name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>

                    <Form.Group
                      className="mt-3"
                      style={{ width: "100%", borderColor: "#3F88A5" }}
                    >
                      <Form.Label
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "black",
                          fontSize: "18px",
                          fontFamily: "Open Sans",
                          fontWeight: "400",
                          wordWrap: "break-word",
                          marginBottom: "2px",
                          borderColor: "#3F88A5",
                        }}
                      >
                        Select User
                      </Form.Label>
                      <div
                        className="gradient-border-only"
                        style={{ width: "100%" }}
                      >
                        <div style={{ position: "relative", width: "100%" }}>
                          <Form.Group controlId="formUserSelect">
                            <Form.Control
                              as="select"
                              value={selectedUser || ""}
                              onChange={(e) => handleUserChange(e.target.value)}
                              disabled={!selectedRole || loadingUsers}
                              className="gradient-border custom-select "
                              style={{
                                border: "1px solid #3F88A5",
                                background: "white",
                                borderColor: "#3F88A5",
                              }}
                            >
                              <option value="">Select a User</option>
                              {users.map((user) => (
                                <option key={user.id} value={user.id}>
                                  {user.fullName}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          {loadingUsers && (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          )}
                        </div>

                        {loadingUsers && (
                          <Spinner animation="border" size="sm" />
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Label
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "black",
                          fontSize: "18px",
                          fontFamily: "Open Sans",
                          fontWeight: "400",
                          wordWrap: "break-word",
                          marginBottom: "2px",
                        }}
                      >
                        Set Due Date
                      </Form.Label>
                      <div>
                        <DatePicker
                          selected={dueDate}
                          onChange={handleDate}
                          minDate={new Date()}
                          className="form-control"
                          style={{
                            border: "1px solid #3F88A5",
                            borderColor: "#3F88A5",
                            background: "white",
                          }}
                        />
                      </div>
                    </Form.Group>
                  </>
                )}
              </>
            </Form>
          </Tab>
          <Tab eventKey="questions" title="Questions">
            {error && <Alert variant="danger">{error}</Alert>}
            <Form style={{ marginTop: "1%" }}>
              {data === "top" && (
                <>
                  <Form.Group controlId="formRange">
                    <Form.Label
                      style={{
                        width: "100%",
                        height: "100%",
                        color: "black",
                        fontSize: "18px",
                        fontFamily: "Open Sans",
                        fontWeight: "400",
                        wordWrap: "break-word",
                        marginBottom: "2px",
                      }}
                    >
                      Enter Range:
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder="e.g., 10 - 50"
                        value={range}
                        style={{
                          border: "1px solid #3F88A5",
                          background: "white",
                          borderColor: "#3F88A5",
                        }}
                        onChange={handleRangeChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    controlId="formCheckbox"
                    style={{ marginTop: "10px" }}
                  >
                    <Form.Check
                      className="greenCheckbox"
                      type="checkbox"
                      label="Self Assign"
                      checked={checkboxChecked}
                      onChange={(e) => handleCheckbox(e.target.checked)}
                    />
                  </Form.Group>
                  {checkboxChecked ? (
                    <Form.Group className="mt-3">
                      <Form.Label
                        style={{
                          width: "100%",
                          height: "100%",
                          color: "black",
                          fontSize: "18px",
                          fontFamily: "Open Sans",
                          fontWeight: "400",
                          wordWrap: "break-word",
                          marginBottom: "2px",
                        }}
                      >
                        Choose Due Date
                      </Form.Label>
                      <DatePicker
                        selected={dueDate}
                        onChange={(date) => setDueDate(date)}
                        minDate={new Date()}
                        className="form-control"
                        style={{ background: "white" }}
                      />
                    </Form.Group>
                  ) : (
                    <>
                      <Form.Group className="mt-3">
                        <Form.Label
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "black",
                            fontSize: "18px",
                            fontFamily: "Open Sans",
                            fontWeight: "400",
                            wordWrap: "break-word",
                            marginBottom: "2px",
                          }}
                        >
                          Select Role
                        </Form.Label>
                        <div className="gradient-border-only">
                          <Form.Control
                            as="select"
                            className="gradient-border custom-select"
                            value={selectedRole}
                            style={{
                              border: "1px solid #3F88A5",
                              borderColor: "#3F88A5",
                              background: "white"
                            }}
                            onChange={(e) => setSelectedRole(e.target.value)}
                          >
                            <option value="">Select a Role</option>
                            {managementListValue?.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.role_name}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      </Form.Group>

                      <Form.Group className="mt-3" style={{ width: "100%" }}>
                        <Form.Label
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "black",
                            fontSize: "18px",
                            fontFamily: "Open Sans",
                            fontWeight: "400",
                            wordWrap: "break-word",
                            marginBottom: "2px",
                          }}
                        >
                          Select User
                        </Form.Label>
                        <div
                          className="gradient-border-only"
                          style={{ width: "100%" }}
                        >
                          <div style={{ position: "relative", width: "100%" }}>
                            <Form.Group controlId="formUserSelect">
                              <Form.Control
                                as="select"
                                style={{
                                  border: "1px solid #3F88A5",
                                  borderColor: "#3F88A5",
                                  background: "white"
                                }}
                                value={selectedUser || ""}
                                onChange={(e) =>
                                  handleUserChange(e.target.value)
                                }
                                disabled={!selectedRole || loadingUsers}
                                className="gradient-border custom-select"
                              >
                                <option value="">Select a User</option>
                                {users.map((user) => (
                                  <option key={user.id} value={user.id}>
                                    {user.fullName}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            {loadingUsers && (
                              <Spinner
                                animation="border"
                                size="sm"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group className="mt-3">
                        <Form.Label
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "black",
                            fontSize: "18px",
                            fontFamily: "Open Sans",
                            fontWeight: "400",
                            wordWrap: "break-word",
                            marginBottom: "2px",
                          }}
                        >
                          Set Due Date
                        </Form.Label>
                        <div className="">
                          <DatePicker
                            selected={dueDate}
                            onChange={handleDate}
                            minDate={new Date()}
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </>
                  )}
                </>
              )}
            </Form>
          </Tab>
          {/* <Tab eventKey="reassign" title="Reassign">
            <Tabs
              activeKey={activeSubTab}
              onSelect={(selectedKey) => setActiveSubTab(selectedKey)}
              className="custom-tabss"
              style={{ marginTop: "1%" }}
            >
              <Tab eventKey="module" title="Module">
                {error && <Alert variant="danger">{error}</Alert>}
                <Form style={{ marginTop: "1%" }}>
                  {data === "top" && (
                    <>
                      <Form.Group controlId="formModuleName">

                        <Form.Control
                          type="text"
                          value={moduleName
                            .replace(/-/g, " ")
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                          readOnly
                          style={{
                            border: "1px solid #3F88A5",
                            borderColor: "#3F88A5",
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="formCheckbox"
                        style={{ marginTop: "10px" }}
                      >
                        <Form.Check
                          className="greenCheckbox"
                          type="checkbox"
                          label="Self Assign"
                          checked={checkboxChecked}
                          onChange={(e) => handleCheckbox(e.target.checked)}
                        />
                      </Form.Group>
                      {checkboxChecked ? (
                        <Form.Group className="mt-3">
                          <Form.Label
                            style={{
                              width: "100%",
                              height: "100%",
                              color: "black",
                              fontSize: "18px",
                              fontFamily: "Open Sans",
                              fontWeight: "400",
                              wordWrap: "break-word",
                              marginBottom: "2px",
                            }}
                          >
                            Choose Due Date
                          </Form.Label>
                          <DatePicker
                            selected={dueDate}
                            onChange={(date) => setDueDate(date)}
                            minDate={new Date()}
                            className="form-control"
                          />
                        </Form.Group>
                      ) : (
                        <>
                          <Form.Group className="mt-3">
                            <Form.Label
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "black",
                                fontSize: "18px",
                                fontFamily: "Open Sans",
                                fontWeight: "400",
                                wordWrap: "break-word",
                                marginBottom: "2px",
                              }}
                            >
                              Select Role
                            </Form.Label>
                            <div className="gradient-border-only">
                              <Form.Control
                                as="select"
                                className="gradient-border custom-select"
                                value={selectedRole}
                                onChange={(e) =>
                                  setSelectedRole(e.target.value)
                                }
                                style={{
                                  border: "1px solid #3F88A5",
                                  borderColor: "#3F88A5",
                                }}
                              >
                                <option value="">Select a Role</option>
                                {managementListValue?.map((role) => (
                                  <option key={role.id} value={role.id}>
                                    {role.role_name}
                                  </option>
                                ))}
                              </Form.Control>
                            </div>
                          </Form.Group>

                          <Form.Group
                            className="mt-3"
                            style={{ width: "100%", borderColor: "#3F88A5" }}
                          >
                            <Form.Label
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "black",
                                fontSize: "18px",
                                fontFamily: "Open Sans",
                                fontWeight: "400",
                                wordWrap: "break-word",
                                marginBottom: "2px",
                                borderColor: "#3F88A5",
                              }}
                            >
                              Select User
                            </Form.Label>
                            <div
                              className="gradient-border-only"
                              style={{ width: "100%" }}
                            >
                              <div
                                style={{ position: "relative", width: "100%" }}
                              >
                                <Form.Group controlId="formUserSelect">
                                  <Form.Control
                                    as="select"
                                    value={selectedUser || ""}
                                    onChange={(e) =>
                                      handleUserChange(e.target.value)
                                    }
                                    disabled={!selectedRole || loadingUsers}
                                    className="gradient-border custom-select "
                                    style={{
                                      border: "1px solid #3F88A5",
                                      borderColor: "#3F88A5",
                                    }}
                                  >
                                    <option value="">Select a User</option>
                                    {users.map((user) => (
                                      <option key={user.id} value={user.id}>
                                        {user.fullName}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                                {loadingUsers && (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                    }}
                                  />
                                )}
                              </div>

                              {loadingUsers && (
                                <Spinner animation="border" size="sm" />
                              )}
                            </div>
                          </Form.Group>

                          <Form.Group className="mt-3">
                            <Form.Label
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "black",
                                fontSize: "18px",
                                fontFamily: "Open Sans",
                                fontWeight: "400",
                                wordWrap: "break-word",
                                marginBottom: "2px",
                              }}
                            >
                              Set Due Date
                            </Form.Label>
                            <div>
                              <DatePicker
                                selected={dueDate}
                                onChange={handleDate}
                                minDate={new Date()}
                                className="form-control"
                                style={{
                                  border: "1px solid #3F88A5",
                                  borderColor: "#3F88A5",
                                }}
                              />
                            </div>
                          </Form.Group>
                        </>
                      )}
                    </>
                  )}
                </Form>
              </Tab>
              <Tab eventKey="questions" title="Questions">
                <Row style={{ width: "100%" }}>
                  <Col md={6} style={{}}>
                    <Form style={{ marginTop: "1%" }}>
                      <Form.Group className="mt-3">
                        <Form.Label
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "black",
                            fontSize: "18px",
                            fontFamily: "Open Sans",
                            fontWeight: "400",
                            wordWrap: "break-word",
                            marginBottom: "2px",
                          }}
                        >
                          Select Role
                        </Form.Label>
                        <div className="gradient-border-only">
                          <Form.Control
                            as="select"
                            className="gradient-border custom-select"
                            value={selectedRoleTwo}
                            style={{
                              border: "1px solid #3F88A5",
                              borderColor: "#3F88A5",
                            }}
                            onChange={(e) => setSelectedRoleTwo(e.target.value)}
                          >
                            <option value="">Select a Role</option>
                            {managementListValue?.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.role_name}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      </Form.Group>
                      <Form.Group className="mt-3" style={{ width: "100%" }}>
                        <Form.Label
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "black",
                            fontSize: "18px",
                            fontFamily: "Open Sans",
                            fontWeight: "400",
                            wordWrap: "break-word",
                            marginBottom: "2px",
                          }}
                        >
                          Select User
                        </Form.Label>
                        <div
                          className="gradient-border-only"
                          style={{ width: "100%" }}
                        >
                          <div style={{ position: "relative", width: "100%" }}>
                            <Form.Group controlId="formUserSelect">
                              <Form.Control
                                as="select"
                                style={{
                                  border: "1px solid #3F88A5",
                                  borderColor: "#3F88A5",
                                }}
                                value={selectedUserTwo || ""}
                                onChange={(e) =>
                                  handleUserChangeTwo(e.target.value)
                                }
                                disabled={!selectedRoleTwo || loadingUsers}
                                className="gradient-border custom-select "
                              >
                                <option value="">Select a User</option>
                                {users.map((user) => (
                                  <option key={user.id} value={user.id}>
                                    {user.fullName}
                                  </option>
                                ))}
                              </Form.Control>
                            </Form.Group>
                            {loadingUsers && (
                              <Spinner
                                animation="border"
                                size="sm"
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group>
                        {assignedQuestions?.length > 0 && (
                          <>
                            <Form.Label
                              style={{
                                width: "100%",
                                height: "100%",
                                color: "black",
                                fontSize: "18px",
                                fontFamily: "Open Sans",
                                fontWeight: "400",
                                wordWrap: "break-word",
                                marginBottom: "2px",
                              }}
                            >
                              Assigned Questions
                            </Form.Label>
                            <div className="table-container">
                              <Table className="custom-table">
                                <thead>
                                  <tr>
                                    <th style={{ textAlign: "left" }}>#</th>
                                    <th style={{ textAlign: "left" }}>
                                      Questions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {assignedQuestions.map((question, index) => (
                                    <tr key={question.questionId}>
                                      <td>{index + 1}</td>
                                      <td>{question.title}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </>
                        )}
                      </Form.Group>
                    </Form>
                  </Col>

                  <Col md={6} style={{ padding: "10px", marginTop: "5px" }}>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form style={{ marginTop: "1%" }}>
                      <div>
                        {data === "top" && (
                          <>
                            {reAssignedToIds ? (
                              <>
                                <Form.Group controlId="formRange">
                                  <Form.Label
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      color: "black",
                                      fontSize: "18px",
                                      fontFamily: "Open Sans",
                                      fontWeight: "400",
                                      wordWrap: "break-word",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    Enter Range:
                                  </Form.Label>
                                  <Col sm="12">
                                    <Form.Control
                                      type="text"
                                      placeholder="e.g., 10 - 50"
                                      value={range}
                                      style={{
                                        border: "1px solid #3F88A5",
                                        borderColor: "#3F88A5",
                                      }}
                                      onChange={handleRangeChange}
                                    />
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  controlId="formCheckbox"
                                  style={{ marginTop: "10px" }}
                                >
                                  <Form.Check
                                    className="greenCheckbox"
                                    type="checkbox"
                                    label="Self Assign"
                                    checked={checkboxChecked}
                                    onChange={(e) =>
                                      handleCheckbox(e.target.checked)
                                    }
                                  />
                                </Form.Group>
                                {checkboxChecked ? (
                                  <Form.Group className="mt-3">
                                    <Form.Label
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        color: "black",
                                        fontSize: "18px",
                                        fontFamily: "Open Sans",
                                        fontWeight: "400",
                                        wordWrap: "break-word",
                                        marginBottom: "2px",
                                      }}
                                    >
                                      Choose Due Date
                                    </Form.Label>
                                    <DatePicker
                                      selected={dueDate}
                                      onChange={(date) => setDueDate(date)}
                                      minDate={new Date()}
                                      className="form-control"
                                    />
                                  </Form.Group>
                                ) : (
                                  <>
                                    <Form.Group className="mt-3">
                                      <Form.Label
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          color: "black",
                                          fontSize: "18px",
                                          fontFamily: "Open Sans",
                                          fontWeight: "400",
                                          wordWrap: "break-word",
                                          marginBottom: "2px",
                                        }}
                                      >
                                        Select Role
                                      </Form.Label>
                                      <div className="gradient-border-only">
                                        <Form.Control
                                          as="select"
                                          className="gradient-border custom-select"
                                          value={selectedRole}
                                          style={{
                                            border: "1px solid #3F88A5",
                                            borderColor: "#3F88A5",
                                          }}
                                          onChange={(e) =>
                                            setSelectedRole(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            Select a Role
                                          </option>
                                          {managementListValue?.map((role) => (
                                            <option
                                              key={role.id}
                                              value={role.id}
                                            >
                                              {role.role_name}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      </div>
                                    </Form.Group>
                                    <Form.Group
                                      className="mt-3"
                                      style={{ width: "100%" }}
                                    >
                                      <Form.Label
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          color: "black",
                                          fontSize: "18px",
                                          fontFamily: "Open Sans",
                                          fontWeight: "400",
                                          wordWrap: "break-word",
                                          marginBottom: "2px",
                                        }}
                                      >
                                        Select User
                                      </Form.Label>
                                      <div
                                        className="gradient-border-only"
                                        style={{ width: "100%" }}
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            width: "100%",
                                          }}
                                        >
                                          <Form.Group controlId="formUserSelect">
                                            <Form.Control
                                              as="select"
                                              style={{
                                                border: "1px solid #3F88A5",
                                                borderColor: "#3F88A5",
                                              }}
                                              value={selectedUser || ""}
                                              onChange={(e) =>
                                                handleUserChange(e.target.value)
                                              }
                                              disabled={
                                                !selectedRole || loadingUsers
                                              }
                                              className="gradient-border custom-select "
                                            >
                                              <option value="">
                                                Select a User
                                              </option>
                                              {users.map((user) => (
                                                <option
                                                  key={user.id}
                                                  value={user.id}
                                                >
                                                  {user.fullName}
                                                </option>
                                              ))}
                                            </Form.Control>
                                          </Form.Group>
                                          {loadingUsers && (
                                            <Spinner
                                              animation="border"
                                              size="sm"
                                              style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                              }}
                                            />
                                          )}
                                        </div>
                                        {loadingUsers && (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                        )}
                                      </div>
                                    </Form.Group>
                                    <Form.Group className="mt-3">
                                      <Form.Label
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          color: "black",
                                          fontSize: "18px",
                                          fontFamily: "Open Sans",
                                          fontWeight: "400",
                                          wordWrap: "break-word",
                                          marginBottom: "2px",
                                        }}
                                      >
                                        Set Due Date
                                      </Form.Label>
                                      <div className="">
                                        <DatePicker
                                          selected={dueDate}
                                          onChange={handleDate}
                                          minDate={new Date()}
                                          className="form-control"
                                        />
                                      </div>
                                    </Form.Group>
                                  </>
                                )}
                              </>
                            ) : (
                              <div className="alert-box">
                                <i className="fas fa-exclamation-circle alert-icon"></i>
                                <p className="alert-text">
                                  Please select a user first and then you can
                                  reassign questions.
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Tab> */}
        </Tabs>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "none",
          padding: "10px 30px",
        }}
      >
        <Button
          onClick={handleAssignClose}
          style={{
            borderColor: "#3F88A5",
            fontSize: "14px",
            fontFamily: "Open Sans",
            fontWeight: "700",
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 30px",
          }}
        >
          Clear
        </Button>
        <Button
          onClick={handleSaveClick}
          style={{
            borderColor: "white",
            fontSize: "14px",
            fontFamily: "Open Sans",
            fontWeight: "700",
            backgroundColor: "#3F88A5",
            color: "white",
            padding: "5px 30px",
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignQuestionModal;
