import React from "react";
import Chart from "react-apexcharts";
import no from "../../../img/no.png";

const DiversityMultipleBarComponent = ({ data }) => {
  console.log(data)
  if (!data || data.length === 0) {
    return (
      <div className="container">
        <img
          src={no} // Replace with the actual image path or URL
          alt="No Data Available"
          style={{
            width: "150px",
            height: "125px",
            display: "block",
            margin: "0 auto",
          }}
        />
      </div>
    );
  }
  
  // Extract the rows for x-axis categories (e.g., "Number of board members", "Number of Key Management Personnel")
  const categories = data[0].question_details
    .filter((detail) => detail.option_type === "row")
    .map((detail) => {
      if (detail.option === "Number of Key Management Personnel") {
        return "Key Management"; // Replace the specific category name
      } else if (detail.option === "Number of board members") {
        return "Board"; // Replace the specific category name
      }
      return detail.option; // Return other options unchanged
    });

  // Extract the columns (e.g., "Female", "Male") for the stack series
  const stackCategories = data[0].question_details
    .filter((detail) => detail.option_type === "column")
    .map((detail) => detail.option)
    .reverse();

  // Reverse the answer array structure to match the rows and columns
  const seriesData = stackCategories.map((column, colIndex) => {
    return {
      name: column, // Name of the stack (e.g., "Female", "Male")
      data: data[0].answer.map((answer) =>
        answer[colIndex] !== "" ? Number(answer[colIndex]) : 0
      ), // Data for each row
    };
  });

  // Chart options
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      height: 350,
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "35%", // Reduced bar height
      },
    },
    xaxis: {
      categories: categories, // X-axis will have the rows
      position: "top", // Move the x-axis to the top
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`,
      },
    },
    dataLabels: {
      enabled: true, // Enable data labels
      style: {
        fontSize: "12px", // Font size for the labels
        fontWeight: "bold", // Make the labels bold
        colors: ["#fff"], // Make label text white for contrast
      },
      dropShadow: {
        enabled: true, // Enable shadow for better readability
        top: 1,
        left: 1,
        blur: 3,
        opacity: 0.5,
      },
      formatter: function (val) {
        return val !== 0 ? `${val}` : ''; // Hide 0 values from being displayed
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      markers: {
        width: 12, // Custom legend marker width
        height: 12, // Custom legend marker height
        borderRadius: 12, // Keep circular markers
      },
      position: "bottom", // Adjust as necessary (top, right, bottom, left)
      horizontalAlign: "center", // Align legend items in the center
      itemMargin: {
        horizontal: 10, // Space between legend items
        vertical: 0, // Vertical space (if needed)
      },
      formatter: function (seriesName, opts) {
        return `<div style="display: flex; align-items: center;">
                 <span style="color: #7b91b0;">${seriesName}</span>
                </div>`;
      },
    },
    colors: ["#88D29E", "#b0b0b0", "#11546f"], // Custom colors for the bars
  };

  return (
    <div className="container">
      <h4>{data[0].title}</h4>
      <Chart options={options} series={seriesData} type="bar" height={"90%"} />
    </div>
  );
};

export default DiversityMultipleBarComponent;
