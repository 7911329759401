import React from "react";
import BarComponentWater from "./FrameworkOne/BarComponentWater";
import WaterBarFourtyEight from "./FrameworkFourtyEight/WaterBarFourtyEight";
import TotalTreatedWater from "./FrameworkFourtyEight/TotalTreatedWater";
import WaterRecyclePercentage from "./FrameworkFourtyEight/WaterRecyclePercentage";

const WaterSingleLocSingleTime = ({
  companyFramework,
  brief,
  matchedDataWater,
  matchedWaterDis,
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "30vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <BarComponentWater
              matchedDataWater={matchedDataWater}
              title={"Water Withdrawal"}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <BarComponentWater
              matchedDataWater={matchedWaterDis}
              title={"Water Discharged"}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="d-flex flex-column flex-space-between">
        <div
          className="d-flex flex-row flex-space-between"
          style={{ height: "52vh", marginBottom: "3%" }}
        >
          <div
            className="firsthalfprogressenergy"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "49%" }}>
              <WaterBarFourtyEight brief={brief} />
            </div>
            <div style={{ height: "49%" }}>
              <WaterRecyclePercentage brief={brief} />
            </div>
          </div>
          <div
            className="secondhalfprogress"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <div style={{ height: "49%" }}>
              <TotalTreatedWater brief={brief} />
            </div>
            <div style={{ height: "0%" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaterSingleLocSingleTime;
