import React from "react";

const TotalWasteDisposedMulti = ({ timePeriods, wasteRecovered }) => {
  console.log(timePeriods, wasteRecovered);

  const categories = wasteRecovered.reduce((acc, item) => {
    if (item.question_details) {
      let filteredOptions = item.question_details
        .filter((detail) => detail.option_type === "column1")
        .map((detail) => detail.option);

      if (filteredOptions.length === 0) {
        filteredOptions = item.question_details
          .filter((detail) => detail.option_type === "column")
          .map((detail) => detail.option);
      }

      return acc.concat(filteredOptions);
    }
    return acc;
  }, []);

  const uniqueCategories = [...new Set(categories)];

  const seriesData = Object.keys(timePeriods).map((timePeriod, timeIndex) => {
    const totalValues = uniqueCategories.map((category, categoryIndex) => {
      const timePeriodData = wasteRecovered[timeIndex];
      if (timePeriodData && timePeriodData.question_details) {
        const matchedDetail = timePeriodData.question_details.find(
          (detail) => detail.option === category
        );
        if (
          matchedDetail &&
          timePeriodData.answer &&
          timePeriodData.answer[0]
        ) {
          const answerValue = timePeriodData.answer[0][categoryIndex];
          return answerValue !== undefined ? Number(answerValue) : 0;
        }
      }
      return 0;
    });

    return {
      name: timePeriod,
      data: totalValues,
    };
  });

  // Sort each data array in ascending order for each series
  seriesData.forEach((series) => {
    const sortedData = [...series.data];
    sortedData.sort((a, b) => a - b); // Sort in ascending order
    series.data = sortedData;
  });

  const totalSum = seriesData.reduce(
    (sum, series) => sum + series.data.reduce((a, b) => a + b, 0),
    0
  );

  const adjustAndRoundTotalSum = (totalSum) => {
    const thresholds = [
      10, 25, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000,
    ];

    if (totalSum < 1) {
      if (totalSum < 0.01) {
        return Math.ceil(totalSum * 200) / 200;
      } else if (totalSum < 0.1) {
        return Math.ceil(totalSum * 100) / 100;
      } else {
        return Math.ceil(totalSum * 2) / 2;
      }
    }

    for (let i = thresholds.length - 1; i >= 0; i--) {
      if (totalSum > thresholds[i]) {
        return Math.ceil(totalSum / thresholds[i]) * thresholds[i];
      }
    }

    return totalSum;
  };

  const adjustedTotalSum = adjustAndRoundTotalSum(totalSum);

  const colors = [
    "#C6CB8D", "#949776", "#ABC4B2", "#6D8B96", "#9CDFE3", "#11546f",
    "#587b87", "#8CBBCE",
  ];

  return (
    <div className="container">
      <div className="renewable-bar-header">Total Waste Recovered</div>
      <div className="renewable-bar-labels" style={{ paddingLeft: "5%" }}>
        <span
          style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
        >
          0
        </span>
        <span
          style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
        >
          {(adjustedTotalSum / 5 / 10) * 10}
        </span>
        <span
          style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
        >
          {(((adjustedTotalSum / 5) * 2) / 10) * 10}
        </span>
        <span
          style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
        >
          {(((adjustedTotalSum / 5) * 3) / 10) * 10}
        </span>
        <span
          style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
        >
          {(((adjustedTotalSum / 5) * 4) / 10) * 10}
        </span>
        <span
          style={{ fontSize: "11px", fontWeight: "bold", fontSize: "12px" }}
        >
          {(adjustedTotalSum / 10) * 10}
        </span>
      </div>
      <div className="renewable-bar-dotted-line"></div>

      {seriesData.length > 0 ? (
        <div
          style={{
            display: "flex",
            height: "90%",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {seriesData.map((series, seriesIndex) => (
            <div
              className="d-flex"
              style={{ width: "100%", marginBottom: "2%" }}
            >
              <div
                style={{
                  fontWeight: 500,
                  color: "#7b91b0",
                  marginBottom: "5px",
                  paddingTop: "2%",
                  fontSize: "12px",
                  marginRight: "2%",
                }}
              >
                {series.name.charAt(0).toUpperCase() + series.name.slice(1)}
              </div>
              <div
                key={seriesIndex}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  backgroundColor: "#f3f3f3",
                  margin: "5px 0",
                  height: "100%",
                  width: "100%",
                }}
              >
                {series.data.map(
                  (value, index) =>
                    value > 0 && (
                      <div
                        key={index}
                        style={{
                          height: "100%",
                          width: `${(value / adjustedTotalSum) * 100}%`,
                          backgroundColor: colors[index % colors.length],
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                        title={`${uniqueCategories[index]}: ${value}`}
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "white",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {value}
                        </span>
                      </div>
                    )
                )}
              </div>
            </div>
          ))}
          <div
            className="unit"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "3%",
              marginTop: "1%",
              marginBottom: "3%",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                fontWeight: 400,
                height: "100%",
                padding: "0%",
              }}
            >
              (in MT)
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              width: "100%",
              overflow: "auto",
            }}
          >
            {uniqueCategories.map((category, index) => {
              const shortNames = {
                "Other recovery operations": "Other",
              };

              const displayName = shortNames[category] || category;
              return (
                <div
                  key={index}
                  style={{
                    width: "33%",
                    display: "flex",
                    alignItems: "center",
                    marginRight: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: colors[index % colors.length],
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <div style={{ width: "70%" }}>
                    <div style={{ fontSize: "12px" }}>{displayName}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <p>No categories available for the selected options.</p>
      )}
    </div>
  );
};

export default TotalWasteDisposedMulti;
