import React from "react";
import DiversityMultiLoc from "./FrameworkOne/DiversityMultiLoc";
import DiversityMultiLocTwo from "./FrameworkOne/DiversityMultiLocTwo";
import DiversityMultiLocSingle from "./FrameworkFourtyEight/DiversityMultiLocSingle";

const DiversitySingleTimeMultLoc = ({
  companyFramework,
  timePeriods,
  dataOne,
  titleOne,
  dataTwo,
  titleTwo,
  diversity,
  titleThree,
  titleFour,
  titleFive,
  brief,
  timePeriodValues,
}) => {
  return companyFramework.includes(1) ? (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "120vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
            <DiversityMultiLoc
              horizontal={false}
              title={titleOne}
              timePeriods={timePeriods}
              matchedDataWaste={dataOne}
            />
          </div>

          <div style={{ height: "49%" }}>
            <DiversityMultiLocTwo
              horizontal={false}
              title={titleFour}
              timePeriods={timePeriods}
              matchedDataWaste={dataOne}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}>
            <DiversityMultiLoc
              horizontal={false}
              title={titleTwo}
              timePeriods={timePeriods}
              matchedDataWaste={dataTwo}
            />
          </div>
          <div style={{ height: "49%" }}>
            <DiversityMultiLocTwo
              horizontal={false}
              title={titleFive}
              timePeriods={timePeriods}
              matchedDataWaste={dataTwo}
            />
          </div>
        </div>
      </div>
      {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLoc
              title={titleThree}
              timePeriods={timePeriods}
              matchedDataWaste={diversity}
            />
          </div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "49%" }}></div>
          <div style={{ height: "49%" }}></div>
        </div>
      </div> */}
    </div>
  ) : (
    <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLocSingle
              brief={brief}
              timePeriodValues={timePeriodValues}
              categories={[
                "Males out of total employees",
                "Females out of total employees",
              ]}
              shortenedCategories={["Males", "Females"]}
              title="Current employees by Gender"
            />
          </div>

          <div style={{ height: "0%" }}></div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLocSingle
              brief={brief}
              timePeriodValues={timePeriodValues}
              categories={[
                "Employees less than 30 years of age",
                "Employees between 30-50 years of age",
                "Employees more than 50 years of age",
              ]}
              shortenedCategories={["<30", "30-50", ">50"]}
              title="Age Wise Distribution of Current Employees"
            />
          </div>
          <div style={{ height: "0%" }}></div>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        <div
          className="firsthalfprogressenergy"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLocSingle
              brief={brief}
              timePeriodValues={timePeriodValues}
              categories={[
                "New hires by Gender Male",
                "New hires by Gender Female",
              ]}
              shortenedCategories={["Males", "Females"]}
              title="New hires by Gender"
            />
          </div>

          <div style={{ height: "0%" }}></div>
        </div>
        <div
          className="secondhalfprogress"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <div style={{ height: "100%" }}>
            <DiversityMultiLocSingle
              brief={brief}
              timePeriodValues={timePeriodValues}
              categories={[
                "New hires by age groups (<30)",
                "New hires by age groups (30-50)",
                "New hires by age groups (>50)",
              ]}
              title="Age Wise Distribution of New Hire Employees"
              shortenedCategories={["<30", "30-50", ">50"]}
            />
          </div>
          <div style={{ height: "0%" }}></div>
        </div>
      </div>
    </div>
  );
};

export default DiversitySingleTimeMultLoc;
