import React from "react";
import img from "../../../img/no.png";

const SafetyVerticalBarFourtyEight = ({   shortenedMap,
  brief,
  categories,heading }) => {
  const getCategorySums = (categoryKey) => {
    let totalForCategory = 0;
    // Loop through each location in the 'time' key
    if (brief && brief.time) {
      // Loop through each location in the 'time' key
      Object.keys(brief.time).forEach((location) => {
        const categoryValues = brief.time[location][categoryKey];
        if (categoryValues) {
          // Sum up values for the category across all time periods
          totalForCategory += categoryValues.reduce(
            (acc, value) => acc + value,
            0
          );
        }
      });
    }

    return totalForCategory;
  };


  // Calculate total sums for each category
  const categoryValues = categories.map((category) => ({
    category,
    totalValue: getCategorySums(category),
  }));

  // Calculate the grand total sum of all categories
  const totalSum = categoryValues.reduce(
    (acc, item) => acc + item.totalValue,
    0
  );

  // Colors for each category
  const colors = [
    "#C6CB8D",
    "#949776",
    "#ABC4B2",
    "#6D8B96",
    "#9CDFE3",
    "#11546f",
    "#587b87",
    "#8CBBCE",
  ];

  // Total bar color
  const totalColor = "#2c3e50";

  const shortenCategory = (category) => {

    return shortenedMap[category] || category; // Fallback to full category if not found
  };

  // Function to format value (adding commas for thousands and 1 decimal place)
  const formatValue = (value) => {
    return value.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="container" style={{ width: "100%" }}>
      <div
        style={{
          height: "10%",
          fontSize: "20px",
          fontWeight: 600,
          color: "#011627",
          marginBottom: "15px"
        }}
      >
        {heading}
      </div>
      
      {categoryValues.length > 0 && totalSum > 0 ? (
        <div style={{ height: "90%" }}>
          {/* Chart container */}
          <div style={{ 
            position: "relative", 
            height: "300px", 
            marginBottom: "80px" 
          }}>
            {/* Y-axis labels */}
            <div style={{ 
              position: "absolute",
              left: 0,
              top: 0,
              bottom: 0,
              width: "50px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRight: "1px solid #ddd"
            }}>
              {[0, 0.2, 0.4, 0.6, 0.8, 1].map((ratio, i) => (
                <div key={i} style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "8px",
                  fontSize: "11px",
                  fontWeight: 600,
                  position: "absolute",
                  bottom: `${ratio * 100}%`,
                  transform: "translateY(50%)",
                  right: 0,
                  left: 0
                }}>
                  {formatValue(Math.round((totalSum * ratio) / 10) * 10)}
                </div>
              ))}
            </div>

            {/* Grid lines */}
            <div style={{ 
              position: "absolute",
              left: "50px",
              right: 0,
              top: 0,
              bottom: 0
            }}>
              {[0, 0.2, 0.4, 0.6, 0.8, 1].map((ratio, i) => (
                <div key={i} style={{
                  width: "100%",
                  borderTop: "1px dashed #ddd",
                  height: "1px",
                  position: "absolute",
                  bottom: `${ratio * 100}%`,
                  left: 0
                }}></div>
              ))}
            </div>

            {/* Bar chart area */}
            <div style={{ 
              position: "absolute",
              left: "50px",
              right: 0,
              top: 0,
              bottom: 0,
              display: "flex",
              alignItems: "flex-end",
              padding: "0 10px",
              borderBottom: "1px solid #ddd",
              borderLeft: "none"
            }}>
              <div style={{ 
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "flex-end"
              }}>
                {/* Individual category bars */}
                {categoryValues.map((item, index) => {
                  const barHeight = (item.totalValue / totalSum) * 100;
                  return (
                    <div key={index} style={{ 
                      width: `${80 / (categoryValues.length + 1)}%`,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative"
                    }}>
                      <div style={{
                        position: "absolute",
                        bottom: 0,
                        width: "70%",
                        height: `${barHeight}%`,
                        backgroundColor: colors[index % colors.length],
                        minHeight: item.totalValue > 0 ? "2px" : "0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        paddingTop: "5px"
                      }}>
                        {item.totalValue > 0 && (
                          <span style={{
                            color: "white",
                            fontSize: "11px",
                            fontWeight: "bold",
                            position: barHeight < 15 ? "absolute" : "static",
                            top: barHeight < 15 ? "-20px" : "auto",
                            color: barHeight < 15 ? "#000" : "#fff"
                          }}>
                            {item.totalValue.toFixed(1)}
                          </span>
                        )}
                      </div>
                      
                      {/* X-axis label */}
                      <div style={{
                        position: "absolute",
                        bottom: "-45px",
                        transform: "translateX(-50%)",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "11px",
                        fontWeight: 600,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}>
                        <span style={{
                          maxWidth: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}>
                          {shortenCategory(item.category)}
                        </span>
                      </div>
                    </div>
                  );
                })}

                {/* Total bar */}
                <div style={{
                  width: `${80 / (categoryValues.length + 1)}%`,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative"
                }}>
                  <div style={{
                    position: "absolute",
                    bottom: 0,
                    width: "70%",
                    height: "100%",
                    backgroundColor: totalColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    paddingTop: "5px"
                  }}>
                    <span style={{
                      color: "white",
                      fontSize: "11px",
                      fontWeight: "bold"
                    }}>
                      {totalSum.toFixed(1)}
                    </span>
                  </div>
                  
                  {/* X-axis label */}
                  <div style={{
                    position: "absolute",
                    bottom: "-45px",
                    transform: "translateX(-50%)",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "11px",
                    fontWeight: 600
                  }}>
                    <span>Total</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Legend for categories */}
          <div style={{ marginTop: "30px" }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {categoryValues.map((item, index) => (
                <div
                  key={index}
                  style={{
                    width: "50%",
                    display: "flex",
                    fontSize: "12px",
                    alignItems: "center",
                    marginBottom: "10px"
                  }}
                >
                  <div
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      backgroundColor: colors[index % colors.length],
                      marginRight: "10px"
                    }}
                  />
                  <div style={{ fontSize: "12px" }}>
                    {shortenCategory(item.category)}
                  </div>
                </div>
              ))}

              {/* Total legend item */}
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                  marginBottom: "10px"
                }}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    backgroundColor: totalColor,
                    marginRight: "10px"
                  }}
                />
                <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Total
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ 
          height: "100%", 
          width: "100%", 
          display: "flex", 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <img src={img} style={{ height: "140px", width: "170px" }} alt="No data" />
        </div>
      )}
    </div>
  );
};

export default SafetyVerticalBarFourtyEight;