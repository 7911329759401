import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const DiversityPieChart = ({
  categories,
  timePeriodValues,
  shortenedCategories,
  brief,
  title
}) => {
  // State for pie chart data
  const [pieData, setPieData] = useState([]);
  const [pieLabels, setPieLabels] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(true);

  useEffect(() => {
    // Check if brief and brief.time exist
    if (!brief || !brief.time) {
      setDataAvailable(false);
      return;
    }

    // Get the values from brief.time
    let filteredKeys = Object.values(brief.time);
    const currentTypeProducts = categories || [];
    
    // Filter data based on type
    filteredKeys = filteredKeys.map(obj => {
      const filteredObj = {};
      Object.keys(obj).forEach(key => {
        if (currentTypeProducts.includes(key)) {
          filteredObj[key] = obj[key];
        }
      });
      return filteredObj;
    });

    const locationData = filteredKeys;
    const legendTotals = {};

    // Loop through each time object
    locationData.forEach((time) => {
      for (const key in time) {
        if (time.hasOwnProperty(key)) {
          const valueArray = time[key];
          const value = Array.isArray(valueArray) ? (valueArray.reduce((acc, curr) => acc + Number(curr), 0)).toFixed(2) : 0;
          legendTotals[key] = Number(Number((legendTotals[key] || 0) + Number(value)).toFixed(2));
        }
      }
    });

    // Convert legendTotals to series data
    const labels = Object.keys(legendTotals);
    const data = Object.values(legendTotals);
    
    setPieLabels(labels);
    setPieData(data);
    setDataAvailable(true);
  }, [brief, categories]);

  if (!dataAvailable) {
    return <p>No data available.</p>;
  }

  const chartOptions = {
    chart: {
      type: "pie",
      toolbar: { show: false }
    },
    labels: pieLabels,
    title: {
      show: false,
      text: ""
    },
    legend: {
      show: false,
    },
    colors: [
      "#C6CB8D",
      "#949776",
      "#ABC4B2",
      "#6D8B96",
      "#9CDFE3",
      "#11546f",
      "#587b87",
      "#8CBBCE",
    ],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(1) + "%";
      }
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toFixed(1) + "%";
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          height: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  return (
    <div className="container">
      <div
        style={{
          height: "10%",
          fontSize: "20px",
          fontWeight: 600,
          color: "#011627",
          marginBottom: "2%",
        }}
      >
        {title}
      </div>

      <div style={{ height: "70%" }}>
        <Chart
          options={chartOptions}
          series={pieData}
          type="pie"
          height={"100%"}
        />
      </div>

      {/* Custom Legends Container */}
      <div style={{ marginTop: "20px", width: "100%" }}>
        {shortenedCategories
          .reduce((acc, category, index) => {
            if (index % 3 === 0) acc.push([]); // Start a new row for every three items
            acc[acc.length - 1].push(
              <div
                key={index}
                style={{
                  display: "flex",
                  width: "25%",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    backgroundColor: chartOptions.colors[index % chartOptions.colors.length], // Use corresponding color
                    marginRight: "5px",
                  }}
                />
                <span style={{ fontWeight: 300, fontSize: "12px" }}>
                  {category}
                </span>
              </div>
            );
            return acc;
          }, [])
          .map((row, rowIndex) => (
            <div
              key={rowIndex}
              style={{
                display: "flex",
                flexWrap: "wrap",
                fontSize: "12px",
                marginBottom: "10px",
              }}
            >
              {row}
            </div>
          ))}
      </div>
    </div>
  );
};

export default DiversityPieChart;