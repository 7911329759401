import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar/sidebar'
import Header from "../header/header"
import config from "../../config/config.json"
import { apiCall } from '../../_services/apiCall'
import Welcome from './components/Welcome'
import TrainingTable from './components/TrainingTable'
import Resedule from './components/Resedule'


const TraineeeModule = (props) => {
  const [financeObjct, setFinanceObjct] = useState();
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {
      setFinanceObjct(data.data[data.data.length - 1].id);
      return (data.data[data.data.length - 1].id)

    }
  };

  useEffect(() => {
    getFinancialYear()

  }, [])

  return (
    <div
      className="d-flex flex-row mainclass"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <div style={{ flex: "0 0 21%", position: "sticky", top: 0, zIndex: 999 }}>
        <Sidebar
          financeObjct={financeObjct}
          dataFromParent={props.location.pathname}
        />
      </div>
      <div style={{ flex: "1 1 79%" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
          <Header />
        </div>
        <div className="main_wrapper p-3">

          <div
            className="w-100"
            style={{
              paddingRight: "2.5%",
              marginLeft: "2%",
            }}
          >
          </div>
          <div className="w-100 p-4 ">
            {/* <Welcome /> */}
            <TrainingTable />
            {/* <Resedule/> */}

          </div>
        </div>
      </div>
    </div>
  )
}

export default TraineeeModule
